<template>
  <div class="w-full">
    <customSelect
      v-model="selectedList"
      :options="filterList"
      :loading="load"
      :disabled="disabled"
      :searchable="true"
      :multiple="true"
      :return-object="true"
      @search="handleSearch"
      @scroll-bottom="loadMoreData"
      @change="handleChange"
    >
    </customSelect>
  </div>
</template>

<script>
import customSelect from "@/components/customSelect.vue";
import { utility } from "@/networking/urlmanager";

export default {
  props: ["value", "isEdit", "disabled"],
  components: {
    customSelect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      filterList: [],
      load: false,
      currentPage: 0,
      searchTerm: "",
      hasMoreData: true,
      originalItems: [],
      deletedItems: []
    };
  },
  methods: {
    handleChange(val) {
      const newlyDeletedItems = this.originalItems.filter(original => 
        !val.some(v => v.id === original.id) && 
        !this.deletedItems.some(d => d.id === original.id)
      ).map(item => ({
        ...item,
        status: 3
      }));
      
      this.deletedItems = [...this.deletedItems, ...newlyDeletedItems];
      
      const processedVal = this.applyStatusLogic(val);
      
      const finalVal = [...processedVal, ...this.deletedItems];
      
      this.$emit("change", finalVal);
    },
    
    applyStatusLogic(departments) {
      if (!departments || !Array.isArray(departments)) return departments;
      
      return departments.map(department => {
        const originalItem = this.originalItems.find(v => v.id === department.id);
        const wasDeleted = this.deletedItems.some(d => d.id === department.id);
        
        if (wasDeleted) {
          this.deletedItems = this.deletedItems.filter(d => d.id !== department.id);
          return { ...department, status: 2 };
        } else if (!originalItem) {
          return { ...department, status: 2 };
        } else {
          return { ...department, status: originalItem.status || 1 };
        }
      });
    },
    
    handleSearch(term) {
      this.searchTerm = term;
      this.currentPage = 0;
      this.hasMoreData = true;
      this.filterList = [];
      this.getDepartments();
    },
    
    loadMoreData() {
      if (!this.load && this.hasMoreData) {
        this.currentPage++;
        this.getDepartments(true);
      }
    },
    
    async getDepartments(append = false) {
      if (!append) {
        this.filterList = [];
      }
      
      try {
        this.load = true;
        const response = await this.axios.get(utility.getDepartmentAll, {
          params: {
            page: this.currentPage,
            search: this.searchTerm || undefined
          }
        });
        
        const data = response.data.data.detail || [];
        
        if (!Array.isArray(data) || data.length === 0) {
          this.hasMoreData = false;
        } else {
          const formattedData = data.map(item => ({
            id: item.id,
            name: item.name,
            status: item.status || 1
          }));
          
          this.filterList = append ? [...this.filterList, ...formattedData] : formattedData;
        }
      } catch (error) {
        console.error("Error loading department data:", error);
      } finally {
        this.load = false;
      }
    }
  },
  async created() {
    await this.getDepartments();
    
    if (this.value && Array.isArray(this.value)) {
      this.deletedItems = this.value.filter(item => item.status === 3);
      this.selectedList = this.value.filter(item => item.status !== 3);
      this.originalItems = this.value.filter(item => item.status !== 3);
    } else {
      this.selectedList = [];
      this.originalItems = [];
      this.deletedItems = [];
    }
  },
  watch: {
    value(val) {
      if (val && Array.isArray(val)) {
        this.deletedItems = val.filter(item => item.status === 3);
        this.selectedList = val.filter(item => item.status !== 3);
        
        if (this.isEdit) {
          this.originalItems = val.filter(item => item.status !== 3);
        }
      } else {
        this.selectedList = [];
        if (this.isEdit) {
          this.originalItems = [];
        }
      }
    }
  }
};
</script>