var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Teleport',{attrs:{"to":"body"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.value)?_c('div',{staticClass:"fixed inset-0 z-[9998] flex items-center justify-center p-4"},[_c('div',{staticClass:"absolute inset-0 bg-slate-900/50 backdrop-blur-sm",on:{"click":function($event){$event.stopPropagation();return _vm.closePopupOutside.apply(null, arguments)}}}),_c('transition',{attrs:{"name":_vm.animationType}},[_c('div',{staticClass:"relative flex flex-col w-full max-h-[90vh] overflow-hidden rounded-lg shadow-xl z-[9999]",class:[
            _vm.sizeClasses[_vm.size],
            _vm.colorVariants[_vm.variant].card,
            _vm.borderVariants[_vm.variant]
          ],on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"flex items-center justify-between border-b p-2 px-4 !bg-cvus-primary",class:[
              _vm.colorVariants[_vm.variant].header,
              _vm.borderVariants[_vm.variant],
            ]},[_vm._t("title",function(){return [_c('h3',{staticClass:"font-semibold m-0 !text-white",class:[
                  _vm.colorVariants[_vm.variant].text,
                  { 'text-base': _vm.size === 'sm', 'text-xl': _vm.size === 'md', 'text-2xl': _vm.size === 'lg' }
                ]},[_vm._v(" "+_vm._s(_vm.title)+" ")])]}),(_vm.showCloseButton)?_c('button',{staticClass:"flex items-center justify-center rounded-full hover:bg-slate-100/10 transition-colors",class:[
                { 'h-6 w-6': _vm.size === 'sm', 'h-8 w-8': _vm.size === 'md', 'h-10 w-10': _vm.size === 'lg' }
              ],on:{"click":_vm.closePopup}},[_c('Icon',{staticClass:"!text-white",class:[
                  _vm.colorVariants[_vm.variant].text,
                  { 'text-base': _vm.size === 'sm', 'text-lg': _vm.size === 'md', 'text-xl': _vm.size === 'lg' }
                ],attrs:{"icon":"mdi:close"}})],1):_vm._e()],2),_c('transition',{attrs:{"name":"body-scale","appear":_vm.animateContent}},[_c('div',{staticClass:"flex-1 overflow-y-auto p-2",class:[
                _vm.colorVariants[_vm.variant].body
              ]},[_vm._t("default")],2)]),(_vm.$slots.footer)?_c('div',{staticClass:"border-t flex items-center justify-end gap-2 p-2 px-4",class:[
              _vm.colorVariants[_vm.variant].footer,
              _vm.borderVariants[_vm.variant]
            ]},[_vm._t("footer")],2):_vm._e()],1)])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }