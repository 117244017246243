<template>
  <div class="min-h-auto">
    <div class="p-5 scroltype h-full xl:pb-1 pb-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
        
        <!-- EĞİTİM SEVİYESİ -->
        <educationSelect 
          v-model="list.educationType" 
          :disabled="allDisabled"
          :highlight="isEdit && !list.educationType?.length"
        />

        <!-- OKUL -->
        <div class="w-full">
          <label class="text textcol font-semibold">{{ $t("project.components.add.school") }}</label>
          <institutionSelect 
            :isEdit="isEdit" 
            v-model="list.institutionList" 
            :educationType="list.educationType"
            :disabled="allDisabled"
          />
        </div>

        <!-- BÖLÜM -->
        <div class="w-full">
          <label class="text textcol font-semibold">{{ $t("project.components.add.section") }}</label>
          <departmentSelect 
            :isEdit="isEdit" 
            v-model="list.departmentList"
            :disabled="allDisabled"
          />
        </div>

        <!-- YABANCI DİL -->
        <div class="w-full">
          <label class="textcol font-semibold">{{ $t("project.components.add.foreignLanguage") }}</label>
          <languageSelect 
            :isEdit="isEdit" 
            v-model="list.languageList"
            :disabled="allDisabled"
          />
        </div>    
        <!-- ÇALIŞMA İLİ -->
        <div>
            <experienceRange 
              v-model="list.experienceDuration"
              :highlight="isEdit && !list.experienceDuration"
            />
        </div>
        <div>
          <label class="textcol font-semibold flex gap-1 items-center"
            >{{ $t("project.components.add.WorkCity") }}
          </label>
          <citySelect 
            :isEdit="isEdit" 
            v-model="list.cityList"
            :disabled="allDisabled"
          />
        </div>

      </div>

    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
import educationSelect from "./educationSelect.vue";
import experienceRange from "./experienceRange.vue";
import languageSelect from "@/components/dev/languageSelect.vue";
import institutionSelect from "@/components/dev/institutionSelect.vue";
import departmentSelect from "@/components/dev/departmentSelect.vue";
import citySelect from "@/components/dev/citySelect.vue";
// import customInput from "@/components/customInput.vue";

export default {
  name: "steptwoPage",
  props: {
    stepList: {},
    isEdit: {},
    allDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    educationSelect,
    experienceRange,
    languageSelect,
    institutionSelect,
    departmentSelect,
    citySelect,
    // customInput
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({ list: (state) => state.project.stepData.stepTwo }),
  },
};
</script>
