<template>
  <div>
    <div class="w-full flex justify-center text-sm">
      <div
        class="w-8/12 p-4 max-sm:h-20 flex items-center border-2 border-dashed border-gray-500 border-r-0 rounded-l-xl"
      >
        <p class="text-black">
          {{  id ? id + ' - ' : '' }}
          
          {{ title }}</p>
      </div>
      <div
        class="w-4/12 rounded-r-xl flex justify-center bgcol p-3 items-center"
      >
        <button
          :disabled="!url"
          type="button"
          @click="goLink(url)"
          class="text-center font-semibold text-white flex items-center text-sm"
          :class="!url && 'text-red-500'"
        >
          <span v-if="!load && !goLoad" class="underline">
            {{
              url
                ? $t(
                    "project.projectUser.components.shortList.components.personelInformation.utility.card.show"
                  )
                : $t(
                    "project.projectUser.components.shortList.components.personelInformation.utility.card.notLoad"
                  )
            }}</span
          >

          <span v-else class="flex gap-2 items-center justify-center text-sm">
            {{
              goLoad
                ? $t(
                    "project.projectUser.components.shortList.components.personelInformation.utility.card.opening"
                  )
                : $t(
                    "project.projectUser.components.shortList.components.personelInformation.utility.card.loading"
                  )
            }}
            <i class="fas fa-spinner fa-2x fa-spin text-sm"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { utility } from "@/networking/urlmanager";

export default {
  name: "card-personal-informations",
  props: ["url", "load", "title", "id"],
  data() {
    return {
      goLoad: false,
    };
  },
  methods: {
    async goLink(val) {
      try {
        // this.goLoad = true;
        // const res = await this.axios.post(utility.readFile, {
        //   fileUrl: val,
        // });
        // let url = Array.isArray(res.data.data)
        //   ? res.data.data[0]
        //   : res.data.data;
        // this.goLoad = false;
        window.open(val, "_blank");
      } catch (err) {
        // this.goLoad = false;
        console.log(err);
        this.authController(err);
      }
    },
  },
};
</script>
