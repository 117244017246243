<template>
  <div
    class="bgcol text-white border-t border-gray-300 grid grid-cols-2 w-full"
  >
    <button
      v-for="(item, index) in List"
      :key="index"
      :class="type == item.id ? 'bg-white bg-opacity-20 ' : ''"
      @click="clickButton(item)"
      class="py-3"
      :disabled="item.id == type || load"
    >
      {{ item.name }}
      <span v-show="item.id == type">(Aktif)</span>
    </button>
  </div>
</template>
<script>
export default {
  name: "top-bar-link",
  props:["load"],
  data() {
    return {
      type: 1,
      List: [
        {
          id: 1,
          name: this.$t('project.detail.interviewQuestions'),
        },
        {
          id: 2,
          name: this.$t('project.detail.candidateQuestions'),
        },
      ],
    };
  },
  methods: {
    clickButton(val) {
      this.type = val.id;
      this.$emit("click", val.id);
    },
  },
};
</script>