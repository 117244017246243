<template>
  <modal
    name="personel-information"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{
          $t(
            "project.projectUser.components.shortList.components.personelInformation.index.popupTitle"
          )
        }}
      </h1>
      <button @click.stop="hide">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="p-10 flex flex-col gap-5 max-sm:p-4">
      <div v-if="load" class="flex justify-center items-center h-full">
          <i class="fas fa-spinner fa-spin text-2xl animate-spin"></i>
      </div>
      <card v-for="(item,index) in list" :key="index" :url="item.fileUrl" :load="load" :id="item.documentId" :title="item.title" />
    
    </div>
  </modal>
</template>

<script>
import { personelInformation } from "@/networking/urlmanager";
import card from "./utility/card.vue";

export default {
  props: ["Detail"],
  name: "select-date-modal",
  components: {
    card,
  },
  data() {
    return {
      allLoad: false,
      load: false,
      goLoad: false,
      list: [],

    };
  },
  methods: {
    show() {

      setTimeout(() => {
        this.getFiles();
      }, 50);
      this.$modal.show("personel-information");
    },
    hide() {
      this.$modal.hide("personel-information");
    },
   

    async getFiles() {
      try {
        this.list = [];
        this.load = true;
        let response = await this.axios.get(personelInformation.getFileList, {
          params:{
            cvId: this.Detail.cvId || this.Detail.id,
            projectId: this.Detail.projectId,
          }
        });
        this.list = response.data.data

      } catch (err) {
        this.load = false;
        console.log(err);
        this.authController(err);
      }finally{
        this.load = false;
      }
    },
  },
};
</script>
