<template>
  <div id="app" class="flex min-h-screen w-full relative">
    <log ref="log" :moduleId="3" @isLog="(r) => (isLog = r)" />

    <checkNetwork successText="İnternet Bağlantınız Tekrar Sağlandı" errorText="İnternet bağlanıtınız Koptu" />

    <sidebar :isSidebar="isSidebar" v-if="$store.state.userData.isAuth" :class="[
      $route.name == 'notFound' && 'hidden',
      $store.state.isMeetingBar == false ? $route.name == 'meeting' && 'hidden transition-all ease-in-out' : 'transition-all ease-in-out ',
      $store.state.isMeetingBar == false ? $route.name == 'meetingLive' && 'hidden transition-all ease-in-out' : ' transition-all ease-in-out',
      $store.state.isMeetingBar == false ? $route.name == 'assistan' && 'hidden transition-all ease-in-out' : ' transition-all ease-in-out',
    ]" />

    <div class="w-full max-h-screen overflow-y-auto overflow-x-hidden" id="app-container">
      <navbar :toggleSidebar="toggleSidebar" :logToggle="logToggle" :isSidebar="isSidebar" :isLog="isLog" v-if="$store.state.userData.isAuth" :class="[
          $route.name == 'notFound' && 'hidden',
          $route.name == 'meeting' && 'hidden',
          $route.name == 'meetingLive' && 'hidden',
          $route.name == 'assistan' && 'hidden',
        ]" />
      <router-view />
    </div>
  </div>
</template>
<script>
import navbar from "@/partitions/navbar.vue";
import sidebar from "@/partitions/sidebar.vue";
import log from "@/components/log.vue";
import checkNetwork from "check-to-network";

import moment from "moment";
import { users, settingContact, settingContactPersonal} from "@/networking/urlmanager";

export default {
  name: "app",
  data() {
    return {
      isSidebar: true,
      extractId: null,
      microsoftId: null,
      googleId: null,
      isLog: 0,
    };
  },
  components: {
    navbar,
    sidebar,
    log,
    checkNetwork,
  },
  methods: {
    toggleSidebar() {
      this.isSidebar = !this.isSidebar;
      this.$store.commit("setIsSidebar", this.isSidebar);
      if (!this.isSidebar) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    logToggle() {
      this.$refs.log.show();
    },
    isExtract() {
      this.axios.get(users.isExtractControl)
        .then((res) => {
          this.$store.commit("setIsExtract", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        });
    },
    getContactPersonal() {
      this.axios.get(settingContactPersonal.getAll)
        .then((res) => {
          this.$store.commit("setContactPersonal", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        });
    },
    getContact() {
      this.axios.get(settingContact.getAll)
        .then((res) => {
          this.$store.commit("setContactCompany", res.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        });
    },
    getLanguage() {
      this.axios.get(settingContact.getAll)
        .then((res) => {
          this.$store.commit("setContactCompany", res.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        });
    },
    captchaController() {
      let elapsedTime = 0;
      const intervalId = setInterval(() => {
        elapsedTime += 50;
        if (this.$recaptchaInstance?.siteKey) {
          if (this.$route.name !== "signIn") {
            this.$recaptchaInstance.hideBadge();
            clearInterval(intervalId);
          }
        }
        if (elapsedTime >= 10000) {
          clearInterval(intervalId);
        }
      }, 50);
    },
  },
  async created() {
    this.captchaController();
    if (this.$store.state.userData.token) {
      if (!this.$store.state.contactCompany.personal) {
        this.getContactPersonal();
      }

      if (!this.$store.state.contactCompany.contact) {
        this.getContact();
      }
      if (this.$store.state.userData.isExtract === 2) {
        this.extractId = setInterval(() => { this.isExtract(); }, 9000);
      } else {
        clearInterval(this.extractId);
      }

      moment.locale(this.$i18n.locale || 'en');
    }
  },
  watch: {
    "$store.state.userData.isExtract"(val) {
      if (val === 2) {
        this.extractId = setInterval(() => {
          this.isExtract();
        }, 2500);
      } else {
        clearInterval(this.extractId);
      }
    },
    "$i18n.locale"(val) {
      moment.locale(val);
    },
  },
};
</script>
