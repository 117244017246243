<template>
  <div>
    <section
        class="px-20 -z-1"
        ref="pdfContent"
        v-show="contentVisible"
    ></section>
  </div>
</template>

<script>
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import axios from "axios";
import { settingGeneral } from "@/networking/urlmanager";

const titleFontSize = 13;

const types = {
  personalInformation: 0,
  fullname: 1,
  position: 10,
  biography: 11,
  schoolAndEducation: 2,
  workExperience: 3,
  languages: 4,
  computerSkills: 5,
  skills: 6,
  seminarAndCourse: 7,
  projectsPublications: 8,
  contactInformation: 9,
  backgroundImg: null,
};

export default {
  name: "cv-profile-page",
  props: ["hiddenList", "isLanguage"],
  data() {
    return {
      contentVisible: false,
      currentYPosition: 730,
      activePage: null,
      pdfDoc: null,
      pageWidth: 0,
      pageHeight: 0,
      backgroundImage: null,
      contentGap: 30,
    };
  },
  methods: {
    async headerItem() {
      try {
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);

        try {
          const fontPath = require("@/assets/font/Roboto-Regular.ttf");
          const fontResponse = await axios.get(fontPath, {
            responseType: "arraybuffer",
            timeout: 5000
          });
          const fontBytes = fontResponse.data;
          const font = await pdfDoc.embedFont(fontBytes);
          return {pdfDoc, font};
        } catch (fontError) {
          console.error('Font loading error:', fontError);
          throw new Error('Failed to load font');
        }
      } catch (error) {
        console.error('PDF creation error:', error);
        throw error;
      }
    },
    _objectBufferToUint8Array(objBuffer) {
      const keys = Object.keys(objBuffer);
      const bufferLength = keys.length;
      const uint8Array = new Uint8Array(bufferLength);
      for (let i = 0; i < bufferLength; i++) {
        uint8Array[i] = objBuffer[i];
      }
      return uint8Array;
    },
    async addPageBackround(pdfDoc) {
      try {
        const page = pdfDoc.addPage();
        const pageWidth = page.getWidth();
        const pageHeight = page.getHeight();
        let backgroundImage = null;

        try {
          const formatFile = await this.axios.get(settingGeneral.getFormatFile);

          if (formatFile.data && formatFile.data.data) {
            const responseData = formatFile.data.data;

            if (responseData.buffer && responseData.contentType) {
              const imgBuffer = this._objectBufferToUint8Array(responseData.buffer);
              if (responseData.contentType.includes('png')) {
                backgroundImage = await pdfDoc.embedPng(imgBuffer);
              } else {
                backgroundImage = await pdfDoc.embedJpg(imgBuffer);
              }
              if (backgroundImage) {
                page.drawImage(backgroundImage, {
                  x: 0,
                  y: 0,
                  width: pageWidth,
                  height: pageHeight,
                });
                console.log('Arkaplan resmi başarıyla eklendi');
              } else {
                console.error('Resim embed edilemedi');
              }
            } else {
              console.error('Buffer veya contentType bulunamadı');
            }
          } else {
            console.error('Data bulunamadı:', formatFile.data);
          }
        } catch (error) {
          console.error('Resim yükleme hatası:', error);
        }

        return { page, pageWidth, pageHeight, backgroundImage };
      } catch (error) {
        console.error('Sayfa oluşturma hatası:', error);
        throw error;
      }
    },
    wrapText(text, maxWidth, font, fontSize) {
      const cleanedText = text ? text.toString().replace(/\s+/g, " ").trim() : "";
      const words = cleanedText.split(" ");
      const lines = [];
      let line = "";

      words.forEach((word) => {
        const testLine = line + " " + word;
        const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);

        if (testLineWidth > maxWidth && line.length > 0) {
          lines.push(line);
          line = word;
        } else {
          line = testLine.trim();
        }
      });

      if (line.length > 0) {
        lines.push(line);
      }

      return lines;
    },
    hiddenControl(val) {
      return this.hiddenList.filter((r) => r == val).length > 0;
    },
    addFullname(list, font) {
      if (!list) return;

      this.activePage.drawText(list.trim(), {
        x: (this.pageWidth - font.widthOfTextAtSize(list.toUpperCase(), 16)) / 2,
        y: this.currentYPosition,
        size: 16,
        color: rgb(0, 0, 0),
        font,
      });
    },
    addPosition(list, font) {
      if (!list) return;

      this.activePage.drawText(list.trim(), {
        x: (this.pageWidth - font.widthOfTextAtSize(list.toUpperCase(), 8)) / 2,
        y: this.currentYPosition,
        size: 8,
        color: rgb(0, 0, 0),
        font,
      });
    },
    addBiography(list, font) {
      if (!list) return;

      const lines = this.wrapText(list, 500, font, 8);
      lines.forEach((line) => {
        this.checkPageHeight(120, 120);
        this.activePage.drawText(line || "", {
          x: 50,
          y: this.currentYPosition,
          size: 8,
          color: rgb(0, 0, 0),
          font,
        });
        this.currentYPosition -= 15;
      });
    },
    addHeaderInSection(title, font) {
      this.activePage.drawText(title, {
        x: 50,
        y: this.currentYPosition,
        size: titleFontSize,
        color: rgb(0, 0, 0),
        font,
      });
      this.currentYPosition -= 3;
      this.activePage.drawLine({
        start: {x: 50, y: this.currentYPosition},
        end: {x: 550, y: this.currentYPosition},
        color: rgb(0, 0, 0),
      });
      this.currentYPosition -= 25;
    },
    makeEducation(list, font) {
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Eğitim Bilgileri" : "Education",
          font
      );

      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight();

          this.activePage.drawText(
              item.duration ? item.duration.replace("to", "-") : "          -",
              {
                x: 70,
                y: this.currentYPosition,
                size: 10,
                color: rgb(0, 0, 0),
                font,
              }
          );

          let pureName = "";
          if (item.status) {
            pureName = item.status.toString().toLowerCase();
          }

          let degree = "";
          if (!pureName) degree = "     -";
          else if (pureName == 5) degree = "Doktora";
          else if (pureName == 4) degree = "Yüksek Lisans";
          else if (pureName == 3) degree = "Lisans";
          else if (pureName == 2) degree = "Ön Lisans";
          else if (pureName == 1) degree = "Lise";

          this.activePage.drawText(degree, {
            x: 210,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          let name = this.wrapText(
              item.name + " - " + item.department,
              200,
              font,
              10
          );

          name.forEach((line) => {
            this.activePage.drawText(line, {
              x: 325,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 25;
          }
        }
      });
    },
    async makeProfessionalExperience(list, font) {
      this.addHeaderInSection(
          this.isLanguage == 1 ? "İş Deneyimi" : "Professional Experiences",
          font
      );

      list.forEach((item, index) => {
        this.checkPageHeight();

        // Company name
        this.activePage.drawText(
            item.company ? item.company : "Company name not found",
            {
              x: 70,
              y: this.currentYPosition - 10,
              size: 11,
              color: rgb(0, 0, 0),
              font,
            }
        );

        // Position and duration
        this.currentYPosition -= 20;
        this.activePage.drawText(
            (item.position ? item.position : "Position not specified") +
            (item.duration ? "  [ " + item.duration + " ]" : ""),
            {
              x: 70,
              y: this.currentYPosition - 5,
              size: 9,
              color: rgb(0, 0, 0),
              font,
            }
        );

        // Description
        const descriptionLines = this.wrapText(item.description, 470, font, 8);
        if (descriptionLines.length > 0) {
          this.currentYPosition -= 25;
        }

        descriptionLines.forEach((line) => {
          this.checkPageHeight(null, 120);
          this.activePage.drawText(line || "", {
            x: 70,
            y: this.currentYPosition,
            size: 8,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 13;
        });

        if (list.length != index + 1 && descriptionLines.length == 0) {
          this.currentYPosition -= 25;
        } else {
          this.currentYPosition -= 8;
        }
      });
    },
    makePersonalInformation(font, data) {
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Kişisel Bilgiler" : "Personal Information",
          font
      );

      this.checkPageHeight(null, 120);

      // Address
      const adress = `${this.isLanguage == 1 ? "Adres" : "Address"} : ${
          data.country + " / " + data.city || "-"
      }`;
      this.activePage.drawText(adress, {
        x: 70,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      // Birth date
      const dateBirth = `${
          this.isLanguage == 1 ? "Doğum Tarihi" : "Date Birth"
      } : ${data?.dateBirth || " - "}`;
      this.activePage.drawText(dateBirth, {
        x: (this.pageWidth - font.widthOfTextAtSize(dateBirth.toUpperCase(), 10)) / 2,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      // Driver License
      const driverLicense = `${
          this.isLanguage == 1 ? "Ehliyet" : "Driver License"
      } : ${
          data.driverLicense
              ? this.isLanguage == 1
                  ? data.driverLicense == 2
                      ? "Var"
                      : "Yok"
                  : data.driverLicense == 2
                      ? "Yes"
                      : "No"
              : "-"
      }`;
      this.activePage.drawText(driverLicense, {
        x: (this.pageWidth - font.widthOfTextAtSize(driverLicense.toUpperCase(), 10)) / 1 - 70,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      this.currentYPosition -= 30;

      // Gender
      const gender = `${this.isLanguage == 1 ? "Cinsiyet" : "Gender"} : ${
          data.gender
              ? this.isLanguage == 1
                  ? data.gender == 1
                      ? "Erkek"
                      : "Kadın"
                  : data.gender == 1
                      ? "Man"
                      : "Woman"
              : "-"
      }`;
      this.activePage.drawText(gender, {
        x: (this.pageWidth - font.widthOfTextAtSize(gender.toUpperCase(), 10)) / 2 - 90,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });

      // Birth place
      const placeBirth = `${
          this.isLanguage == 1 ? "Doğum yeri" : "PlaceBirth"
      } : ${data.placeBirth || "-"}`;
      this.activePage.drawText(placeBirth, {
        x: (this.pageWidth - font.widthOfTextAtSize(placeBirth.toUpperCase(), 10)) / 2 + 90,
        y: this.currentYPosition,
        size: 10,
        color: rgb(0, 0, 0),
        font,
      });
    },
    makeLanguages(list, font) {
      this.checkPageHeight();
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Dil Becerileri" : "Languages",
          font
      );

      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight();

          this.activePage.drawText(item.name, {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          let level = "-";
          if (item.level) {
            if (item.level == 4) level = "Ana Dili Seviyesi";
            else if (item.level == 3) level = "İleri Seviye";
            else if (item.level == 2) level = "Orta Seviye";
            else if (item.level == 1) level = "Başlangıç Seviyesi";
          }

          this.activePage.drawText(level, {
            x: 300,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 15;
          }
        }
      });
    },
    makeSkills(list, font) {
      this.checkPageHeight();
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Yetenekler" : "Skills",
          font
      );

      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight();

          let level = "-";
          if (item.level) {
            if (item.level == 3) level = "İleri Seviye";
            else if (item.level == 2) level = "Orta Seviye";
            else if (item.level == 1) level = "Başlangıç Seviyesi";
          }

          this.activePage.drawText(level, {
            x: 300,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          const name = this.wrapText(item.name, 200, font, 10);
          name.forEach((line) => {
            this.activePage.drawText(line, {
              x: 70,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 10;
          }
        }
      });
    },
    makeComputerSkills(list, font) {
      this.checkPageHeight();
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Bilgisayar Becerileri" : "Computer Skills",
          font
      );

      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight();

          let level = "-";
          if (item.level) {
            if (item.level == 3) level = "İleri Seviye";
            else if (item.level == 2) level = "Orta Seviye";
            else if (item.level == 1) level = "Başlangıç Seviyesi";
          }

          this.activePage.drawText(level, {
            x: 300,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          const name = this.wrapText(item.name, 200, font, 10);
          name.forEach((line) => {
            this.activePage.drawText(line, {
              x: 70,
              y: this.currentYPosition,
              size: 10,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 10;
          }
        }
      });
    },
    makeSeminarAndCourses(list, font) {
      this.checkPageHeight();
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Kurs ve Sertifikalar" : "Seminars And Courses",
          font
      );

      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight();

          this.activePage.drawText(item.name, {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          this.currentYPosition -= 15;

          this.activePage.drawText(item.duration || " ", {
            x: 70,
            y: this.currentYPosition,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 25;
          }
        }
      });
    },
    makeProjectsPublications(list, font) {
      this.checkPageHeight();
      this.addHeaderInSection(
          this.isLanguage == 1 ? "Projeler" : "Published Projects",
          font
      );

      list.forEach((item, index) => {
        if (item.name) {
          this.checkPageHeight();

          // Project name
          this.activePage.drawText(item.name, {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 15;

          // Project date
          this.activePage.drawText(item.date || "", {
            x: 70,
            y: this.currentYPosition,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 15;

          // Project URL
          this.activePage.drawText(item.url || "", {
            x: 70,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });
          this.currentYPosition -= 18;

          // Project description
          const description = this.wrapText(item.description, 470, font, 9);
          description.forEach((line) => {
            this.activePage.drawText(line, {
              x: 70,
              y: this.currentYPosition,
              size: 9,
              color: rgb(0, 0, 0),
              font,
            });
            this.currentYPosition -= 15;
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 25;
          }
        }
      });
    },
    makeContactInformations(list, font) {
      this.checkPageHeight();
      this.addHeaderInSection(
          this.isLanguage == 1 ? "İletişim" : "Contact Informations",
          font
      );

      list.forEach((item, index) => {
        if (item.value) {
          this.checkPageHeight();

          this.currentYPosition -= 10;

          // Contact type
          this.activePage.drawText(item.type || "-", {
            x: 70,
            y: this.currentYPosition,
            size: 9,
            color: rgb(0, 0, 0),
            font,
          });

          // Contact value
          this.activePage.drawText(item.value, {
            x: 150,
            y: this.currentYPosition,
            size: 10,
            color: rgb(0, 0, 0),
            font,
          });

          if (list.length != index + 1) {
            this.currentYPosition -= 20;
          }
        }
      });
    },
    checkPageHeight(position, pageHeight) {
      if (this.currentYPosition < (position || 90)) {
        this.pdfDoc.addPage();
        this.activePage = this.pdfDoc.getPages()[this.pdfDoc.getPageCount() - 1];
        this.currentYPosition = this.pageHeight - (pageHeight || 140);

        if (this.backgroundImage) {
          this.activePage.drawImage(this.backgroundImage, {
            x: 0,
            y: 0,
            width: this.pageWidth,
            height: this.pageHeight,
          });
        }
      }
    },
    async generatePDF() {
      try {
        this.$emit("load", true);

        // Initialize PDF document and font
        const {pdfDoc, font} = await this.headerItem();
        const {page, pageWidth, pageHeight, backgroundImage} = await this.addPageBackround(pdfDoc);

        // Set component state
        this.pdfDoc = pdfDoc;
        this.activePage = page;
        this.pageWidth = pageWidth;
        this.pageHeight = pageHeight;
        this.backgroundImage = backgroundImage;
        this.currentYPosition = 730;

        // Get data from store
        const { fullname, biography, position, schoolAndEducation, workexperience, languages, skills, computerSkills, seminarAndCourse, projectsPublications, contactInformation, city, country, dateBirth, placeBirth, driverLicense, gender } = this.$store.state.extractDetail;

        // Utility function to check if a value is valid
        const isValidValue = (value) => {
          return value && value.trim() && value.trim().toLowerCase() !== "null";
        };

        // Add sections based on available data and visibility settings
        if (isValidValue(fullname) && !this.hiddenControl(types.fullname)) {
          this.addFullname(fullname, font);
          this.currentYPosition -= 15;
        }

        if (isValidValue(position) && !this.hiddenControl(types.position)) {
          this.addPosition(position, font);
          this.currentYPosition -= 20;
        }

        if (isValidValue(biography) && !this.hiddenControl(types.biography)) {
          this.addBiography(biography, font);
        }

        if (
            (isValidValue(city) &&
                isValidValue(country) &&
                isValidValue(dateBirth) &&
                isValidValue(placeBirth) &&
                isValidValue(driverLicense) &&
                isValidValue(gender)) &&
            !this.hiddenControl(types.personalInformation)
        ) {
          this.currentYPosition -= this.contentGap;
          this.makePersonalInformation(font, {
            city,
            country,
            dateBirth,
            placeBirth,
            driverLicense,
            gender,
          });
        }

        if (Array.isArray(schoolAndEducation) && schoolAndEducation.length > 0 &&
            !this.hiddenControl(types.schoolAndEducation)) {
          this.currentYPosition -= this.contentGap;
          this.makeEducation(schoolAndEducation, font);
        }

        if (Array.isArray(workexperience) && workexperience.length > 0 &&
            !this.hiddenControl(types.workExperience)) {
          this.currentYPosition -= this.contentGap;
          this.makeProfessionalExperience(workexperience, font);
        }

        if (Array.isArray(languages) && languages.length > 0 &&
            !this.hiddenControl(types.languages)) {
          this.currentYPosition -= this.contentGap;
          this.makeLanguages(languages, font);
        }

        if (Array.isArray(skills) && skills.length > 0 &&
            !this.hiddenControl(types.skills)) {
          this.currentYPosition -= this.contentGap;
          this.makeSkills(skills, font);
        }

        if (Array.isArray(computerSkills) && computerSkills.length > 0 &&
            !this.hiddenControl(types.computerSkills)) {
          this.currentYPosition -= this.contentGap;
          this.makeComputerSkills(computerSkills, font);
        }

        if (Array.isArray(seminarAndCourse) && seminarAndCourse.length > 0 &&
            !this.hiddenControl(types.seminarAndCourse)) {
          this.currentYPosition -= this.contentGap;
          this.makeSeminarAndCourses(seminarAndCourse, font);
        }

        if (Array.isArray(projectsPublications) && projectsPublications.length > 0 &&
            !this.hiddenControl(types.projectsPublications)) {
          this.currentYPosition -= this.contentGap;
          this.makeProjectsPublications(projectsPublications, font);
        }

        if (Array.isArray(contactInformation) && contactInformation.length > 0 &&
            !this.hiddenControl(types.contactInformation)) {
          this.currentYPosition -= this.contentGap;
          this.makeContactInformations(contactInformation, font);
        }

        // Generate and download PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], {type: "application/pdf"});
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${fullname || 'cv'}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        this.$emit("load", false);
      } catch (error) {
        console.error('PDF generation error:', error);
        this.$emit("load", false);
        throw error;
      }
    },
  },
}
</script>