<template>
  <div class="relative group flex justify-center">
    <button
      :class="isPage == page ? 'bg-[#344CA0]' : 'bg-[#344ba08b]'"
      @click="$emit('isPage', page)"
      class="w-10 h-10 rounded-full flex items-center justify-center"
    >
      <img class="w-6" :src="img" alt="" />
    </button>
    <tooltip
      :title="title"
      tipClass="w-[150px] max-sm:!-left-[10px]"
      rotateClass=" max-sm:mr-16"
      :offset="-40"
    />
  </div>
</template>
<script>
import tooltip from "@/components/tooltip.vue";

export default {
  name: "ressume-button",
  props: ["isPage", "img", "title", "page"],
  components: {
    tooltip,
  },
};
</script>