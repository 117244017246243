<template>
    <modal name="personalInformationModal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '35%'"
        :scrollable="true">
        <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
            <h1 class="text-lg">{{
                $t("settings.interview.index.inadequate")
            }}</h1>
            <button @click="hide()" type="button">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <form @submit.prevent="addNewInadequate" class="p-5 grid grid-cols-1 gap-4">
            <customInput 
                v-model="form.inadequateMessage" 
                type="text"
                :placeholder="$t('settings.interview.index.inadequatePlaceholder')"
                :required="true" 
                :is-error="error"
                :title="$t('settings.interview.index.inadequateMessage')" 
            />

            <requiredField />

            <buttonItem class="bgcol text-white rounded text-sm" :load="load">
                {{ $t("general.save") }}
            </buttonItem>
        </form>
    </modal>
</template>
<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import { settingConfigration } from "@/networking/urlmanager";
import requiredField from "@/components/requiredField.vue";

export default {
    name: "InadequateModal",

    data() {
        return {
            modalType: 'save',
            load: false,
            inadequateId: null,
            form: {
                inadequateMessage: '',
            },
            error: false
        };
    },
    components: {
        customInput,
        buttonItem,
        requiredField
    },

    methods: {
        async addNewInadequate() {
            this.load = true;
            const url = this.modalType === 'add' ? settingConfigration.addInadequateMessage : settingConfigration.editInadequateMessage;
            try {
                const method = this.modalType === 'add' ? 'post' : 'put';
                await this.axios[method](
                    url,
                    {
                        msgId: this.inadequateId ? this.inadequateId : undefined,
                        message: this.form.inadequateMessage,
                    }
                );
                this.successMessage();
                this.$emit("success");
                this.hide();
            } catch (error) {
                this.authController(error);
            } finally {
                this.load = false;
            }
        },

        show(type = 'add', item = null) {
            this.modalType = type;
            if (type === 'edit' && item) {
                this.form.inadequateMessage = item.inadequateMessage;
                this.inadequateId = item.id;
            } else {
                this.form.inadequateMessage = '';
                this.inadequateId = null;
            }
            this.$modal.show("personalInformationModal");
        },

        hide() {
            this.$modal.hide("personalInformationModal");
            this.form.inadequateMessage = '';
            this.inadequateId = null;
            this.error = false;
        },
    },
};
</script>
