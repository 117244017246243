<template>
    <label class="relative flex text-[15px] items-center gap-2 p-2 rounded-full cursor-pointer" :for="label" @click="toggleCheckbox">
        <div class="relative flex items-center justify-center">
            <input type="checkbox"
                :id="label"
                :disabled="disabled"
                class="cursor-pointer before:content[''] peer relative h-5 w-5 appearance-none rounded-md border border-cvus-primary-gray-200 transition-all before:absolute before:top-1/2 before:left-1/2 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-cvus-primary-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                :checked="checked" />
            <span
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 peer-checked:opacity-100">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                    stroke="currentColor" stroke-width="1">
                    <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
            </span>
        </div>
        {{ formatText(label) }}
    </label>
</template>

<script>
export default {
    props: {
        checked: Boolean,
        toggle: Function,
        label: String,
        disabled: Boolean
    },

    methods: {
        toggleCheckbox(e) {
            if (this.disabled) return;
            e.stopPropagation();
            this.toggle();
        }
    }
}
</script>