<template>
  <div>
    <div v-if="shouldShowTooltip" class="relative group">
      <div class="truncate text-ellipsis">
        {{ displayText }}
      </div>
      <tooltip :title="text" :top="top" />
    </div>
    <div v-else>
      {{ displayText }}
    </div>
  </div>
</template>

<script>
import tooltip from "@/components/tooltip.vue";

export default {
  name: "CustomTextWrapper",
  components: {
    tooltip
  },
  props: {
    text: {
      type: [String, Number],
      default: ""
    },
    maxLength: {
      type: Number,
      default: 20
    },
    top: {
      type: Boolean,
      default: true
    },
    fallback: {
      type: String,
      default: "-"
    }
  },
  computed: {
    displayText() {
      const formattedText = this.formatText(this.text || this.fallback);
      return this.shouldShowTooltip 
        ? formattedText.slice(0, this.maxLength) + '...' 
        : formattedText;
    },
    shouldShowTooltip() {
      return this.isContentOverflow(this.text?.toString() || '', this.maxLength);
    }
  },
  methods: {
    formatText(text) {
      return text?.toString() || this.fallback;
    },
    isContentOverflow(text, maxLength) {
      return text?.length > maxLength;
    }
  }
};
</script> 