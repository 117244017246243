<template>
  <div class="mt-5 mb-12 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4">
    <div class="flex justify-between items-center">
      <div class="cursor-pointer" @click="$router.back()">
        <h2 class="text-xl textcol font-semibold mt-5">
          <i class="fa-sharp fa-solid fa-arrow-left"></i>
          {{ $t("project.detail.positions") }}
        </h2>
      </div>
      <p class="text-xl textcol font-semibold mt-5 capitalize">
        {{ formatText($route.query.projectName) }}
      </p>
    </div>
    <div class="grid grid-cols-3 gap-4 max-md:grid-cols-1 items-center justify-center my-6">
      <button :disabled="currentStateId" data-v-step="4" @click="$refs.recommendedModal.show()"
        class="w-full rounded-xl border-2 my-auto border-cvus-primary-light2 transition-all duration-300 bg-slate-50 hover:bg-slate-200 h-[125px] flex flex-col items-center gap-1 px-4 py-2"
        :class="{'opacity-50 cursor-not-allowed': currentStateId}">
        <img src="@/assets/project/search-cv.svg" alt="" class="w-auto h-10 max-sm:h-7">
        <div class="flex flex-col">
          <span class="text-cvus-primary-light2 font-semibold text-md max-[1310px]:text-xs">
            {{ $t("project.detail.recommendedResumes") }}
          </span>
          <span class="text-[10px] text-gray-500 max-[1310px]:text-[8px]">
            {{ $t("project.detail.recommendedResumesDescription") }}
          </span>
        </div>
      </button>
      <uploadPanel class="!mb-0 !my-auto" ref="uploadPanel" data-v-step="0" :projects="[{ id: $route.query.id }]"
       :disabled="currentStateId"
       />
      <button  @click="$refs.interviewQuestion.toggleSidebar()" data-v-step="5"
        class="w-full rounded-xl border-2 my-auto border-stone-900 transition-all duration-300 bg-stone-50 hover:bg-stone-200 h-[125px] flex flex-col items-center gap-1 px-4 py-2"
        :class="{'opacity-50 cursor-not-allowed': currentStateId}">
        <div class="w-10 h-10 flex items-center justify-center rounded-full bg-stone-100 text-cvus-primary">
          <Icon icon="mdi:question-box-multiple-outline" class="h-6 w-6" />
        </div>
        <div class="flex flex-col">
          <span class="text-stone-800 font-semibold text-md max-[1310px]:text-xs">
            {{ $t("project.detail.addQuestion") }}
          </span>
          <span class="text-[10px] text-gray-500 max-[1310px]:text-[8px]">
            {{ $t("project.detail.addQuestionDescription") }}
          </span>
        </div>
      </button>
    </div>

    <recommendedModal ref="recommendedModal" @refresh="getAll()" />
    <preInterviewDetail ref="preInterviewDetail" :Detail="selected" :projectId="$route.query.id" @refresh="getAll()" />
    <filtering ref="filtering" :data="List" @filterAll="(r) => getAll(r)" @loading="setLoad" @changePage="setPage" />
    <interviewQuestion ref="interviewQuestion" @refresh="getAll()" />
    <addEmail ref="addEmail" @refresh="getAll()" :cvId="selected.cvId" />
    <interviewPanel ref="interviewPanel" @refresh="getAll()" />
    <shortListPanel ref="shortListRef" @refresh="getAll()" />
    <history ref="history" :Detail="selected" :isPreInterviewDetail="false" />

    <referenceAndPayment ref="referenceAndPaymentRef" @refresh="getAll()" />


    <customTable :items="List" :columns="tableColumns" :loading="load" :current-page="currentPage"
      :page-count="pageCount" :total-count="totalCount" :size="'sm'" @page-change="setPage" @row-click="handleRowClick"
      @select-all="selectAll" @sort="handleSort" :sortType="sortType" :selectedSortKey="selectedSortKey">
      <!-- Header slot with search and filter -->
      <template #header>
        <div class="flex items-center justify-around flex-wrap gap-8 w-full">
          <div class="flex gap-5 max-sm:w-full max-sm:flex-wrap w-auto justify-around">

            <asyncButton :disabled="currentStateId" data-v-step="4" :text="$t('project.detail.startInterview')"
              :load="loading" @click="sendInterviewMail()" :loadText="$t('project.detail.interviewProcessLoad')"
              buttonClass="text-sm max-sm:!w-full text-white" containerClass="max-sm:w-full" size="sm"
              containerBackground="bg-cvus-primary-light border-2 border-cvus-primary-light !shadow  justify-center gap-3 !w-full">
              <template #textLeft>
                <Icon v-if="!loading" icon="hugeicons:artificial-intelligence-02" class="text-white text-xl" />
              </template>
            </asyncButton>

            <asyncButton :text="$t('project.detail.export')"
              @click="exportTable" buttonClass="text-sm max-sm:!justify-start"
              :load="exportLoad" :loadText="$t('project.detail.export')"
              containerClass="max-sm:w-full" size="sm"
              containerBackground="bg-cvus-primary border-2 border-cvus-primary !text-white !shadow  justify-center gap-3 !w-full">
              <template #textLeft>
                <Icon v-if="!exportLoad" icon="mdi:export-variant" class="text-white text-xl" />
              </template>
            </asyncButton>

            <asyncButton :text="$t('project.detail.shortList')" @click="$refs.shortListRef.toggleSidebar()"
              buttonClass="text-sm max-sm:!w-full " containerClass="max-sm:w-full" size="sm"
              containerBackground="bg-lime-100 border-2 border-lime-200 !text-black !shadow  justify-center gap-3 !w-full">
              <template #textLeft>
                <Icon icon="mdi:list-box-outline" class="text-black text-xl" />
              </template>
            </asyncButton>

            <asyncButton @click="$refs.referenceAndPaymentRef.toggleSidebar()" buttonClass="text-sm max-sm:!w-full "
              :text="$t('project.detail.referenceAndPayment')" containerClass="max-sm:w-full" size="sm"
              containerBackground="bg-amber-100 border-2 border-amber-200 !text-black !shadow  justify-center gap-3 !w-full">

              <template #textLeft>
                <Icon icon="ic:outline-person-search" class="text-black text-xl" />
              </template>
            </asyncButton>
            <button @click="$refs.interviewPanel.toggleSidebar()"
              class="py-2 px-8 flex items-center gap-2 bg-cvus-primary hover:text-base hover:shadow-xl rounded-t text-white text-sm font-medium cursor-pointer shadow-xl transform rotate-0 fixed bottom-0 z-50 h-10 right-10 transition-all ease-out">
              <span class="animate-pulse flex gap-3 items-center">
                <img src="@/assets/project/aiInterview.png" alt="" class="w-6" />
                {{ $t("project.detail.interviewProcess") }}
              </span>
            </button>



          </div>

          <!-- Search and filter section -->
          <div class="w-[200px] tableSearch relative ml-auto border-none outline-none">
            <input v-model="search" @keypress.enter="getSearch()" type="text"
              class="h-full w-full outline-none border-2 border-cvus-primary shadow-cvus-primary/25 shadow-md focus:shadow-cvus-primary/50 focus:!border-cvus-primary-light2 pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
              :placeholder="$t('project.detail.table.search')" />
            <button class="px-3 textcol absolute right-0">
              <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
            </button>
            <button @click="$refs.filtering.toggleSidebar()" class="absolute right-9">
              <div class="relative group flex justify-center">
                <Icon :icon="
                  $refs?.filtering?.isFilterActive ? 'mage:filter-fill' : 'mage:filter'
                  " class="text-cvus-primary text-2xl" :class="{
                    'text-cvus-primary-light': $refs?.filtering?.isFilterActive,
                    'text-cvus-primary': !$refs?.filtering?.isFilterActive,
                  }" />
              </div>
            </button>

          </div>
        </div>
      </template>

      <!-- Custom cell templates -->
      <template #checked="{ item }">

        <customCheckbox v-if="isEmail(item)" :disabled="item.stateId == 7 || item.stateId == 8 || item.stateId == 9"
          :value="item.checked" class="mr-2" @input="selectCheckbox(item, !item.checked)" />
        <div v-else class="relative group">
          <i class="fa-solid fa-xmark text-red-600 text-lg"></i>
          <tooltip :title="$t('project.detail.table.notEmail')" tipClass="z-10 w-[160px] !top-6 -ml-2 text-center"
            :top="true" />
        </div>
      </template>
      <template #id="{ item }">
        <span class="cursor-pointer font-bold" @click="$router.push(`/cv/profile/${item.cvId}`)">
          #{{ formatText(item.id) }}
        </span>
      </template>


      <template #fullname="{ item }">
        <div class="flex flex-col py-1 gap-1 w-full">
          <span class="cursor-pointer" @click="$router.push(`/cv/profile/${item.cvId}`)">
            {{ formatText(item.fullname) }}
          </span>
          <span class="text-xs text-gray-500 truncate">
            {{ parseEmail(item.contactEmail) }}
          </span>
        </div>
      </template>


      <template #position="{ item }">
        <span class="cursor-pointer h-full flex items-center " @click="$router.push(`/cv/profile/${item.cvId}`)">
          {{ formatText(item.position) }}
        </span>
      </template>

      <template #status="{ item }">
        <div class="w-full flex">
          <div v-if="isEmail(item) && item.stateId == 12"
            class="text-red-500 flex gap-2 flex-col py-1 items-center justify-center">
            <span class="text-[10px] text-center">{{ stateName(12

              ) }}</span>
            <asyncButton type="button" :text="$t('project.detail.table.statusMessages.sendMailAgain')"
              buttonClass="w-fit text-[8px] !p-0" awesome="fa-solid fa-envelope"
              containerBackground="!text-white bgcol !px-2 !py-1 !h-fit justify-center !w-fit"
              spinnerClass="!border-t-transparent !border-black !text-black" :load="mailLoad"
              @click.stop="sendMailAgain(item)" />
          </div>
          <label v-else-if="isEmail(item)" class="cursor-pointer">
            {{ stateName(item.stateId) }}
          </label>
          <button v-else class="text-red-500 animate-pulse">
            {{ $t("project.detail.table.addEmail") }}
          </button>
        </div>
      </template>
      <template #date="{ item }">
        <p @click="() => $router.push(`/cv/profile/${item.cvId}`)">
          {{ item.cvDate | getDate }}
        </p>
      </template>

      <template #actions="{ item }">
        <div class="flex justify-between gap-2 items-center">


          <div class="flex justify-end items-center gap-3">
            <asyncButton v-if="item.stateId > 5 && item.stateId !=12 && item.stateId != 10" type="button"
              @click="() => (selected = item) && $refs.preInterviewDetail.show()" :hiddenText="true"
              buttonClass="w-full !px-0 !gap-0" awesome="fa-solid fa-magnifying-glass"
              containerBackground="!text-black md:!w-auto justify-center !w-full shadow-none" :isTooltip="true"
              :toolTipTitle="$t('project.detail.table.interviewHistory')" :tooltipTop="true" />

            <asyncButton v-if="item.stateId == 1" type="button" @click="deleteItem(item)" :hiddenText="true"
              buttonClass="w-full !px-0 !gap-0" awesome="fa-solid fa-trash-can text-base"
              containerBackground="!text-black md:!w-auto justify-center !w-full shadow-none" :isTooltip="true"
              :toolTipTitle="$t('project.detail.table.deleteRessume')" :tooltipTop="true" />
            <button @click=" () => ((selected = item), $refs.history.toggleSidebar())"
              class="relative group flex justify-center">
              <i class="fa-solid fa-clock-rotate-left w-4"></i>
              <tooltip :title="$t('cv.index.toolTip.history')" tipClass="z-50 w-[160px] !-right-1 !top-6 "
                rotateClass="ml-[140px]" :top="true" />
            </button>
          </div>
        </div>
      </template>
    </customTable>
    <tour :steps="steps" :moduleId="4" v-if="!$store.state.userData.tourModuleId.includes(4)" />
    <!-- <pagination v-model="currentPage" :pageCount="pageCount" :totalRowCount="totalCount" /> -->
  </div>
</template>
<script>
//import card from "@/components/card.vue";
import preInterviewDetail from "./components/preInterview/detail/index.vue";
import interviewPanel from "./components/preInterview/interviewPanel";
import recommendedModal from "./components/recommendedModal.vue";
import tour from "@/components/tour.vue";

import filtering from "./components/preInterview/question/filterPanel.vue";
import interviewQuestion from "./components/preInterview/question/index.vue";
import shortListPanel from "./components/shortlist/index.vue";

import tooltip from "@/components/tooltip.vue";
import asyncButton from "@/components/button.vue";
import addEmail from "./components/addEmail.vue";

import uploadPanel from "../../cv/components/uploadPanel.vue";
import customTable from "@/components/customTable.vue";
import history from "../../cv/components/history.vue";
import moment from "moment";
import "moment/locale/tr";

import {projectConnect, projectInterview} from "@/networking/urlmanager";
import {projectUserTour} from "@/tourmodel/tour.js";
import customCheckbox from "@/components/customCheckbox.vue";
import referenceAndPayment from "./components/referenceAndPayment/index.vue";

export default {
  name: "project-user",
  components: {
    //card,
    interviewPanel,
    asyncButton,
    preInterviewDetail,
    recommendedModal,
    tour,
    tooltip,
    filtering,
    interviewQuestion,
    addEmail,
    shortListPanel,
    uploadPanel,
    customTable,
    customCheckbox,
    history,
    referenceAndPayment
  },
  data() {
    return {
      show: false,
      isModalOver: false,
      projectUserFilter: false,
      projectUserState: [
        {
          id: 1,
          name: this.$t("state.noAction"),
          active: false,
        },
        {
          id: 2,
          name: this.$t("state.waiting"),
          active: false,
        },
        {
          id: 3,
          name: this.$t("state.started"),
          active: false,
        },
        {
          id: 4,
          name: this.$t("state.completed"),
          active: false,
        },
        {
          id: 5,
          name: this.$t("state.didNotAgree"),
          active: false,
        },
      ],
      steps: projectUserTour,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      loading: false,
      load: false,
      List: [],
      projectId: 1,
      sendMail: [],
      selected: "",
      filterParams: null,
      mailLoad: false,
      sortType: this.$route.query.order || 'none',
      selectedSortKey: this.$route.query.sort || null,
      exportLoad: false,
    };
  },

  methods: {
    stateName(id) {
      const statusMessages = {
        1: "project.detail.table.statusMessages.noAction",
        2: "project.detail.table.statusMessages.preparing",
        3: "project.detail.table.statusMessages.prepared",
        4: "project.detail.table.statusMessages.sent",
        5: "project.detail.table.statusMessages.inProgress",
        6: "project.detail.table.statusMessages.decisionBlocked",
        7: "project.detail.table.statusMessages.completed",
        8: "project.detail.table.statusMessages.notEnough",
        9: "project.detail.table.statusMessages.shortlisted",
        10: "project.detail.table.statusMessages.error",
        11: "project.detail.table.statusMessages.dismissed",
        12: "project.detail.table.statusMessages.mailError",
        13: "project.detail.table.statusMessages.reference",
      };

      return (
          this.$t(statusMessages[id]) ||
          this.$t("project.detail.table.statusMessages.unknown")
      );
    },
    isEmail(item) {
      const contactEmailRaw = this.listFormatController(item?.contactEmail);
      if (!contactEmailRaw) return false;
      const contactEmail = Array.isArray(contactEmailRaw) ? contactEmailRaw : [];
      return contactEmail.length > 0 && !!contactEmail[0];
    },
    getSearch() {
      this.$refs.filtering.clearFilters();
    },
    async getAll(val) {
      this.load = true;
      this.List = [];
      if (val) {
        this.load = true;
        const {totalCount, detail, pageCount} = val;
        this.totalCount = totalCount;
        this.pageCount = pageCount;
        this.List = detail.map(item => ({...item, checked: false}));
        this.load = false;
        return;
      }

      await this.axios
          .get(projectConnect.getUserAll, {
            params: {
              projectId: this.$route.query.id,
              page: this.currentPage,
              search: this.search,
              sort: this.selectedSortKey && (this.sortType && this.sortType !== 'none') ? this.selectedSortKey : undefined,
              order: (this.sortType && this.sortType !== 'none') && this.selectedSortKey ? this.sortType : undefined,
            },
          })
          .then((res) => {
            const {totalCount, detail, pageCount, stateId} = res.data.data;
            this.totalCount = totalCount;
            this.pageCount = pageCount;
            this.List = detail;if(stateId){
            this.$store.commit("setStateId", stateId);
          }
          })
          .catch((err) => {
            console.log(err);
            this.authController(err);
          })
          .finally(() => {
            this.load = false;
          });
    },
    async sendInterviewMail() {
      if (this.checkedList.length > 0) {
        try {
          const result = await this.verifiedMessageThree(
              {
                message: this.$t("project.warning.startInterview"),
                yesButton: this.$t("project.warning.generateQuestion"),
                noButton: this.$t("project.warning.continue"),
                thirdButton: this.$t("project.warning.cancel"),
                timer: 1000000,
              }
          )

          let respose;
          if (result.isConfirmed) {
            this.loading = true;
            respose = await this.createQuesion()
          } else if (result.isDenied) {
            this.loading = true;
            respose = await this.handlePrepareInterview()
          }
          if (result.isConfirmed || result.isDenied) {
            this.successMessage(respose.data.message);
            this.List.forEach(item => {
              item.checked = false;
            });
          }
        } catch (err) {
          console.error(err);
          this.authController(err);
        }finally {
          this.loading = false;
        }
      } else {
        this.warningMesage(this.$t("project.warning.selectUsers"), 5000);
      }
    },

    async handlePrepareInterview() {
      return await this.axios.post(projectInterview.mail, {
        list: JSON.stringify(this.checkedList),
        projectId: this.$route.query.id,
      });
    },
    async createQuesion() {
      const data = await this.axios.post(projectInterview.createQuesion,
          {
            projectId: this.$route.query.id,
            list: JSON.stringify(
                this.checkedList
            ),
          }
      )
      await this.$refs.interviewPanel.toggleSidebar();
      return data
    },

    deleteItem(item) {
      this.verifiedMessage("'" + item.fullname + "' " + this.$t("project.verifiedMessages.userRemove"))
          .then((result) => {
            if (result.isConfirmed) {
              this.axios.post(projectConnect.delete,
                  {
                    rowId: item.id,
                    projectId: this.$route.query.id,
                    projectName: this.$route.query.projectName,
                    fullname: item.fullname,
                    stateId: item.stateId,
                  },
                  {
                    headers: {
                      Authorization: "Bareer " + this.$store.state.userData.token,
                    },
                  }
              )
                  .then((res) => {
                    this.$store.commit("setProjectListDelete", this.$route.query.id);
                    this.successMessage(res.data.message);
                    this.getAll();
                  })
                  .catch((err) => {
                    console.error(err);
                    const errorMessage = err.response
                        ? err.response.data.message
                        : err.message;
                    this.authController(errorMessage);
                  });
            }
          });
    },
    selectAll(val) {
      const checkedList = [...this.List];

      if (val) {
        
        checkedList.forEach(item => {
          const contactEmailRaw = item.contactEmail;
          if (!contactEmailRaw) return null; // Eğer contactEmail null/undefined ise geçersiz say

          const contactEmail = this.listFormatController(contactEmailRaw);
          const statusId = this.statusFilter(item)?.id;
          if (
              (statusId <= 2 || statusId === 5) &&
              contactEmail.length > 0 &&
              contactEmail[0]
          ) {
            item.checked = true;
          }else{
            item.checked = false;
          }
        });
      } else {
        checkedList.forEach(item => {
          item.checked = false;
        });
      }
      this.List = [...checkedList];
    },
    selectCheckbox(item, bool) {
      const checkedList = [...this.List];
      checkedList.map(it => {
        if(it.id === item.id) {
          it.checked = bool;
        }
      });
      this.List = [...checkedList];
    },
    statusFilter(val) {
      if (!val || !val.stateId) return null;
      
      // Special handling for stateId 2 with interview date
      if (val.stateId === 2 && val.sendInterviewDate) {
        const endDate = moment(val.sendInterviewDate);
        const nowDate = moment();
        const diff = endDate.diff(nowDate, "hours");
        
        if (diff <= 0) {
          return {
            id: 5,
            name: this.$t("project.detail.table.statusMessages.inProgress")
          };
        }
      }
      
      // Map stateIds directly to their corresponding messages
      switch (val.stateId) {
        case 1:
          return { id: 1, name: this.$t("project.detail.table.statusMessages.noAction") };
        case 2:
          return { id: 2, name: this.$t("project.detail.table.statusMessages.preparing") };
        case 3:
          return { id: 3, name: this.$t("project.detail.table.statusMessages.prepared") };
        case 4:
          return { id: 4, name: this.$t("project.detail.table.statusMessages.sent") };
        case 5:
          return { id: 5, name: this.$t("project.detail.table.statusMessages.inProgress") };
        case 6:
          return { id: 6, name: this.$t("project.detail.table.statusMessages.decisionBlocked") };
        case 7:
          return { id: 7, name: this.$t("project.detail.table.statusMessages.completed") };
        case 8:
          return { id: 8, name: this.$t("project.detail.table.statusMessages.notEnough") };
        case 9:
          return { id: 9, name: this.$t("project.detail.table.statusMessages.shortlisted") };
        case 10:
          return { id: 10, name: this.$t("project.detail.table.statusMessages.error") };
        case 11:
          return { id: 11, name: this.$t("project.detail.table.statusMessages.dismissed") };
        case 12:
          return { id: 12, name: this.$t("project.detail.table.statusMessages.mailError") };
        default:
          return null;
      }
    },

    async sendMailAgain(item) {
      this.mailLoad = true;
      item.checked = true;
      await this.createQuesion();
      this.mailLoad = false;
    },
    setLoad(val) {
      this.load = val;
    },

    setPage(val) {
      if (val != this.currentPage) {
        this.currentPage = val;
      } else {
        this.filterOrGet();
      }
    },
    filterOrGet() {
      if (this.$refs.filtering.isFilterActive) {
        this.$refs.filtering.applyFilters(this.currentPage);
      } else {
        this.getAll();
      }
    },
    handleRowClick(data) {
      this.$router.push(`/cv/profile/${data.item.cvId}`);
    },
    handleSort(data) {
      if (data.order == 'none') {
        this.sortType = 'none';
        this.selectedSortKey = null;
      } else {
        this.sortType = data.order;
        this.selectedSortKey = data.key;
      }
      if (this.currentPage == 0) {
        this.$router.push({
          query: {
            ...this.$route.query,
            sort: this.selectedSortKey && (this.sortType && this.sortType !== 'none') ? this.selectedSortKey : undefined,
            order: (this.sortType && this.sortType !== 'none') && this.selectedSortKey ? this.sortType : undefined,
          }
        });
        this.filterOrGet();
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            sort: this.selectedSortKey && (this.sortType && this.sortType !== 'none') ? this.selectedSortKey : undefined,
            order: (this.sortType && this.sortType !== 'none') && this.selectedSortKey ? this.sortType : undefined,
          }
        });
        this.currentPage = 0;
      }
    },
    async exportTable() {
      this.exportLoad = true;
      try {
        const response = await this.axios.get(projectConnect.export, {
          params: {
            projectId: this.$route.query.id,
          },
          responseType: 'blob'
        });

        // Create a blob from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        
        // Set the filename
        const fileName = `${this.$route.query.projectName}.xlsx`;
        link.setAttribute('download', fileName);
        
        // Append to body, click and remove
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        this.successMessage();
      } catch (err) {
        console.error(err);
        this.authController(err);
      } finally {
        this.exportLoad = false;
      }
    },
    
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("project.detail.filterStatus.noDate");
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'checked',
          label: '',
          width: '80px',
          selectAll: true
        },
        {
          key: 'id',
          label: this.$t("project.detail.table.candidateNo"),
          width: '100px',
          format: (value) => `#${value}`
        },
        {
          key: 'fullname',
          label: this.$t("project.detail.table.ressume"),
          width: '200px',
          clickable: true,
          sticky: this.isMobile ? false : true
        },
        {
          key: 'position',
          label: this.$t("project.detail.table.position"),
          width: '200px',
          clickable: true,
          sticky: this.isMobile ? false : true
        },
        {
          key: 'status',
          label: this.$t("project.detail.table.status"),
          width: '200px',
        },
        {
          key: 'recommendScore',
          label: this.$t("project.detail.table.recommendScore"),
          width: '200px',
          format: (value) => value ? value + '%' : '-',
          sortable: true
        },
        {
          key: 'recomendedInterviewScore',
          label: this.$t("project.detail.table.recomendedInterviewScore"),
          width: '200px',
          format: (value) => value ?? '-',
          sortable: true
        },
        {
          key: 'date',
          label: this.$t("project.detail.table.cvDate"),
          clickable: true,
          width: '200px'
        }
      ];
    },
    checkedList() {
      return this.List.filter(item => item.checked);
    },
    currentStateId() {
      return this.$store.state.project.stateId == 3;
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    projectId(newVal) {
      this.rowId = newVal;
      this.getAll();
    },
    currentPage() {
      this.filterOrGet()
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>

<style scoped>
.swal-wide{
    width:850px !important;
}
</style>