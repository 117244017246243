<template>
  <modal name="editProject" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '55%'" :scrollable="true"
    class="scroltype" @closed="hide()">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">{{ $t("project.components.edit.title") }}</h1>
      <button @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <loadingDocument :isLoading="project.load" v-if="project.load"
      container-class=" w-full text-center justify-center items-center flex h-[487px]" />
    <div v-else>
      <stepOne v-show="project.activeStep == 1" :isEdit="true" ref="stepOne" :allDisabled="!isPositionEditable" />
      <stepTwo v-show="project.activeStep == 2" :isEdit="true" ref="stepTwo" :allDisabled="!isPositionEditable"  />
      <stepThree v-show="project.activeStep == 3" :isEdit="true" ref="stepThree" :allDisabled="!isPositionEditable" />
    </div>
    <div class="flex w-full p-2">
      <infoBox v-if="!isPositionEditable">
        {{ $t("project.components.edit.positionNotEditable") }}
      </infoBox>
    </div>

    <div class="px-4" v-if="!project.load">
      <requiredField />
    </div>
    <div class="flex w-full h-16 items-center px-4" v-if="!project.load">
      <div class="flex flex-1">
        <div class="w-6/12">
          <buttonItem :text="$t('project.components.add.back')" @click="backActive" :containerBackground="
              project.activeStep == 1 ? 'bg-gray-400' : 'bgcol'
            " />
        </div>
      </div>

      <div class="flex flex-1 justify-center">
        <p class="text-center font-bold textcol">
          {{ project.activeStep }} / 3
        </p>
      </div>

      <div class="flex flex-1 justify-end">
        <div :class="project.activeStep == 3 ? 'w-full' : 'w-1/2'">
          <buttonItem v-if="project.activeStep < 3" :text="$t('project.components.add.forward')" @click="nextActive" />

          <div class="flex flex-1 items-center space-x-2 w-full justify-end">
            <buttonItem @click="save" v-if="project.activeStep == 3"
              :class="project.activeStep == 3 ? 'w-1/2' : 'w-6/12'" :load="project.load"
              :text="$t('project.components.add.save')" :loadText="$t('project.components.add.saveLoad')"
             />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import requiredField from "@/components/requiredField.vue";
import stepThree from "./utility/stepThree.vue";
import stepOne from "./utility/stepOne.vue";
import stepTwo from "./utility/stepTwo.vue";
import buttonItem from "@/components/button.vue";
import { project } from "@/networking/urlmanager";
import loadingDocument from "@/components/loadingDocument.vue";
import infoBox from "@/components/infoBox.vue";

export default {
  name: "editProject",
  props: ["rowId"],
  components: {
    stepThree,
    stepOne,
    stepTwo,
    buttonItem,
    requiredField,
    loadingDocument,
    infoBox,
  },
  data() {
    return {
      required: false,
      initialRowId: null,
      isPositionEditable: true,
    };
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
      stepOne: (state) => state.project.stepData.stepOne,
      stepTwo: (state) => state.project.stepData.stepTwo,
      stepThree: (state) => state.project.stepData.stepThree,
    }),

    preparePostData() {
      return {
        advisors: this.stepOne.advisors,
        rowId: this.rowId,
        priority: this.stepOne.priority,
        educationList: JSON.stringify(this.stepTwo.educationType),
        experienceDuration: JSON.stringify(this.stepTwo.experienceDuration),
        projectName: this.stepOne.projectName,
        innovationList: this.stepThree.innovations,
        position: this.stepOne.positionList,
        languageList: JSON.stringify(this.stepTwo.languageList),
        companyName: this.stepOne.companyName,
        endDate: this.stepOne.endDate,
        keywordList: this.stepThree.keywords,
        institutionList: JSON.stringify(this.stepTwo.institutionList),
        departmentList: JSON.stringify(this.stepTwo.departmentList),
        adType: this.stepOne.adType,
        workingModel: this.stepOne.workingModel,
        description: this.stepThree.description,
        cityList: this.stepTwo.cityList,
        recruitmentCount: this.stepOne.recruitmentCount,
        positionLanguage: this.stepThree.positionLanguage,
        advLanguage: this.stepThree.advLanguage,
        note: this.stepThree.note,
        isPositionEditable: this.isPositionEditable,
      };
    },
  },
  methods: {
    ...mapMutations({
      updateProjectStep: "UPDATE_PROJECT_STEP",
      setProjectActiveStep: "SET_PROJECT_ACTIVE_STEP",
      setStepOneData: "SET_STEP_ONE_DATA",
      setStepTwoData: "SET_STEP_TWO_DATA",
      setStepThreeData: "SET_STEP_THREE_DATA",
      setLoadState: "SET_LOAD_STATE",
      setChangedState: "SET_CHANGED_STATE",
    }),

    ...mapActions({
      saveProject: "saveProject",
      resetProjectData: "resetProjectData",
    }),

    async save() {
      if (this.checkRequiredFieldsStepThree()) {
        return;
      }
      this.setLoadState(true);
      await this.postEdit();
    },

    async postEdit() {
     
      try {
        const res = await this.axios.post(project.edit, this.preparePostData);
        this.successMessage(res.data.message);
        this.setLoadState(false);
        this.hide();
        this.$emit("refresh", true);
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },

    handleError(error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      this.authController(errorMessage);
      this.setLoadState(false);
    },

    checkRequiredFields() {
      const stepOneFields = {
        companyName: this.stepOne.companyName
          ? true
          : this.$t("project.components.requiredFields.companyName"),
        projectName: this.stepOne.projectName
          ? true
          : this.$t("project.components.requiredFields.projectName"),
        positionListId: this.stepOne.positionList.id
          ? true
          : this.$t("project.components.requiredFields.organizationPosition"),
        priority: this.stepOne.priority
          ? true
          : this.$t("project.components.requiredFields.priority"),
        endDate: this.stepOne.endDate
          ? true
          : this.$t("project.components.requiredFields.endDate"),
        recruitmentCount: this.stepOne.recruitmentCount
          ? true
          : this.$t("project.components.requiredFields.recruitmentCount"),
   
        advisors:
          this.stepOne.advisors.length > 0
            ? true
            : this.$t("project.components.add.advisors"),
        workingModel: this.stepOne.workingModel
          ? true
          : this.$t("project.components.requiredFields.workingModel"),
      };
      const stepTwoFields = {
        educationType:
          this.stepTwo.educationType.length > 0
            ? true
            : this.$t("project.components.requiredFields.education"),
        experienceDuration: this.stepTwo.experienceDuration
          ? true
          : this.$t("project.components.requiredFields.workExperience"),
      };
      const stepThreeFields = {
        keywords: this.stepThree.keywords
          ? true
          : this.$t("project.components.requiredFields.keywords"),
        innovations: this.stepThree.innovations
          ? true
          : this.$t("project.components.requiredFields.innovations"),
        positionLanguage: this.stepThree.positionLanguage?.id
          ? true
          : this.$t("project.components.add.positionLanguage"),
        advLanguage: this.stepThree.advLanguage?.id
          ? true
          : this.$t("project.components.add.advLanguage"),
      };

      let missingFields;
      if (this.project.activeStep === 1) {
        missingFields = stepOneFields;
      } else if (this.project.activeStep === 2) {
        missingFields = stepTwoFields;
      } else if (this.project.activeStep === 3) {
        missingFields = stepThreeFields;
      }

      const errorMessages = Object.values(missingFields).filter(
        (message) => message !== true
      );

      if (errorMessages.length > 0) {
        this.warningMesage(
          this.$t("project.components.add.fillInAllFields") +
            " => " +
            errorMessages.join(" - "),
          5000
        );
        this.required = false;
      } else {
        this.required = true;
      }
    },
    checkRequiredFieldsStepThree() {
      let isError = false;
      if (!this.stepThree.positionLanguage?.id) {
        this.warningMesage(this.$t("project.components.add.fillInAllFields") +
            " => " + this.$t("project.components.add.positionLanguage"));
        isError = true;
      }
      if (!this.stepThree.advLanguage?.id) {
        this.warningMesage(this.$t("project.components.add.fillInAllFields") +
            " => " + this.$t("project.components.add.advLanguage"));
        isError = true;
      }
      return isError;
    },
    nextActive() {
      this.checkRequiredFields();
      if (this.required && this.project.activeStep <= 3) {
        this.setProjectActiveStep(this.project.activeStep + 1);
      }
    },

    backActive() {
      if (this.project.activeStep <= 3 && this.project.activeStep > 1) {
        this.setProjectActiveStep(this.project.activeStep - 1);
      }
    },

    hide() {
      this.isPositionEditable = true;
      this.$modal.hide("editProject");
    },


    close() {
      if (this.project.changeMessage == "editProject") {
        this.resetProjectData();
        this.isPositionEditable = true;
      }
      this.$modal.hide("editProject");
    },

    show() {
      this.$modal.show("editProject");

      if (this.initialRowId !== this.rowId) {
        this.resetProjectData();
        this.setProjectActiveStep(1);
      }

      this.initialRowId = this.rowId;

      if (this.project.changeMessage !== "editProject") {
        this.resetProjectData();
        this.setProjectActiveStep(1);
      }

      this.setChangedState("editProject");

      setTimeout(() => {
        if (this.rowId) this.getDetail();
      }, 100);
    },

    async getDetail() {
      this.setLoadState(true);
      try {
        const res = await this.axios.get(
          project.getDetail + "?rowId=" + this.rowId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );
        this.handleDetailResponse(res.data.data);
      } catch (err) {
        console.log(err)
        this.warningMesage(err?.response?.data?.message);
      } finally {
        this.setLoadState(false);
      }

    },

    handleDetailResponse(data) {
      if(!data.isPositionEditable){
        this.isPositionEditable = false;
      }
      const {
        detail,
        languages,
        positions,
        education,
        keywords,
        department,
        institution,
        city,
        innovations,
        advisors,
      } = data;

      const {
        id,
        companyName,
        endDate,
        experienceDuration,
        projectName,
        isCompany,
        priority,
        adType,
        workingModel,
        description,
        recruitmentCount,
        evaluationCriteria,
        note,
        positionLanguage,
        advLanguage,
      } = detail?.[0];

      const stepOneData = {
        id,
        adType,
        projectName,
        companyName,
        endDate,
        isCompany,
        priority,
        recruitmentCount,
        positionList: positions,
        advisors,
        workingModel: workingModel,
      };

      const stepTwoData = {
        experienceDuration: JSON.parse(experienceDuration),
        educationType: education,
        institutionList: institution,
        languageList: languages,
        departmentList: department,
        cityList: city,
      };

      const stepThreeData = {
        keywords,
        description,
        evaluationCriteria,
        note,
        innovations,
        isDescription: !!description,
        positionLanguage: positionLanguage,
        advLanguage: advLanguage,
      };

      this.setStepOneData(stepOneData);
      this.setStepTwoData(stepTwoData);
      this.setStepThreeData(stepThreeData);
    },
  },
};
</script>
