<template>
  <sideModal
    ref="preInterviewFilters"
    modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full"
    :isHeader="true"
    :headerTitle="$t('project.detail.filterPanel.title')"
  >
  <template v-slot:headerRight>
      <button @click="clearFilters()" class="flex items-center">
        <span class="text-red-500 border-b border-red-500 text-sm mr-2">{{
          $t("cv.components.filterPanel.reset")
        }}</span>
      </button>
    </template>
    <template v-slot:content>
      <div class="overflow-y-auto scroltype h-[85vh] mt-2 p-2 max-sm:p-5">
        <form @submit.prevent="getFilter" class="p-2">
          <div class="mb-4 pb-5 border-b border-gray-300">

          <p class="text-sm pb-1">
            -
            {{ $t("project.detail.filterPanel.state") }}
          </p>
          <select
            name=""
            id=""
            class="w-full border-2 border-344CA0 p-2 rounded mb-5 text-sm"
            v-model="stateId"
          >
            <option
              v-for="(item, index) in projectUserState"
              :key="index"
              :value="item.id"
            >
              {{ $t(item.name) }}
            </option>
          </select>
          </div>
          <div v-for="(item, index) in List" :key="index">
            <div
              class="mb-4 pb-5 border-b border-gray-300"
              v-if="!(item.questionType <= 1)"
            >
              <p class="text-sm pb-4">- {{ formatText(item.question) }}</p>

              <div
                v-if="item.questionType == 2"
                class="flex justify-between gap-5"
              >
                <input
                  v-model="item.min"
                  type="number"
                  placeholder="min"
                  class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
                />
                <input
                  v-model="item.max"
                  type="number"
                  placeholder="max"
                  class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
                />
              </div>
              <input
                v-if="item.questionType == 3"
                type="number"
                v-model="item.number"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              />
              <div
                v-if="item.questionType == 4"
                class="flex justify-between gap-5 text-sm"
              >
                <select
                  v-model="item.yesNo"
                  name=""
                  id=""
                  class="w-full border-2 border-344CA0 p-2 rounded text-sm"
                >
                  <option value="0">
                    {{ $t("project.detail.filterPanel.choose") }}
                  </option>
                  <option value="1">
                    {{ $t("project.detail.filterPanel.yes") }}
                  </option>
                  <option value="2">
                    {{ $t("project.detail.filterPanel.no") }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- Recommend Score Filter -->
          <div class="mb-4 pb-5 border-b border-gray-300">
            <p class="text-sm pb-4">- {{ $t('project.detail.table.recommendScore')  }}</p>
            <div class="flex justify-between gap-5">
              <input
                v-model="recommendScoreFilter.min"
                type="number"
                placeholder="min"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              />
              <input
                v-model="recommendScoreFilter.max"
                type="number"
                placeholder="max"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              />
            </div>
          </div>
          
          <!-- Recommended Interview Score Filter -->
          <div class="mb-4 pb-5 border-b border-gray-300">
            <p class="text-sm pb-4">- {{ $t('project.detail.table.recomendedInterviewScore')  }}</p>
            <div class="flex justify-between gap-5">
              <input
                v-model="recommendedInterviewScoreFilter.min"
                type="number"
                placeholder="min"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              />
              <input
                v-model="recommendedInterviewScoreFilter.max"
                type="number"
                placeholder="max"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              />
            </div>
          </div>

          <buttonItem
            :load="load"
            buttonClass="w-full font-bold hover:bg-opacity-90 mt-3"
          />
        </form>
      </div>
    </template>
  </sideModal>
</template>

<script>
import "vue-slider-component/theme/default.css";
import { projectInterview } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";

export default {
  components: {
    buttonItem,
  },
  data() {
    return {
      isOpen: false,
      load: false,
      count: 0,
      
      // Mevcut filtreleme durumunu saklamak için yeni property
      currentFilters: null,
      
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,

      List: [],
      stateId: 0,

      // Additional score filter definitions
      recommendScoreFilter: { question: "Recommend Score", questionType: 2, min: null, max: null },
      recommendedInterviewScoreFilter: { question: "Recommended Interview Score", questionType: 2, min: null, max: null },

   projectUserState: [
        {
          id: 1,
          name: "project.detail.table.statusMessages.noAction",
          active: false,
        },
        {
          id: 2,
          name: "project.detail.table.statusMessages.preparing",
          active: false,
        },
        {
          id: 3,
          name: "project.detail.table.statusMessages.prepared",
          active: false,
        },
        {
          id: 4,
          name: "project.detail.table.statusMessages.sent",
          active: false,
        },
        {
          id: 5,
          name: "project.detail.table.statusMessages.inProgress",
          active: false,
        },
        {
          id: 6,
          name: "project.detail.table.statusMessages.decisionBlocked",
          active: false,
        },
        {
          id: 7,
          name: "project.detail.table.statusMessages.completed",
          active: false,
        },
        {
          id: 8,
          name: "project.detail.table.statusMessages.notEnough",
          active: false,
        },
        {
          id: 9,
          name: "project.detail.table.statusMessages.shortlisted",
          active: false,
        },
        {
          id: 10,
          name: "project.detail.table.statusMessages.error",
          active: false,
        },
        {
          id: 11,
          name: "project.detail.table.statusMessages.dismissed",
          active: false,
        },
        {
          id: 12,
          name: "project.detail.table.statusMessages.mailError",
          active: false,
        }
      ],
    };
  },
  methods: {
    updateExperienceRange(value, index) {
      this.List[index].min = value[0];
      this.List[index].max = value[1];  
    },
    toggleSidebar() {
      this.$refs.preInterviewFilters.show();
    },
    async getAll() {
      this.load = true;
      try {
        const response = await this.axios.get(projectInterview.getAll, {
          params: {
            page: this.currentPage,
            search: this.search,
            projectId: this.$route.query.id,
          },
        });
        const detail = response.data.data.data;
        
        this.List = await Promise.all(
          detail.map(async (item) => {
            switch (item.questionType) {
              case 1:
                return { ...item, text: "" };
              case 2:
                return { ...item, min: 0, max: 0 };
              case 3:
                return { ...item, number: 0 };
              case 4:
                return { ...item, yesNo: 0 };
              case 5:
                return { ...item, date: "2022-03-12" };
              default:
                return item;
            }
          })
        );

        this.load = false;
      } catch (error) {
        console.error(error);
        this.load = false;
        this.authController(error);
      }
    },

    async applyFilters(page = 0) {
      if (!this.currentFilters) return;
      
      this.load = true;
      this.$emit("loading", true);
      await this.axios
        .post(projectInterview.getFilter, {
          ...this.currentFilters,
          page: page
        })
        .then((res) => {
          this.load = false;
          this.$emit("filterAll", res.data.data);
          this.$emit("loading", false);
          this.$refs.preInterviewFilters.close();
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
          this.authController(err);
          this.$emit("loading", false);
        })
    },

    getFilter() {
      // Create score filters array
      const scoreFilters = [];
      
      // Add recommendScore filter if values are set
      if (this.recommendScoreFilter.min  || this.recommendScoreFilter.max ) {
        scoreFilters.push({
          key: "recommendScore",
          min: this.recommendScoreFilter.min ? this.recommendScoreFilter.min : undefined,
          max: this.recommendScoreFilter.max ? this.recommendScoreFilter.max : undefined
        });
      }
      
      // Add recommendedInterviewScore filter if values are set
      if (this.recommendedInterviewScoreFilter.min  || this.recommendedInterviewScoreFilter.max ) {
        scoreFilters.push({
          key: "recomendedInterviewScore", // Note: using the exact key as in the example
          min: this.recommendedInterviewScoreFilter.min ? this.recommendedInterviewScoreFilter.min : undefined,
          max: this.recommendedInterviewScoreFilter.max ? this.recommendedInterviewScoreFilter.max : undefined
        });
      }
      
      this.currentFilters = {
        list: JSON.stringify(this.List),
        projectId: this.$route.query.id,
        stateId: this.stateId,
        scoreFilters: scoreFilters.length > 0 ? scoreFilters : undefined
      };
      
      this.$emit("changePage", 0)
    },

    // Filtreleri temizle
    clearFilters() {
      this.List.forEach(item => {
        switch (item.questionType) {
          case 1:
            item.text = "";
            break;
          case 2:
            item.min = 0;
            item.max = 0;
            break;
          case 3:
            item.number = 0;
            break;
          case 4:
            item.yesNo = 0;
            break;
          case 5:
            item.date = "2022-03-12";
            break;
          default:
            break;
        }
      });
      
      // Reset score filters
      this.recommendScoreFilter.min = null;
      this.recommendScoreFilter.max = null;
      this.recommendedInterviewScoreFilter.min = null;
      this.recommendedInterviewScoreFilter.max = null;
      
      this.stateId = 0;
      this.$emit("changePage", 0)
      this.$refs.preInterviewFilters.close();
    }
  },
  created(){
    this.getAll();
  },
  computed: {
    isFilterActive() {
      return this.List.filter(item => {
        switch (item.questionType) {
          case 1:
            return item.text !== "";
          case 2:
            return item.min !== 0 || item.max !== 0;
          case 3:
            return item.number !== 0;
          case 4:
            return item.yesNo !== 0;
          case 5:
            return item.date !== "2022-03-12";
          default:
            return false;
        }
      }).length > 0 || 
      this.stateId !== 0 || 
      this.recommendScoreFilter.min !== null || 
      this.recommendScoreFilter.max !== null ||
      this.recommendedInterviewScoreFilter.min !== null || 
      this.recommendedInterviewScoreFilter.max !== null
    }
  }
};
</script>
