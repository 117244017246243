<template>
  <modal name="recommendedModal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '75%'"
         :scrollable="true" class="scroltype">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white z-50">
      <h1 class="">{{ $t("project.detail.recommended.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <positions ref="positionsModal" :cvId="selectedCvId" :showAddButton="false" :isEdit="false" />

    <div class="mx-3 my-3 relative">


      <customTable :items="List" :columns="columns" :loading="load" :currentPage="currentPage" :pageCount="pageCount"
                   :totalCount="totalCount" :showPagination="false" @row-click="handleRowClick" @select-all="handleSelectAll"
                   :size="'sm'"

                   height="440px">
        <template #header>
          <div class="flex justify-between w-full items-center px-2">
            <div >
            <div class="text-xs" v-if="totalCount > 0 && !load">
              <b>
                {{ totalCount }}
              </b>
              {{ $t("paginations.text") }}
            </div>
          </div>
            <div class="w-[300px] flex items-center border border-[#182451] rounded-md h-9 relative">
              <input v-model="search" @keypress.enter="getSearch()" type="text"
                     class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black"
                     :placeholder="$t('project.detail.recommended.search')" />
              <button class="px-3 textcol absolute right-0">
                <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
        </template>
        <template #checked="{ item }">

          <customCheckbox
              v-model="item.checked"
              class="mr-2"
          />

        </template>

        <template #fullname="{ item }">
          <div>
            <p>{{ item.fullname }}</p>
          </div>
        </template>

        <template #addDate="{ item }">
          <div class="">
            <p>{{ item.addDate | getDate }}</p>

          </div>
        </template>

        <template #matchRate="{ item }">
          <div class="flex gap-5 items-center justify-center">
            <div v-if="item.onlyKey" class="relative group flex justify-center">
              <img class="w-[16px]" src="@/assets/project/key.png" alt="" />
              <tooltip :title="$t('project.detail.recommended.onlyKeyRate')"
                       tipClass="z-50 w-[150px] !-left-[120px] !top-6 text-center" rotateClass="ml-[100px]" :top="true" />
            </div>
            <div class="relative group flex justify-center text-center">
              <p :class="{
                'text-red-500': item.matchPercentage >= 0 && item.matchPercentage <= 60,
                'text-yellow-500': item.matchPercentage >= 61 && item.matchPercentage <= 75,
                'text-green-500': item.matchPercentage >= 76,
              }" class="text-center text-sm">
                %{{ item.matchPercentage ? item.matchPercentage : "0" }}
              </p>
              <tooltip
                  :title="item.onlyKey ? $t('project.detail.recommended.onlyKeyRate') : $t('project.detail.recommended.ressumeRate')"
                  tipClass="z-50 w-[150px] !top-6" :top="true" />
            </div>
          </div>
        </template>
        <template #type="{ item }">
          <badge
              size="small"
              :variant="item.type == '1' ? 'success' : 'primary'"
          >
            {{ item.type == '1' ? $t('project.detail.recommended.recommended') : $t('project.detail.recommended.applied') }}
          </badge>
        </template>
        <template #relatedProjects="{ item }">
          <div class="flex justify-center h-full w-full">
            <div class="border border-gray-300 rounded-md px-2 py-1 w-full items-center justify-center flex">{{ item.otherPositionCount }}</div>
          </div>
        </template>
        <template #actions="{ item }">
          <div class="flex justify-between gap-2 items-center">

            <div class="flex justify-end items-center gap-3">
              <a @click.prevent="cvDetail(item.id)" target="_blank" class="flex justify-center h-full w-10 cursor-pointer" data-v-step="4">
                <Icon icon="pepicons-pencil:cv" class="text-xl" />
              </a>
              <tooltip :title="$t('project.detail.recommended.resumeAccess')"
                       tipClass="z-50 w-[150px] !-left-[120px] !top-6 text-center" rotateClass="ml-[100px]" :top="true" />
            </div>
            <button class="flex justify-center h-full w-10" @click="showPositionsModal(item.id)">
              <Icon icon="mdi:briefcase-outline" class="text-xl" />
              <tooltip :title="$t('project.detail.recommended.relatedProjects')"
                       tipClass="z-50 w-[150px] !-left-[120px] !top-6 text-center" rotateClass="ml-[100px]" :top="true" />
            </button>
          </div>
        </template>
      </customTable>
    </div>

    <div class="flex justify-between items-center border-t border-gray-200 pt-2 max-sm:py-1 max-sm:flex-col px-5 py-3">
      <pagination v-model="currentPage" :pageCount="pageCount" :totalRowCount="totalCount" :hiddenTotal="true"
                  paginationClass="!py-0 -ml-3 max-sm:ml-0" />

      <div class="mt-3 max-sm:w-full flex items-center space-x-2 ml-auto">
        <saveButton @click="$router.push(`/cv`)" :text="$t('globalComponents.sideBar.resumes')" buttonClass="!inline-block !w-44 max-sm:!block max-sm:!w-full !bg-transparent !text-gray-800
          !border !border-gray-800 hover:!bg-[#182451]/70 hover:!text-white" />
        <saveButton @click="projectSave()" :load="saveLoad" :text="$t('project.detail.recommended.saveButton')"
                    buttonClass="!inline-block !w-44 max-sm:!block max-sm:!w-full " />
      </div>
    </div>
  </modal>
</template>

<script>
import pagination from "@/components/pagination.vue";
import customTable from "@/components/customTable.vue";
import tooltip from "@/components/tooltip.vue";

import moment from "moment";
import "moment/locale/tr";

import saveButton from "@/components/button.vue";
import { projectConnect } from "@/networking/urlmanager";
import positions from "@/views/cv/profile/components/positions.vue";
import customCheckbox from "@/components/customCheckbox.vue";
import badge from "@/components/badge.vue";

export default {
  name: "log-component",
  props: ["cvId", "projectId", "projectName"],
  components: {
    pagination,
    saveButton,
    tooltip,
    customTable,
    positions,
    customCheckbox,
    badge
  },
  data() {
    return {
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      saveLoad: false,
      List: [],
      load: false,

      selectedCvId: null
    };
  },
  computed: {
    checkedList() {
      return this.List.filter(item => item.checked);
    },
    columns() {
      return [
        {
          key: 'checked',
          label: '',
          width: '50px',
          selectAll:true
        },
        {
          key: 'fullname',
          label: this.$t('project.detail.recommended.ressume'),
          width: '200px',
          clickable: true
        },

        {
          key: 'position',
          label: this.$t('project.detail.recommended.position'),
          width: '200px',
        },

        {
          key: 'matchRate',
          width: '60px',
          align: 'center',
          label: this.$t('project.detail.recommended.matchRate')
        },
        {
          key: 'type',
          label: this.$t('project.detail.recommended.type'),
          width: '180px',
          align: 'center'
        },
        {
          key: 'addDate',
          label: this.$t('project.detail.recommended.addDate'),
          width: '150px'
        },

        {
          key: 'relatedProjects',
          label: this.$t('project.detail.recommended.relatedProjects'),
          width: '130px',
          clickable: true
        }

      ];
    }
  },
  methods: {
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    cvDetail(val) {
      // Yeni sekmede açma
      window.open(`/cv/profile/${val}`, '_blank');
    },

    handleRowClick({item, key}) {
      if (key == 'fullname') {
        // Yeni sekmede açılması için
        this.cvDetail(item.id);
      } else if (key == 'relatedProjects') {
        this.showPositionsModal(item.id);
      }
    },
    handleSelectAll(checked) {
      if (checked) {
        this.List.forEach(item => {
          item.checked = true;

        });
      } else {
        this.List.forEach(item => {
          item.checked = false;

        });
      }
    },

    getAll() {
      this.List = [];
      this.load = true;
      this.axios.get(projectConnect.getResumeRecommendations, {
        params: {
          projectId: this.$route.query.id,
          page: this.currentPage,
          search: this.search,
        },
      })
          .then((res) => {
            const { detail, totalCount, pageCount } = res.data.data;
            this.List = detail.map(item => ({
              ...item,
              checked: false
            }));
            this.totalCount = totalCount;
            this.pageCount = pageCount;
            this.load = false;
          })
          .catch((err) => {
            this.authController(err);
          })
          .finally(() => {
            this.load = false;
          });
    },
    projectSave() {
      if (this.checkedList.length > 0) {
        this.saveLoad = true;
        if (this.checkedList.length > 0) {
          this.axios.post(projectConnect.addResume, {
            projectId: this.$route.query.id,
            list: JSON.stringify(this.checkedList),
            projectName: this.$route.query.projectName,
          })
              .then((res) => {
                this.successMessage(res.data.message);

                this.saveLoad = false;
                this.List = [];
                this.List.forEach(item => {
                  item.checked = false;
                });
                this.$emit("refresh", true);
                this.hide();
              })
              .catch((err) => {
                console.log(err);
                this.saveLoad = false;
              });
        }
      }else{
        this.warningMesage(this.$t("project.detail.recommended.selectionAlert"))
      }
    },
    show() {
      this.load = true;
      this.search = "";
      this.List = [];
      if (this.currentPage != 0) {
        this.currentPage = 0;
      } else {
        this.getAll();
      }

      this.$modal.show("recommendedModal");
    },
    hide() {

      this.$modal.hide("recommendedModal");
    },
    async showPositionsModal(cvId) {
      this.selectedCvId = await `${cvId}`
      await this.$refs.positionsModal.showPositions();
    }
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>