<template>
    <div class="flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap" v-if="!getLoad">
        <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
                {{ $t("settings.configration.index.emailTitle") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
                {{ $t("settings.configration.index.emailText") }}
            </p>
        </div>
        <div class="w-6/12 h-full grid grid-cols-1 gap-4 max-md:w-full">
            <Inputs
                :title="$t('settings.configration.index.sunucuAdress')"
                v-model="form.mailServer"
                :required="false"
                type="text"
                min="2"
                max="100"
            />
            <Inputs
                :title="$t('settings.configration.index.username')"
                v-model="form.mailName"
                :required="false"
                type="text"
                min="2"
                max="100"
            />
            <Inputs
                :title="$t('settings.configration.index.password')"
                v-model="form.mailPassword"
                :required="false"
                type="text"
                min="2"
                max="100"
            />
            <Inputs
                :title="$t('settings.configration.index.port')"
                v-model="form.mailPort"
                :required="false"
                type="select"
                :selectList="[
                    { value: '587', name: '587' },
                    { value: '465', name: '465' },
                    { value: '25', name: '25' }
                ]"
            />
            <div class="flex justify-end">
                <asyncButton 
                    :text="$t('settings.general.index.saveButton')" 
                    @click="save" 
                    :load="load" 
                    :loadText="$t('settings.general.index.saveButton')" 
                />
            </div>
        </div>
    </div>
    <div v-else class="flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap min-h-[440px] items-center justify-center">
        <customLoading />
    </div>
</template>

<script>
import Inputs from "@/components/customInput.vue";
import asyncButton from "@/components/button.vue";
import { settingConfigration } from "@/networking/urlmanager";
import customLoading from "@/components/customLoading.vue";

export default {
    name: "EmailSettings",
    components: {
        Inputs,
        asyncButton,
        customLoading,
    },
    data() {
        return {
            form: {
                mailServer: "",
                mailName: "",
                mailPassword: "",
                mailPort: "",
            },
            load: false,
            getLoad: false,
            initialValues: [],
        };
    },
    methods: {
        getAll() {
            this.getLoad = true;
            this.axios
                .get(settingConfigration.getMail, {
                    headers: {
                        Authorization: "Bareer " + this.$store.state.userData.token,
                    },
                })
                .then((res) => {
                    if (res.data.data.length > 0) {
                        const { mailServer, mailName, mailPassword, mailPort } = res.data.data[0];
                        this.form.mailServer = mailServer;
                        this.form.mailName = mailName;
                        this.form.mailPassword = mailPassword;
                        this.form.mailPort = mailPort;
                    }
                    this.getLoad = false;
                })
                .catch((err) => {
                    this.getLoad = false;
                    console.log(err);
                    this.authController(err);
                })
                .finally(() => {
                    this.initialValues = _.cloneDeep(this.form);
                });
        },
        save() {
            this.load = true;
            this.axios
                .post(
                    settingConfigration.edit,
                    {
                        mailServer: this.form.mailServer,
                        mailName: this.form.mailName,
                        mailPassword: this.form.mailPassword,
                        mailPort: this.form.mailPort,
                    },
                    {
                        headers: {
                            Authorization: "Bareer " + this.$store.state.userData.token,
                        },
                    }
                )
                .then((res) => {
                    this.successMessage(res.data.message);
                    this.getAll();
                    this.load = false;
                })
                .catch((err) => {
                    this.warningMesage(this.$t("settings.configration.index.warningMesage"));
                    this.load = false;
                });
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.getLoad && !_.isEqual(this.form, this.initialValues)) {
            this.verifiedMessage(
                this.$t("settings.general.index.leaveMessage"),
                this.$t("settings.general.index.leaveMessageYes"),
                this.$t("settings.general.index.leaveMessageNo")
            ).then((result) => {
                if (result.isConfirmed) {
                    next(true);
                } else {
                    next(false);
                }
            });
        } else {
            next(true);
        }
    },
    created() {
        this.getAll();
    },
};
</script> 