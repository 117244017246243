<template>
    <div class="flex justify-between items-center rounded-lg h-full p-4 shadow-lg shadow-slate-500/25" >
            <Icon :icon="icon" class="text-[80px] text-slate-500" /> 
        <div class="text-right pr-4">
            <h2 class=" font-medium text-slate-500 text-sm">{{title}}</h2>
            <h1 class="text-slate-500 text-3xl font-semibold">
                <p v-if="!load">{{count ? count : 0 }}</p>
                <p v-if="load && !count" class="text-sm text-right mt-2"> <i class="fas fa-spinner fa-spin mr-2"></i>{{ $t("general.components.card.loadTitle") }}</p>
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    name:"card-costum", 
    props:["icon","title","count","classcard","load"],
    data() {
        return {
            
        }
    },
}
</script>