<template>
  <div class="w-full">
    <div
        v-if="!getLoad"
    >
      <div v-if="providers.length === 0" class="py-8 text-center text-gray-500">
        {{ $t('settings.assessment.index.noProviders') }}
      </div>

      <div v-for="provider in providers" :key="provider.providerId" class="border-b border-gray-200">
        <div class="flex gap-5 py-8 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">{{ provider.name }}</h1>
            <p class="text-xs text-gray-700 mt-2">{{ $t('settings.assessment.index.apiKeyDescription') }}</p>
          </div>
          <div class="w-6/12 max-md:w-full flex items-center gap-3">
            <input
                class="flex-1 border bordercol rounded py-3 outline-none pl-3 text-xs"
                type="text"
                v-model="provider.apiKey"
                :placeholder="$t('settings.assessment.index.apiKeyPlaceholder')"
            />
            <!-- <button
                type="button"
                class="textcol border bordercol px-4 py-3 rounded text-xs"
                @click="saveAssessmentProvider(provider)"
                :disabled="loadingProviders[provider.providerId]"
            
                >
              <span v-if="!loadingProviders[provider.providerId]">{{ $t('settings.assessment.index.save') }}</span>
              <span v-else class="flex items-center">
                <span class="w-4 h-4 rounded-full animate-spin inline-block border-2 border-solid border-white border-t-transparent shadow-md mr-1"></span>
                {{ $t('settings.assessment.index.saving') }}
              </span>
            </button> -->
            <asyncButton :text="$t('settings.assessment.index.save')" @click="saveAssessmentProvider(provider)" :load="loadingProviders[provider.providerId]" :loadText="$t('settings.assessment.index.saving')" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col items-center justify-center py-8 h-64">
       <customLoading />
      </div>
    </div>
  </div>
</template>

<script>
import { settingConfigration } from "@/networking/urlmanager";
import customLoading from "@/components/customLoading.vue";
import asyncButton from "@/components/button.vue";
export default {
  name: "assessmentSettings",
  components: {
    customLoading,
    asyncButton,
  },
  data() {
    return {
      providers: [],
      getLoad: false,
      loadingProviders: {}, // Track loading state per provider
    };
  },
  created() {
    this.getAssessmentProviders();
  },
  methods: {
    async getAssessmentProviders() {
      this.getLoad = true;
      try {
        const response = await this.axios.get(settingConfigration.getAssessmentProviders);
        if (response.data && response.data.data) {
          this.providers = response.data.data;

          // Initialize loading states
          this.providers.forEach(provider => {
            this.$set(this.loadingProviders, provider.providerId, false);
          });
        }
      } catch (error) {
        console.error("Error fetching assessment providers:", error);
      } finally {
        this.getLoad = false;
      }
    },
    async saveAssessmentProvider(provider) {
      // Set loading state for this specific provider
      this.$set(this.loadingProviders, provider.providerId, true);

      try {
        await this.axios.post(settingConfigration.setAssessmentProvider, {
          providerId: provider.providerId,
          apiKey: provider.apiKey,
        });
        this.successMessage(this.$t('settings.assessment.index.saveSuccess'));
      } catch (error) {
        console.error(`Error saving assessment provider ${provider.name}:`, error);
        this.authController(error);
      } finally {
        // Reset loading state for this specific provider
        this.$set(this.loadingProviders, provider.providerId, false);
      }
    },
  },
};
</script>