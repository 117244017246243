<template>
  <div class="px-10 py-5">
    <div class="p-5 w-full relative flex items-center justify-center">
      <h2 class="text-center text-slate-800 font-bold">
        {{
          $t(
            "project.projectUser.components.shortList.components.selectDateModal.modalBodyTitle"
          )
        }}
      </h2>
      <div class="absolute right-0 top-1/2 transform -translate-y-1/2 group flex justify-center"
        v-if="candidateSuggestions.length > 0" @click="goToFirstSuggestedDate">
        <i class="fa-solid fa-circle-exclamation text-sm text-[#182451]"></i>
        <tooltip :title="formatCandidateSuggestions()" tipClass="z-50 !top-6 !w-[130px]" :top="true" />
      </div>
    </div>

    <div class="flex flex-col gap-2 text-sm mb-4 bg-gray-200 p-1 px-2 rounded-sm">
          <div class="flex items-center gap-2">
            <div
              class="bg-green-100 text-green-500 ring-2 w-4 h-4 rounded-sm ring-green-800/40 flex justify-center items-center">
              <i class=" fa fa-clock"> </i>
            </div>
            {{
              $t(
                "project.projectUser.components.shortList.components.selectDateModal.candidateSuggestionDates"
              )
            }}
          </div>
          <div class="flex items-center gap-2">
            <div
              class="bg-red-100 text-red-500 ring-2 w-4 h-4 rounded-sm ring-red-800/40 flex justify-center items-center">
              <i class=" fa fa-clock"> </i>

            </div>
            {{
              $t(
                "project.projectUser.components.shortList.components.selectDateModal.candidateRejectedDates"
              )
            }}
          </div>
          <div class="flex items-center gap-2">
            <div
              class="bg-orange-100 text-orange-300 ring-2 w-4 h-4 rounded-sm ring-red-800/40 flex justify-center items-center">
              <i class=" fa fa-clock"> </i>

            </div>
            {{
              $t(
                "project.projectUser.components.shortList.components.selectDateModal.alreadyHaveMeetingHours"
              )
            }}
          </div>
  </div>
    <div class="mx-auto w-full">
      <div v-if="!isLoading">
        <div class="flex justify-between space-x-3 mb-5">
          <!-- Ay seçimi -->
          <button type="button" @click="goToNextOrPreviousMonth(false)">
            <i class="fas fa-chevron-left"></i>
          </button>
          <div class="flex space-x-2 items-center">

            <select v-model="selectedMonth">
              <option v-for="(month, index) in months" :key="index" :value="index">
                {{ month }}
              </option>
            </select>

            <select v-model="selectedYear">
              <option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
          <!-- Yıl seçimi -->

          <button type="button" @click="goToNextOrPreviousMonth(true)">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
        <div>
          <!-- Haftanın günleri -->
          <div class="grid grid-cols-7 text-center">
            <div class="py-2 font-bold" v-for="day in $t(
              'project.projectUser.components.shortList.components.selectDateModal.daysInWeek'
            )" :key="day">
              {{ day }}
            </div>
          </div>

          <!-- Ayın günleri -->
          <div class="grid grid-cols-2 sm:grid-cols-7 gap-2 mt-2">
            <button class="py-2 rounded-lg shadow-md" v-for="(day, index) in daysInMonth" :key="index" type="button"
               @click="selectDay(day)" :class="{
                'hover:bg-slate-500 hover:text-white text-slate-800 font-semibold':
                  !isDisabled(day) && day.isSelectedMonthDay,
                'bg-slate-500 text-white': isSelected(day),
                'opacity-30': isDisabled(day) || !day.isSelectedMonthDay,
                'text-red-600': !day.isSelectedMonthDay,
                'ring-2 ring-green-800/40 bg-green-100 text-green-500':
                  isCompanyFalse(day) && !isSelected(day),
                  'bg-red-100 text-red-500 ring-2 ring-red-800/40':isInHistoryCandidate(day),
              }">
              {{ day.day }} 
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="isLoading"
        class="top-0 left-0 w-full min-h-[300px] flex items-center justify-center bg-opacity-50 my-5">
        <i class="fas fa-spinner fa-spin text-slate-500 text-[60px] sm:text-[65px] animate-spin"></i>
      </div>

      <div class="w-full border border-gray-400 rounded my-5">
        <ul>
          <li v-if="selectedDates.length === 0" class="text-center p-1">
            {{
              $t(
                "project.projectUser.components.shortList.components.selectDateModal.datePickerTitle"
              )
            }}
          </li>
          <li v-else class="grid grid-cols-3 p-1 border-b border-b-gray-400">
            <strong>
              {{
                $t(
                  "project.projectUser.components.shortList.components.selectDateModal.date"
                )
              }}</strong>
            <strong class="col-span-2">
              {{
                $t(
                  "project.projectUser.components.shortList.components.selectDateModal.time"
                )
              }}</strong>
          </li>
          <li v-for="(item, index) in selectedDates" :key="index"
            class="grid grid-cols-3 p-1 border-b border-gray-400 items-center">
            <span>{{ formatDate(item.date) }}</span>
            <div class="flex overflow-x-auto scroll-design col-span-2">
              <button v-for="(timeSlot, timeIndex) in getTimesForSelectedDate(item.date)" :key="timeIndex"
              
                class="text-black border px-1 py-1 m-1 rounded-lg shadow-lg whitespace-nowrap"
                @click="selectTime(item.date, timeSlot.time)" :class="{
                  '!bg-slate-500 text-white': item.times.includes(timeSlot.time),
                  'hover:bg-slate-500/90 hover:text-white': !item.times.includes(timeSlot.time),
                  'ring-2 ring-green-800/40 bg-green-100 text-green-500':
                    isCompanyTimeSlot(item.date, timeSlot.time),
                  '!ring-2 !ring-red-800/40 bg-red-100 text-red-500': isCandidateTimeSlot(item.date,timeSlot.time),
                  'bg-orange-300': !timeSlot.isAvailable

                }">
                {{ timeSlot.time }} 
              </button>
            </div>
          </li>
        </ul>
      </div>

      <div class="flex justify-center my-5 w-full">
        <asyncButton text="Gönder" class="w-full" @click="saveDate" type="submit" :load="buttonLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import asyncButton from "@/components/button.vue";
import tooltip from "@/components/tooltip.vue";
import {
  technicalInterviewMicrosoft,
  technicalInterviewGoogle,
  technicalInterviewGeneral,
} from "@/networking/urlmanager";

export default {
  name: "select-date-modal",
  components: { asyncButton, tooltip },
  props: ["detail"],
  data() {
    return {
      selectedMonth: moment().month(),
      selectedYear: moment().year(),
      selectedDates: [],
      availableDates: [],
      months: moment.months(),
      years: Array.from({ length: 10 }, (_, i) => moment().year() + i),
      isLoading: false,
      buttonLoading: false,
      timeSlots: this.generateTimeSlots(),
      candidateSuggestions: [],
      isModalVisible: false,
      defaultService: this.$store.state.integration?.defaultService,
      fetchTimer: null,
      interviewHistory: [],
      interviewId: 0,
    };
  },
  computed: {
    computedDisabledDates() {
      const startOfMonthDate = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).startOf("month");
      const endOfMonthDate = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).endOf("month");

      const days = [];
      for (
        let m = moment(startOfMonthDate);
        m.isBefore(endOfMonthDate);
        m.add(1, "days")
      ) {
        days.push(m.toDate());
      }
      return days
        .filter((date) =>
          this.availableDates.every(
            (interval) =>
              !moment(date).isBetween(
                moment(interval.start),
                moment(interval.end),
                null,
                "[]"
              )
          )
        )
        .map((date) => ({
          start: date,
          end: date,
        }));
    },
    daysInMonth() {
      const daysInThisMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).daysInMonth();
      const firstDayOfMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: 1,
      }).day();
      const daysInPreviousMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      })
        .subtract(1, "month")
        .daysInMonth();
      const days = [];
      for (let i = 0; i < firstDayOfMonth - 1; i++) {
        days.push({
          day: daysInPreviousMonth - i,
          isSelectedMonthDay: false,
          id: `${this.selectedYear}-${this.selectedMonth - 1}-${daysInPreviousMonth - i
            }`,
        });
      }
      days.reverse();
      for (let i = 0; i < daysInThisMonth; i++) {
        days.push({
          day: i + 1,
          isSelectedMonthDay: true,
          id: `${this.selectedYear}-${this.selectedMonth}-${i + 1}`,
        });
      }
      return days;
    },
  },
  methods: {
    isCompanyTimeSlot(date, time) {
      // Tarih için isCompanyFalse kontrolü
      const isCompanyDate = this.interviewHistory.some((item) =>
        item.dates.some(
          (dateObj) =>
            moment(dateObj.date).format("YYYY-MM-DD") === date &&
            !item.isCompany &&
            moment(dateObj.date).format("HH:mm") === time.split(" / ")[0]
        )
      );

      return isCompanyDate;
    },
    isCandidateTimeSlot(date, time) {
      const isCompanyDate = this.interviewHistory.some((item) =>
        item.dates.some(
          (dateObj) =>
            moment(dateObj.date).format("YYYY-MM-DD") === date &&
            item.isCompany &&
            moment(dateObj.date).format("HH:mm") === time.split(" / ")[0]
        )
      );

      return isCompanyDate;
    },
    async show() {
      this.$modal.show("interview-diversity");
      this.isModalVisible = true;
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
      await this.initializeHistoryCalendar();
    },
    async initializeHistoryCalendar() {
      await this.authorizeMixin(() => {this.fetchCalendarDates()},() => {this.handleClose()} );
    },
    handleClose() {
      this.cleanup();
      this.selectedDates = [];
      this.candidateSuggestions = [];
      this.$emit("close");
    },


    cleanup() {
      this.selectedDates = [];
      this.candidateSuggestions = [];
      this.isModalVisible = false;
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
      if (this.fetchTimer) {
        clearTimeout(this.fetchTimer);
      }
    },
    async getCredentials() {
      const vuexState = await JSON.parse(localStorage.getItem("vuex"));
      const integration = vuexState.integration || {};

      return {
        microsoft: {
          token: integration.microsoft?.token,
          date: integration.microsoft?.date,
          isValid: (token, date) =>
            token && date && moment(date).diff(moment(), "minutes") > 0,
        },
        google: {
          token: integration.google?.token,
          date: integration.google?.date,
          refreshToken: integration.google?.refreshToken,
          isValid: (token, date) =>
            token && date && moment(date).diff(moment(), "minutes") > 0,
        },
      };
    },
    formatCandidateSuggestions() {
      if (this.candidateSuggestions.length === 0) {
        return "No candidate suggestions";
      }

      return (
        '<p class="mb-2">Adayın tarihleri:</p>' +
        this.candidateSuggestions
          .map((suggestion) => {
            const date = moment(suggestion.date).format("LL");
            const times = suggestion.times.join(", ");
            return `<p class="mb-2">${date}: ${times}</p>`;
          })
          .join("")
      );
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
    generateTimeSlots() {
      const timeSlots = [];
      let currentTime = moment('08:00', 'HH:mm');
      const endTime = moment('18:00', 'HH:mm');

      while (currentTime.isBefore(endTime)) {
        const slotStart = currentTime.format('HH:mm');
        currentTime.add(1, 'hour');
        const slotEnd = currentTime.format('HH:mm');
        timeSlots.push(`${slotStart} / ${slotEnd}`);
      }

      return timeSlots;
    },
    selectTime(date, time) {
      let selectedDate = this.selectedDates.find((item) => item.date === date);
      if (!selectedDate) {
        if (this.selectedDates.length < 3) {
          selectedDate = { date: date, times: [] };
          this.selectedDates.push(selectedDate);
        } else {
          this.warningMesage("En fazla 3 tarih seçebilirsiniz.");
          return;
        }
      }

      const index = selectedDate.times.findIndex(
        (selectedTime) => selectedTime === time
      );

      if (index > -1) {
        selectedDate.times.splice(index, 1);
      } else {
        const totalSelectedTimes = this.selectedDates.reduce(
          (total, date) => total + date.times.length,
          0
        );

        if (totalSelectedTimes < 3) {
          selectedDate.times.push(time);
        } else {
          this.warningMesage("Toplamda en fazla 3 saat seçebilirsiniz.");
          return;
        }
      }

      const totalSelectedTimes = this.selectedDates.reduce(
        (total, date) => total + date.times.length,
        0
      );
      if (totalSelectedTimes === 3) {
        this.selectedDates = this.selectedDates.filter(
          (date) => date.times.length > 0
        );
      }
    },

    formatSelectedDates() {
      return this.selectedDates
        .map((selectedDate) => {
          const baseDate = moment.tz(selectedDate.date, "Europe/Istanbul");

          const formattedTimes = selectedDate.times.map((time) => {
            const [hours, minutes] = time.split(" / ")[0].split(":");
            return baseDate
              .clone()
              .hours(parseInt(hours))
              .minutes(parseInt(minutes))
              .utc()  // Convert to UTC from UTC+3
              .format("YYYY-MM-DDTHH:mm:ss[Z]");
          });

          return formattedTimes;
        })
        .flat();
    },

    saveDate() {


      this.buttonLoading = true;

      const formattedDates = this.formatSelectedDates();

      if (formattedDates.length === 0) {
        this.buttonLoading = false;
        this.warningMesage(
          this.$t(
            "project.projectUser.components.shortList.components.selectDateModal.saveDateWarningMessage"
          )
        );
        return;
      }
      const email = this.detail.contactEmail.match(/[\w.-]+@[\w.-]+\.\w+/)[0];
 
      const attendeeMailsAll= [email,this.$store.state.userData.email];


      const requestBody = {
        datesHistory: this.interviewHistory,
        interviewId: this.interviewId,
        interviewDates: formattedDates,
        selectedDates: formattedDates,
        applicantMail: email,
        attendeeMails: attendeeMailsAll,

        projectId: this.detail.projectId,
        projectName: this.$route.query.projectName,
        cvId: this.detail.cvId,
        fullname: this.detail.fullname,
        meetCount: this.detail.meetCount,
      };

      this.axios.post(technicalInterviewGeneral.interviewSendAgain, requestBody)
        .then(() => {
          this.buttonLoading = false;
          this.$emit("refresh", true);
          this.$emit("close");
        })
        .catch((error) => {
          console.error(error);
          this.buttonLoading = false;
          this.authController(error);
        });
    },

    isDisabled(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");
      // const isAvailable = this.availableDates.some((availableDate) => availableDate.date === formattedDate);
      const isSelected = this.selectedDates.some((selectedDate) => selectedDate.date === formattedDate);
      const isInSelectedMonth = day.isSelectedMonthDay;

      const totalSelectedTimes = this.selectedDates.reduce((total, date) => total + date.times.length, 0);
      const filteredHistory = this.interviewHistory.filter((item) => item.isCompany);

      const dateFormat = filteredHistory.flatMap((item) => item.dates.map((dateObj) => moment(dateObj.date).format("YYYY-MM-DD")));
      // const isInHistory = dateFormat.some((historyDate) => historyDate === formattedDate);
      const isPastDate = moment(formattedDate).isBefore(moment(), 'day');
      const isTwoWeekLater = moment(formattedDate).isAfter(moment().add(2, 'week'), 'day');
      const isDisabledBool =  !isInSelectedMonth || (totalSelectedTimes >= 3 && !isSelected) || isPastDate || isTwoWeekLater;
  

   
      return isDisabledBool
    },
    
    isTimeDisabled(date, time) {
      const [startTime] = time.split(" / ");
      const dateTime = moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm");
      return dateTime.isBefore(moment());
    },


    isInHistoryCandidate(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      }).format("YYYY-MM-DD");

      const dateFormat = this.interviewHistory.flatMap((item) =>
        item.dates.map((dateObj) =>
          moment(dateObj.date).format("YYYY-MM-DD")
        )
      );
      const isInHistory = dateFormat.some((historyDate) => historyDate === date);

      const isCompanyFalse = this.interviewHistory.some((item) =>
        item.dates.some(
          (dateObj) =>
            moment(dateObj.date).format("YYYY-MM-DD") === date &&
            !item.isCompany
        )
      );
      return isInHistory && !isCompanyFalse;
    },

    isInHistoryCandidateTime(time){
      const date = moment(new Date(time.date)).format("YYYY-MM-DD");


    },

    isSuggested(day) {
      if (!day.isSelectedMonthDay) {
        return false;
      }

      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");
      return this.candidateSuggestions.some(
        (suggestedDate) =>
          moment(suggestedDate.date).format("YYYY-MM-DD") === formattedDate
      );
    },
    async fetchCalendarDates() {
      const vuexState = JSON.parse(localStorage.getItem("vuex"));
      if (!vuexState.integration.google.token) {
        this.fetchTimer = setTimeout(() => this.fetchCalendarDates(), 100);
        return;
      }
      if (!this.detail.cvId) {
        this.fetchTimer = setTimeout(() => this.fetchCalendarDates(), 1000);
        return;
      }
      try {
        const vuexState = JSON.parse(localStorage.getItem("vuex"));
        this.isLoading = true;
        const credentials = await this.getCredentials();
        const service = this.defaultService || 1;

        const fetchUrls = {
          1: `${technicalInterviewMicrosoft.getCalendarAll}?token=${credentials.microsoft.token}`,
          2: `${technicalInterviewGoogle.getCalendarAll}?access_token=${credentials.google.token}&refresh_token=${vuexState.integration?.google.refreshToken}`,
        };

        const url = fetchUrls[service];
        if (!url) {
          throw new Error("Invalid service configuration");
        }

        const response = await this.axios.get(url, {
          params: {
            cvId: this.detail.cvId,
            meetCount: this.detail.meetCount,
          },
        });

        this.processCalendarData(response.data.data);
      } catch (error) {
        console.error("Calendar fetch error:", error);
        this.warningMesage(
          this.$t(
            "project.projectUser.components.shortList.components.selectDateModal.datesNotRecieved"
          ),
          3000
        );
      await this.authorizeMixin();

      } finally {
        this.isLoading = false;
      }
    },
    // preprocessDate(dateStr) {
    //   if (!dateStr) {
    //     return;
    //   }
    //   const [date, time] = dateStr.split(/(?<=^\d{4}-\d{2}-\d{2})/);
    //   return `${date}${time}`;
    // },
    processCalendarData(data) {
      this.availableDates = [];
      this.candidateSuggestions = [];

      // Process candidate suggestions
      data.dates?.forEach((timeSlot) => {
        const dateTime = moment(timeSlot);
        const date = dateTime.format("YYYY-MM-DD");
        const time = dateTime.format("HH:mm");
        const timeSlotStr = `${time} / ${dateTime.clone().add(1, 'hour').format("HH:mm")} ${dateTime.format("Z")}`;

        // Find or create the existing date
        const existingDate = this.availableDates.find(d => d.date === date);
        if (existingDate) {
          if (!existingDate.times.includes(timeSlotStr)) {
            existingDate.times.push(timeSlotStr);
          }
        } else {
          this.availableDates.push({
            date,
            times: [timeSlotStr]
          });
        }

        // For candidate suggestions
        this.candidateSuggestions.push({
          date,
          times: [timeSlotStr]
        });
        
      });

      // Process available time slots
      data.timeSlots?.forEach((timeSlot) => {
        const dateTime = moment(timeSlot);
        const date = dateTime.format("YYYY-MM-DD");
        const time = dateTime.format("HH:mm");
        const timeSlotStr = `${time} / ${dateTime.clone().add(1, 'hour').format("HH:mm")}`;

        const existingDate = this.availableDates.find(d => d.date === date);


        const now = moment();
      const isToday = moment(date).isSame(now, 'day');

      if (isToday) {
        const timeDate = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm');
            if (timeDate.isBefore(now)) {
              return null;
            }
          }


        if (existingDate) {
          if (!existingDate.times.includes(timeSlotStr)) {
            existingDate.times.push(timeSlotStr);
          }
        } else {
          this.availableDates.push({
            date,
            times: [timeSlotStr]
          });
        }
      });

      // Sort the dates
      this.availableDates.sort((a, b) => moment(a.date).diff(moment(b.date)));

    },
    handleVisibilityChange() {
      if (
        document.visibilityState === "visible" &&
        !document.hasFocus() &&
        this.isModalVisible &&
        this.availableDates.length === 0
      ) {
        this.initializeHistoryCalendar();
      }
    },

    selectDay(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });

      if (!this.isDisabled(day)) {
        const formattedDate = date.format("YYYY-MM-DD");
        const index = this.selectedDates.findIndex(
          (selectedDate) =>
            moment(selectedDate.date).format("YYYY-MM-DD") === formattedDate
        );

        if (index > -1) {
          this.selectedDates.splice(index, 1);
        } else {
          const totalSelectedTimes = this.selectedDates.reduce(
            (total, date) => total + date.times.length,
            0
          );

          if (totalSelectedTimes < 3) {
            const availableDate = this.availableDates.find(
              (availableDate) => availableDate.date === formattedDate
            );

            if (availableDate) {
              const isCompanyFalse = this.interviewHistory.some((item) =>
                item.dates.some(
                  (dateObj) =>
                    moment(dateObj.date).format("YYYY-MM-DD") ===
                    formattedDate && !item.isCompany
                )
              );

              this.selectedDates.push({
                date: availableDate.date,
                times: [],
                isCompany: isCompanyFalse, // `isCompany: false` kontrolü ekleniyor
              });
            }
          } else {
            this.warningMesage(
              this.$t(
                "project.projectUser.components.shortList.components.selectDateModal.selectDayWarningMessage"
              ),
              2000
            );
            return;
          }
        }
      }
    },

    isCompanyFalse(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      }).format("YYYY-MM-DD");
      // ----------------------------------------------------------------
      return this.interviewHistory.some((item) =>
        item.dates.some(
          (dateObj) =>
            moment(dateObj.date).format("YYYY-MM-DD") === date &&
            !item.isCompany
        )
      );
    },
    goToAvailableMonth(afterCurrent = false) {
      if (this.availableDates.length > 0) {
        if (afterCurrent) {
          const currentDate = new Date(
            this.selectedYear,
            this.selectedMonth + 1,
            1
          );
          for (let i = 0; i < this.availableDates.length; i++) {
            const availableDate = moment(this.availableDates[i].date);
            if (availableDate >= currentDate) {
              this.selectedMonth = availableDate.getMonth();
              this.selectedYear = availableDate.getFullYear();
              break;
            }
          }
        } else {
          const firstAvailableDate = moment(this.availableDates[0].date);
          this.selectedMonth = firstAvailableDate.month();
          this.selectedYear = firstAvailableDate.year();
        }
      }
    },
    goToFirstSuggestedDate() {
      if (this.candidateSuggestions.length > 0) {
        const firstSuggestedDate = moment(this.candidateSuggestions[0].date);
        this.selectedMonth = firstSuggestedDate.month();
        this.selectedYear = firstSuggestedDate.year();
      }
    },
    goToNextOrPreviousMonth(next = false) {
      const currentDate = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      });
      let newDate;

      if (next) {
        newDate = moment(currentDate).add(1, "month");
      } else {
        newDate = moment(currentDate).subtract(1, "month");
      }

      this.selectedMonth = newDate.month();
      this.selectedYear = newDate.year();
    },
    getTimesForSelectedDate(selectedDate) {
      // Get available times for this date
      const availableTimes = this.availableDates
        .filter((dateObj) => dateObj.date === selectedDate)
        .map((dateObj) => dateObj.times)
        .flat();

      const selectedMoment = moment(selectedDate);
      const now = moment();
      const isToday = selectedMoment.isSame(now, 'day');

      // Generate all time slots between 08:00 and 19:00
      const startTime = "08:00";
      const endTime = "19:00";
      const timeSlots = [];
      
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");

      while (start < end) {
        const currentTime = start.format("HH:mm");
        const nextHour = start.clone().add(1, 'hour').format("HH:mm");
        const timeSlot = `${currentTime} / ${nextHour}`;

        // Skip if it's today and this time has already passed
        if (isToday && now.isAfter(moment(selectedDate + ' ' + currentTime))) {
          start.add(1, 'hour');
          continue;
        }

        timeSlots.push({
          time: timeSlot,
          isAvailable: availableTimes.includes(timeSlot)
        });
        start.add(1, 'hour');
      }

      return timeSlots;
    },
    preprocessDate(dateStr) {
      if (!dateStr) {
        return;
      }
      const [date, time] = dateStr.split(/(?<=^\d{4}-\d{2}-\d{2})/);
      return `${date}${time}`;
    },

    isSelected(day) {
      if (!day.isSelectedMonthDay) {
        return false;
      }

      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");
      return (
        this.selectedDates.some(
          (selectedDate) => selectedDate.date === formattedDate
        ) && !this.isDisabled(day)
      );
    },
  },
  beforeDestroy() {
    this.cleanup();
  },
  mounted() {
    this.goToAvailableMonth();
  },
};
</script>

<style>
.scroll-design::-webkit-scrollbar {
  height: 5px;
}

.scroll-design::-webkit-scrollbar-thumb {
  background: #182451;
  border-radius: 10px;
}

.scroll-design::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-design-y::-webkit-scrollbar {
  width: 6px;
}

.scroll-design-y::-webkit-scrollbar-thumb {
  background: #182451;
  border-radius: 10px;
}

.scroll-design-y::-webkit-scrollbar-track {
  background: transparent;
}
</style>
