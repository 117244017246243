import axios from "axios";
import store from "../store";
import router from "../router";
import i18n from "../plugins/i18n";

const instance = axios.create();
instance.interceptors.request.use((config) => {
        const token = store.state.userData.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        const lang = store.state.userData.locale;
        if (lang) {
            config.headers['accept-language'] = lang;
        }

        config.headers['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use((response) => response, async (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                store.commit("signOut");
                await router.push("/");
            }
            return Promise.reject(error);
        } else if (error.request) {
            return Promise.reject(i18n.t("error.index.network"));
        } else {
            return Promise.reject(i18n.t("error.index.unknown", error.message));
        }
    }
);

export default instance;