<template>
  <div class="w-full">
    <div class="w-full">
      <customInput
        v-model="selectedItem"
        type="range"
        :min="0"
        :max="30"
        :required="true"
        :title="$t('project.components.experienceRange.title')"
        :disabled="disabled"
        :inputClass="highlight ? ' !bg-red-500/25 ' : ''"
      />

      <div class="w-full flex justify-between text-sm pt-3">
        <span class="pl-1">{{
          $t("project.components.experienceRange.lessThanOne")
        }}</span>

        <span class="flex gap-4">
          <input
            v-model="selectedItem[0]"
            type="number"
            class="border-t-0 w-11 border-b border-gray-200 px-1 text-center"
            style="-webkit-appearance: none; margin: 0"
            :min="0"
            :max="30"
            @input="validateInput('start', $event)"
          />
          -
          <input
            v-model="selectedItem[1]"
            type="number"
            class="border-t-0 w-11 border-b border-gray-200 px-1 text-center"
            style="-webkit-appearance: none; margin: 0"
            :min="0"
            :max="30"
            @input="validateInput('end', $event)"
          />
        </span>
        <span>{{
          $t("project.components.experienceRange.moreThanThirty")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import customInput from "@/components/customInput.vue";

export default {
  name: "checkbox-special",
  props: ["title", "value", "required", "disabled", "highlight"],
  components: {
    customInput,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedItem: this.value || [0, 30],
      maxAllowedValue: 30,
    };
  },
  methods: {
    validateInput(type, event) {
      const value = Number(event.target.value);
      if (value > this.maxAllowedValue) {
        event.target.value = this.maxAllowedValue;
        if (type === "start") this.selectedItem[0] = this.maxAllowedValue;
        else this.selectedItem[1] = this.maxAllowedValue;
      } else if (value < 0) {
        event.target.value = 0;
        if (type === "start") this.selectedItem[0] = 0;
        else this.selectedItem[1] = 0;
      }
    },
  },
  watch: {
    selectedItem(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selectedItem = this.listFormatController(val);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
