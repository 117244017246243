<template>
  <div @mousedown="toggleDown" class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 relative max-sm:my-8">
    <uploadPanel ref="uploadPanel" data-v-step="0" @open-sidebar="openCvlistModal" />
    <filtering ref="filtering" :data="List" @deleteFilter="filterDeleted" @filterAll="(r) => getFilter(r)"
      :currentPage="currentPage" @load="chanceLoad" />
    <cvListModal ref="modal" @refresh="getAll" />
    <history ref="history" :Detail="selected" />

    <customTable :items="List" :columns="tableColumns" :loading="load" :current-page="currentPage"
      :page-count="pageCount" :total-count="totalCount" :size="'sm'" @page-change="handlePageChange"
      @row-click="handleRowClick" @select-all="selectAll">
      <!-- Header slot with search and filter -->
      <template #header>
        <div class="flex items-center justify-end w-full h-[50px]">
          <div v-if="checkedList.length > 0">
            <div v-if="!isAllStatus" class="flex flex-col">
              <customSelect v-model="status" :options="statusOptions" :title="$t('cv.index.tableTopList.state')"
                :placeholder="$t('cv.index.tableTopList.yourChoice')" size="sm" class="min-w-[125px]"
                @change="allChangeStatus" />
            </div>
            <div v-else class="pr-2 py-0.5 text-black rounded">
              <i class="fas fa-spinner fa-spin"></i>
              {{ $t("cv.index.tableTopList.loading") }}
            </div>
          </div>
          <div class="w-[200px] tableSearch relative ml-auto border-none outline-none">
            <input v-model="search" @keypress.enter="getSearch()" type="text"
              class="h-full w-full outline-none border-2 border-cvus-primary shadow-cvus-primary/25 shadow-md focus:shadow-cvus-primary/50 focus:!border-cvus-primary-light2 pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
              :placeholder="$t('cv.index.tableList.cvPlaceholder')" />
            <button class="px-3 textcol absolute right-0">
              <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
            </button>
            <button @mousedown.stop="projectFilter = !projectFilter" class="absolute right-9">
              <div class="relative group flex justify-center">
                <Icon :icon="$refs?.filtering?.hasActiveFilters ? 'mage:filter-fill' : 'mage:filter'
                  " class="text-cvus-primary text-2xl" :class="{
                    'text-cvus-primary-light': $refs?.filtering?.hasActiveFilters,
                    'text-cvus-primary': !$refs?.filtering?.hasActiveFilters,
                  }" />
              </div>
            </button>
            <div @mousedown.stop="projectFilter = true" :class="{
              'invisible opacity-0 transition-all ease-in duration-100': !projectFilter,
            }"
              class="w-full z-50 rounded mt-1 px-2 text-xs absolute bg-white top-full border border-gray-300 text-black shadow-md">
              <button v-for="(item, index) in projectState" :key="index" @click="filterButton(item)"
                :class="{ 'text-slate-700': item.active }" class="w-full py-2 border-b border-gray-100 text-left">
                {{ item.name }}
              </button>
            </div>
          </div>
        </div>
      </template>

      <!-- Custom cell templates -->
      <template #checked="{ item, index }">
        <div class="flex gap-5 items-center">
          <customCheckbox v-model="item.checked" class="mr-2" />
          <button :disabled="item.stateId == 4" @click="cvFavorite(item, index)">
            <i v-if="item.isFavorite == 2" class="fa-solid fa-star text-base"></i>
            <i v-else class="fa-regular fa-star text-base"></i>
          </button>
        </div>
      </template>

      <template #fullname="{ item }">


        <div class="flex flex-col py-1 gap-1">
          <div class="flex items-center gap-2">

            {{ formatText(item.fullname) }}
            <div v-if="item.isHired" class="bg-green-500 text-white px-1 rounded-md text-xs">
              {{ $t("cv.index.hired") }}
            </div>
            <div v-else-if="isWithinTwoDays(item.addDate)"
              class="bg-cvus-primary-light text-white px-1 rounded-md text-xs">
              {{ $t("new") }}
            </div>
          </div>
          <span class="text-xs text-gray-500 truncate">
            {{ parseEmail(item.contactEmail) }}
          </span>
        </div>
      </template>

      <template #position="{ item }">
        {{ item.position ? formatText(item.position) : $t("cv.index.unspecified") }}
      </template>

      <template #status="{ item }">
        <select data-v-step="3" class="border border-[#182451] rounded w-[125px]" v-if="!item.changeStatus"
          v-model="item.temporaryStateId" @change="onStatusChange($event, item)">
          <option value="1">{{ $t("cv.index.tableList.active") }}</option>
          <option value="4">{{ $t("cv.index.tableList.unsuitable") }}</option>
          <option value="2">{{ $t("cv.index.tableList.deleted") }}</option>
        </select>
        <div v-else class="flex items-center gap-1 text-sm w-[125px]">
          <i class="fas fa-spinner fa-spin"></i>
          <label class="text-sm">{{ $t("cv.index.tableList.recorded") }}</label>
        </div>
      </template>

      <template #actions="{ item }">
        <div class="flex gap-4 items-center">
          <button @click="() => ((selected = item), $refs.history.toggleSidebar())"
            class="relative group flex justify-center">
            <i class="fa-solid fa-clock-rotate-left w-4"></i>
            <tooltip :title="$t('cv.index.toolTip.history')" tipClass="z-50 w-[160px] !-right-1 !top-6"
              rotateClass="ml-[140px]" :top="true" />
          </button>

          <div class="relative group flex justify-center">
            <router-link :to="`/cv/profile/${item.id}`" class="block min-w-4 w-4 h-full" data-v-step="4">
              <img class="min-w-4 w-4" src="@/assets/cv/document-icon.svg" alt="" />
            </router-link>
            <tooltip :title="$t('cv.index.toolTip.detail')" tipClass="z-50 w-[160px] !-right-1 !top-6"
              rotateClass="ml-[140px]" :top="true" />
          </div>
        </div>
      </template>
    </customTable>

    <tour :steps="steps" :moduleId="2" v-if="!$store.state.userData.tourModuleId.includes(2)" />
  </div>
</template>

<script>
import uploadPanel from "./components/uploadPanel";
import history from "./components/history.vue";
import filtering from "./components/filter-panel.vue";
import moment from "moment";
import "moment/locale/tr";
import { cv } from "@/networking/urlmanager";
import { cvtour } from "@/tourmodel/tour.js";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
import cvListModal from "./components/cvListSide/index.vue";
import customTable from "@/components/customTable.vue";
import customCheckbox from "@/components/customCheckbox.vue";
import customSelect from "@/components/customSelect.vue";

export default {
  name: "cv-page",
  components: {
    uploadPanel,
    history,
    filtering,
    tour,
    tooltip,
    cvListModal,
    customTable,
    customCheckbox,
    customSelect
  },
  data() {
    return {
      temporaryStateId: null, // Geçici seçimi burada tutuyoruz
      List: [],
      search: "",
      currentPage: this.$route.query.currentPage || 0,
      totalCount: "",
      pageCount: 1,
      rowId: "",
      completedModels: [],
      projectFilter: false,
      stateId: "",
      blackList: "",
      load: false,
      projectState: [
        {
          id: 3,
          name: this.$t("cv.index.filterMenu.active"),
          active: false,
        },
        {
          id: 1,
          name: this.$t("cv.index.filterMenu.favorite"),
          active: false,
        },
        {
          id: 4,
          name: this.$t("cv.index.filterMenu.unsuitable"),
          active: false,
        },
        {
          id: 2,
          name: this.$t("cv.index.filterMenu.filterMenuTitle"),
          active: false,
        },
      ],
      steps: cvtour,
      isFilter: 1,
      isAllStatus: false,
      status: 0,
      statusOptions: [
        {
          id: "0",
          name: this.$t("cv.index.tableTopList.yourChoice"),
          disabled: true
        },
        {
          id: "1",
          name: this.$t("cv.index.tableTopList.active")
        },
        {
          id: "4",
          name: this.$t("cv.index.tableTopList.unsuitable")
        },
        {
          id: "2",
          name: this.$t("cv.index.tableTopList.deleted"),
        }
      ],
      selected: "",
    };
  },
  methods: {
    getSearch() {
      this.$refs.filtering.deleteResumeFilter(false);
      this.blackList = "";
      this.stateId = "";
      this.currentPage = 0;
      this.getAll();
    },
    filterDeleted() {
      if (this.currentPage != 0) {
        this.currentPage = 0;
      } else {
        this.getAll();
      }
    },
    isWithinTwoDays(dateString) {
      const now = moment();
      const date = moment(dateString);
      const diffDays = now.diff(date, "days");
      return diffDays <= 2;
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(cv.getAll, {
          params: {
            page: this.currentPage,
            search: this.search,
            isFavorite: this.stateId,
            blackList: this.blackList,
            dataCount: this.dataCount,
          },
        })
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;

          this.List = detail.map((r) => {
            return {
              ...r,
              temporaryStateId: r.stateId,
              changeStatus: false,
              checked: false,
            };
          });

          this.load = false;

        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },

    allChangeStatus(val) {
      this.verifiedMessage(
        this.$t("cv.index.warningMessage"),
        this.$t("cv.index.yesChange")
      ).then((result) => {
        if (result.isConfirmed) {
          this.isAllStatus = true;
          const list = this.checkedList.map((r) => {
            return {
              id: r.id,
              fullname: r.fullname
            };
          });
          this.axios
            .post(cv.allChangeStatus, {
              list: JSON.stringify(list),
              stateId: val.id
            })
            .then(() => {
              this.status = "0";
              this.getData();
              this.isAllStatus = false;
            })
            .catch((err) => {
              this.isAllStatus = false;
              console.log(err);
              this.authController(err);
            });
        }
      });
    },
    selectAll(val) {
      if (val) {
        for (let index = 0; index < this.List.length; index++) {
          this.List[index].checked = true;
        }
      } else {
        for (let index = 0; index < this.List.length; index++) {
          this.List[index].checked = false;
        }
      }
    },
    chanceLoad() {
      this.List = [];
      this.load = true;
    },
    getFilter(val) {
      this.totalCount = val.totalCount;
      this.pageCount = val.pageCount;
      this.List = val.detail.map((r) => {
        return {
          ...r,
          temporaryStateId: r.stateId,
          changeStatus: false,
        };
      });
      this.isFilter = 2;
      this.load = false;
    },
    onStatusChange(event, item) {
      const newValue = event.target.value;
      this.verifiedMessage(
        "'" + item.fullname + "' " + this.$t("cv.index.verifiedMessage"),
        this.$t("cv.index.yesChange")
      ).then((result) => {
        if (result.isConfirmed) {
          item.stateId = newValue;
          this.changeStatus(newValue, item);
        } else {
          item.temporaryStateId = item.stateId;
        }
      });
    },

    changeStatus(val, item) {
      item.changeStatus = true;
      this.axios
        .post(cv.changeStatus, {
          rowId: item.id,
          stateId: val,
          fullname: item.fullname,
        })
        .then(() => {
          this.getAll();
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          item.changeStatus = false;
        });
    },

    filterButton(val) {
      this.blackList = "";
      this.stateId = "";
      if (val.id === 2) {
        this.$refs.filtering.toggleSidebar();
      } else {
        this.$store.commit("deleteResumeFilter");
        this.$refs.filtering.deleteResumeFilter(false);
        if (val.id === 4) {
          this.blackList = 4;
        } else if (val.id === 1) {
          this.stateId = 3;
        } else {
          this.blackList = "";
          this.stateId = "";
        }
        if (this.currentPage == 0) {
          this.getAll();
        } else {
          this.currentPage = 0;
        }
      }
      this.projectState.forEach((item) => {
        item.active = item.id === val.id;
      });
      this.projectFilter = false;
    },
    toggleDown() {
      if (this.$refs.filtering.isOpen == true) {
        this.$refs.filtering.toggleSidebar();
      }
      this.projectFilter = false;
    },
    openCvlistModal() {
      this.$refs.modal.toggleSidebar();
    },
    cvFavorite(item, index) {
      if (item.stateId != 4) {
        item.isFavorite == 2
          ? (item.isFavorite = 1)
          : (item.isFavorite = 2);
        this.axios
          .post(cv.isFavorite, {
            rowId: item.id,
            stateId: item.isFavorite,
            fullname: item.fullname,
          })
          .then(() => {
          })
          .catch((err) => {
            this.authController(err);
          });
      }
    },
    getData() {
      if (this.$refs?.filtering?.hasActiveFilters) {
        setTimeout(() => {
          this.$refs.filtering.applyFilters(this.currentPage);
        }, 50);
      } else {
        this.getAll();
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    handleRowClick({ item }) {
      this.$router.push(`/cv/profile/${item.id}`);
    },

  },
  // mounted() {
  //   this.getAll();
  // },
  created() {

    // if (this.$store.state.resumeFilter.length == 0) {
    this.getData();
    // }

  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("cv.index.date");
    },
  },
  watch: {
    currentPage() {
      this.$router.replace({
        query: {
          currentPage: this.currentPage,
        },
      });
      this.getData();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'checked',
          label: '',
          width: '100px',
          selectAll: true,
        },
        {
          key: 'fullname',
          label: this.$t("cv.index.tableList.resume"),
          width: '250px',
          clickable: true
        },

        {
          key: 'position',
          label: this.$t("cv.index.tableList.position"),
          width: '250px',
          clickable: true
        },

        {
          key: 'addDate',
          label: this.$t("cv.index.tableList.addDate"),
          width: '160px',
          format: 'date'
        },
        {
          key: 'status',
          label: '',
          width: '150px'
        },

      ];
    },
    checkedList() {
      return this.List.filter(item => item.checked);
    }
  },
};
</script>
