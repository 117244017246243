<template>
  <div>
    <button
      @click="toggleSidebar"
      class="py-2 px-8 flex items-center gap-2 bg-cvus-primary hover:text-white hover:shadow-xl rounded-t text-white text-sm font-medium cursor-pointer shadow-xl fixed bottom-0 z-50 h-10 right-5 transition-all ease-out md:transform md:-rotate-90 md:origin-bottom-right md:top-[90px] md:bottom-auto md:right-0"
    >
      <span class="animate-pulse">
        <i class="fas fa-list cursor-pointer" aria-hidden="true"></i>
        {{ $t("cv.components.cvListModal.resumeList") }}
      </span>
    </button>
    <addEmail ref="addEmailRef" @refresh="closeSide()" :cvId="cvId" />
    <sideModal
      ref="cvListModal"
      modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full"
      :isHeader="true"
      :headerTitle="$t('cv.components.cvListModal.resumeListTitle')"
      @close="closeSide()"
      :isRefresh="true"
      @refresh="getAll()"
      :hidenOverflowY="true"
    >
      >
      <template v-slot:content>
        <navbar
          ref="navbar"
          :list="buttonList"
          @change="changePage"
          :load="load"
          :counts="tabCounts"
        />
        <loadItems :load="load" :lenghtItem="List?.length" />

        <section
          v-if="buttonList[activePage]"
          :class="!load && List.length > 0 && 'h-full overflow'"
        >
          <component
            :is="buttonList[activePage].component"
            :key="buttonList[activePage].id"
            :ref="buttonList[activePage].referance"
            @addEmail="addEmail"
            @load="(r) => (load = r)"
            @chanceList="(r) => (List = r)"
            @refresh="$emit('refresh', true)"
          ></component>
        </section>
      </template>
    </sideModal>
  </div>
</template>
<script>
import { cv } from "@/networking/urlmanager";
import navbar from "./components/navbar.vue";
import loadingCv from "./page/loadingCv.vue";
import conflictsCv from "./page/conflictsCv.vue";
import errorCv from "./page/errorCv.vue";
import loadItems from "./components/load.vue";
import addEmail from "@/views/project/projectUser/components/addEmail.vue";
export default {
  name: "cv-list-side",
  components: { navbar, conflictsCv, loadItems, errorCv, addEmail },
  data() {
    return {
      load: false,
      List: [],
      intervalId: null,
      tabCounts: {
        0: 0,
        1: 0,
        2: 0,
      },
      buttonList: [
        {
          id: 1,
          name: this.$t("cv.components.cvListModal.tab.loading"),
          component: loadingCv,
          referance: "loadingCv",
        },
        {
          id: 2,
          name: this.$t("cv.components.cvListModal.tab.hasRegistered"),
          component: conflictsCv,
          referance: "conflictsCv",
        },
        {
          id: 3,
          name: this.$t("cv.components.cvListModal.tab.error"),
          component: errorCv,
          referance: "errorCv",
        },
      ],
      cvId: 0,
      activePage: 0,
    };
  },
  methods: {
    closeSide(){      
      this.$refs.cvListModal.close();
      this.$emit("refresh", true);
    },
    addEmail(errItem){
      this.cvId = errItem.cvId;
      this.$refs.addEmailRef.show();
    },
    async getAllCounts() {
      try {
        // Yüklenen CV'ler
        const loadingRes = await this.axios.get(cv.getCvTrain, {
          params: { stateId: 1 },
        });

        // Çakışan CV'ler
        const conflictsRes = await this.axios.get(cv.getConflictCv);

        // Hatalı CV'ler
        const errorRes = await this.axios.get(cv.getCvTrain, {
          params: { stateId: 3 },
        });

        this.tabCounts = {
          0: loadingRes.data.data.length,
          1: conflictsRes.data.data.length,
          2: errorRes.data.data.length,
        };
      } catch (err) {
        console.log(err);
        this.authController(err);
      }
    },
    changePage(index) {
      this.activePage = index;
      this.getAll();
    },
    toggleSidebar() {
      this.$refs.cvListModal.show();
      this.activePage = this.activePage || 0;
      this.getAllCounts(); // Get initial counts
      this.getAll();
    },
    getAll() {
      this.$nextTick(() => {
        const referance = this.buttonList[this.activePage].referance;
        if (this.$refs[referance] && this.$refs[referance].getAll) {
          this.$refs[referance].getAll();
          this.intervalId = setInterval(() => {
            try {
              if (this.List.length > 0) {
                this.$refs[referance].getAll(false);
                this.getAllCounts(); // Update counts periodically
              } else {
                clearInterval(this.intervalId);
              }
            } catch (err) {
              clearInterval(this.intervalId);
            }
          }, 10000);
        }
      });
    },
  },
};
</script>
