<template>
  <div class="mb-10">
    <div class="w-full py-10">
      <img class="mx-auto w-72" src="@/assets/global/fullLogo.png" alt="" />
    </div>
    <div class="flex justify-center gap-3 items-center">
      <router-link to="/"
        ><button
          :class="[buttonClass, $route.name == 'signIn' && activeButton]"
        >
          <span
            :class="
              $route.name != 'signIn' && 'border-b-2 border-slate-800 pb-1'
            "
          >
            {{ $t("auth.components.logobutton.signIn") }}</span
          >
        </button></router-link
      >

      <router-link to="/sign/up"
        ><button
          :class="[buttonClass, $route.name == 'signUp' && activeButton]"
        >
          <span
            :class="
              $route.name != 'signUp' && 'border-b-2 border-slate-800 pb-1'
            "
          >
            {{ $t("auth.components.logobutton.signUp") }}</span
          >
        </button></router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "logo-buttob",
  data() {
    return {
      buttonClass: "px-6 text-base	font-semibold textcol py-2",
      activeButton:
        "bg-white shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-full",
    };
  },
};
</script>
