<template>
    <div class="bgcol w-full h-screen text-white flex justify-center items-center">
        <div>
            <h1 class="text-9xl text-slate-300 text-center mb-5">404</h1>
            <h1 class="text-2xl">{{ $t("error.index.warningMessage") }}</h1>
            <router-link to="/" class="px-4 py-1.5 text-black bg-white block text-center mt-5 rounded">{{ $t("error.index.back") }}</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name:'error-page'
}
</script>