var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex w-full flex-col gap-1"},[(_vm.title || _vm.label)?_c('label',{staticClass:"textcol font-semibold flex gap-1 items-center",class:[
    { 'text-xs': _vm.size === 'sm' },
    { 'text-sm': _vm.size === 'md' },
    { 'text-base': _vm.size === 'lg' }
  ]},[_vm._v(" "+_vm._s(_vm.title || _vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-[10px]"},[_c('i',{staticClass:"fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600"})]):_vm._e()]):_vm._e(),_c('div',{ref:"selectButton",staticClass:"w-full border bordercol h-full rounded flex justify-between items-center",class:[
    { 'py-1 px-2 text-xs mt-1': _vm.size === 'sm' },
    { 'py-2 px-3 text-sm mt-1': _vm.size === 'md' },
    { 'py-3 px-4 text-base mt-2': _vm.size === 'lg' },
    {
      '!border-red-500': _vm.error,
      'cursor-pointer': !_vm.loading && !_vm.disabled,
      'cursor-not-allowed opacity-75': _vm.loading || _vm.disabled
    }
  ],on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"truncate flex w-full h-full min-h-[20px]",class:{ 'text-gray-400': (!_vm.selectedOption && !_vm.multiple) || (!_vm.selectedOptions.length && _vm.multiple) || _vm.disabled,
       
     }},[(_vm.multiple)?[(_vm.selectedOptions.length)?[_c('div',{staticClass:"flex flex-wrap gap-1"},_vm._l((_vm.selectedOptions),function(option,index){return _c('div',{key:`selected-${option.id}-${index}`,staticClass:"bg-gray-100 px-2 py-1 flex rounded-md break-words whitespace-normal"},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)]:[_vm._v(_vm._s((_vm.placeholder || _vm.$t('general.components.select.placeholder'))))]]:[(_vm.selectedOption && _vm.selectedOption?.name)?_c('span',[_vm._v(_vm._s(_vm.selectedOption.name))]):_c('span',{staticClass:"text-gray-400"},[_vm._v(_vm._s((_vm.placeholder || _vm.$t('general.components.select.placeholder'))))])]],2),_c('div',{staticClass:"flex items-center gap-2"},[(_vm.loading)?_c('div',{staticClass:"loading-spinner",class:[
        { 'w-2 h-2': _vm.size === 'sm' },
        { 'w-3 h-3': _vm.size === 'md' },
        { 'w-4 h-4': _vm.size === 'lg' }
      ]}):(_vm.clearable && !_vm.disabled && ((_vm.multiple && _vm.selectedOptions.length) || (!_vm.multiple && _vm.selectedOption)))?_c('i',{staticClass:"fas fa-times text-gray-400 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}}):_vm._e(),(!_vm.loading)?_c('i',{staticClass:"fas fa-chevron-down transition-transform flex-shrink-0",class:[
        { 'text-xs': _vm.size === 'sm' },
        { 'text-sm': _vm.size === 'md' },
        { 'text-base': _vm.size === 'lg' },
        { 'rotate-180': _vm.isOpen }
      ]}):_vm._e()])]),(_vm.isOpen && _vm.closeOnClickOutside)?_c('Teleport',{attrs:{"to":"body"}},[_c('div',{staticClass:"fixed inset-0 z-[9998]",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeDropdown.apply(null, arguments)}}})]):_vm._e(),_c('Teleport',{attrs:{"to":"body"}},[_c('transition',{attrs:{"name":"dropdown"}},[(_vm.isOpen)?_c('div',{ref:"dropdownElement",staticClass:"fixed z-[9999] bg-white border bordercol2 rounded shadow-lg overflow-y-auto dropdown-options",class:[
          { 'max-h-[150px]': _vm.size === 'sm' },
          { 'max-h-[200px]': _vm.size === 'md' },
          { 'max-h-[250px]': _vm.size === 'lg' }
        ],style:(_vm.dropdownStyle),on:{"scroll":_vm.handleDropdownScroll}},[_c('div',{staticClass:"sticky top-0 bg-white border-b bordercol2 z-10"},[(_vm.searchable)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-shrink-0 ml-2"},[_c('Icon',{staticClass:"text-gray-400",class:[
                { 'text-xs': _vm.size === 'sm' },
                { 'text-sm': _vm.size === 'md' },
                { 'text-base': _vm.size === 'lg' }
              ],attrs:{"icon":"mdi:magnify"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"w-full border-none focus:outline-none",class:[
                { 'px-2 py-1 text-xs': _vm.size === 'sm' },
                { 'px-3 py-2 text-sm': _vm.size === 'md' },
                { 'px-4 py-3 text-base': _vm.size === 'lg' }
              ],attrs:{"type":"text","placeholder":_vm.searchPlaceholder || _vm.$t('general.components.select.searchPlaceholder')},domProps:{"value":(_vm.searchTerm)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value},_vm.handleSearch]}})]):_vm._e()]),(_vm.filteredOptions.length === 0 && !_vm.loading)?_c('div',{staticClass:"px-3 py-2 text-gray-500 text-center",class:[
          { 'text-xs': _vm.size === 'sm' },
          { 'text-sm': _vm.size === 'md' },
          { 'text-base': _vm.size === 'lg' }
        ]},[_vm._t("no-results",function(){return [_vm._v(" "+_vm._s(_vm.$t('general.components.select.noElements'))+" ")]})],2):[_vm._l((_vm.filteredOptions),function(option,index){return _c('div',{key:`option-${option.id}-${index}`,staticClass:"hover:bg-gray-50 border-b last:border-b-0 bordercol2 flex items-center justify-between",class:[
              { 'px-2 py-1 text-xs min-h-[30px]': _vm.size === 'sm' },
              { 'px-3 py-2 text-sm min-h-[36px]': _vm.size === 'md' },
              { 'px-4 py-3 text-base min-h-[44px]': _vm.size === 'lg' },
              { 'bg-gray-50': _vm.multiple ? _vm.isOptionSelected(option) : _vm.selectedOption?.id === option.id },
              { 'cursor-pointer': !option.disabled },
              { 'cursor-not-allowed opacity-60': option.disabled }
            ],on:{"click":function($event){!option.disabled && _vm.selectOption(option)}}},[_c('span',[_vm._v(_vm._s(option.name))]),(_vm.multiple && _vm.isOptionSelected(option))?_c('i',{staticClass:"fas fa-check text-green-500"}):_vm._e()])}),(_vm.loading)?_c('div',{staticClass:"flex justify-center items-center py-2 border-t bordercol2"},[_c('div',{staticClass:"loading-spinner",class:[
              { 'w-2 h-2': _vm.size === 'sm' },
              { 'w-3 h-3': _vm.size === 'md' },
              { 'w-4 h-4': _vm.size === 'lg' }
            ]}),_c('span',{staticClass:"ml-2 text-gray-500",class:[
              { 'text-xs': _vm.size === 'sm' },
              { 'text-sm': _vm.size === 'md' },
              { 'text-base': _vm.size === 'lg' }
            ]},[_vm._v(_vm._s(_vm.$t('general.components.select.loadingMore')))])]):_vm._e()]],2):_vm._e()])],1),(_vm.error)?_c('span',{staticClass:"block mt-1 text-red-500",class:[
    { 'text-[10px]': _vm.size === 'sm' },
    { 'text-xs': _vm.size === 'md' },
    { 'text-sm': _vm.size === 'lg' }
  ]},[_vm._v(" "+_vm._s(_vm.errorMessage || _vm.$t('general.components.select.errorText'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }