<template>
    <modal name="referenceModal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '35%'"
        :scrollable="true">
        <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
            <h1 class="text-lg">{{
        $t("settings.reference.index.addReference")
    }}
            </h1>
            <button @click="hide()" type="button">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <form @submit.prevent="addNewReference" class="p-5 grid grid-cols-1 gap-4">
            <div>
                <customInput v-model="form.value" type="text"
                    :placeholder="$t('settings.reference.index.titlePlaceholder')"
                    :required="true" :is-error="valueError"
                    :title="$t('settings.reference.index.title')" />
            </div>

            <requiredField />

            <buttonItem class="bgcol text-white rounded text-sm" :load="load">
                {{ $t("settings.reference.index.save") }}
            </buttonItem>
        </form>
    </modal>
</template>
<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import { referenceAndPayment } from "@/networking/urlmanager";
import requiredField from "@/components/requiredField.vue";
export default {
    name: "ReferenceModal",

    data() {
        return {
            modalType: 'save',
            load: false,
            referenceId: null,
            form: {
                value: ''
            },
            valueError: false
        };
    },
    components: {
        customInput,
        buttonItem,
        requiredField
    },

    methods: {
        async addNewReference() {
            this.load = true;
            try {
                const response = await this.axios.post(
                    referenceAndPayment.referenceTypeAdd,
                    {
                        valueId: this.referenceId ? this.referenceId : undefined,
                        value: this.form.value
                    }
                );
                this.successMessage();
                this.$emit("success");
                this.hide();
            } catch (error) {
                this.authController(error);
            } finally {
                this.load = false;
            }
        },

        show(type = 'add', item = null) {
            this.modalType = type;
            if (type === 'edit' && item) {
                this.form.value = item.value;
                this.referenceId = item.id;
            } else {
                this.form.value = '';
                this.referenceId = null;
            }
            this.$modal.show("referenceModal");
        },
        
        hide() {
            this.$modal.hide("referenceModal");
            this.form.value = '';
            this.referenceId = null;
        },
    },
};
</script> 