<template>
  <div id="chart" class="pb-5">
    <apexchart
      ref="chart"
      type="bar"
      height="480"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>

<script>
import { general } from "@/networking/urlmanager";

export default {
  name: "PositionChart",
  
  data() {
    return {
      chartSeries: [{
        name: "",
        data: [0, 0, 0] // Initialize with empty data
      }],
      error: null,
      loading: false,
      chartColors: ["#50b696", "#5070b6" ,"#b65070", "#b0bec5"],
    };
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
        },
        title: {
          text: this.$t("general.components.positionChart.openPositionChart"),
          align: "left",
          style: {
            fontSize: "14px",
            fontWeight: "600",
          },
        },
        colors: this.chartColors,
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            borderRadius: 4,
            dataLabels: {
              position: 'top'
            }
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toString();
          },
          style: {
            fontSize: '12px',
            fontWeight: '500'
          }
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            this.$t("general.components.positionChart.completed"),
            this.$t("general.components.positionChart.open"),
            this.$t("general.components.positionChart.cancel"),
            this.$t("general.components.positionChart.passive"),
          ],
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "500",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function(value) {
              return value.toString();
            }
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 300
            },
            plotOptions: {
              bar: {
                columnWidth: '70%'
              }
            }
          }
        }]
      };
    }
  },

  methods: {
    async fetchPositionData() {
      try {
        this.loading = true;
        const response = await this.axios.get(general.getpositionAll);
        const data = response.data.data[0];
        
        this.chartSeries = [{
          name: this.$t("general.components.positionChart.situation"),
          data: [data.completedCount, data.openCount, data.closedCount,data.passiveCount]
        }];
      } catch (error) {
        this.error = error.message;
        console.error("Failed to fetch position data:", error);
      } finally {
        this.loading = false;
      }
    },

    updateChartOnLocaleChange() {
      if (!this.$refs.chart) return;

      const updatedOptions = {
        title: {
          text: this.$t("general.components.positionChart.openPositionChart")
        },
        xaxis: {
          categories: [
            this.$t("general.components.positionChart.completed"),
            this.$t("general.components.positionChart.open"),
            this.$t("general.components.positionChart.cancel"),
            this.$t("general.components.positionChart.passive"),
          ]
        }
      };

      this.$refs.chart.updateOptions(updatedOptions);
      
      if (this.chartSeries?.[0]?.data) {
        const updatedSeries = [{
          name: this.$t("general.components.positionChart.situation"),
          data: this.chartSeries[0].data
        }];
        this.$refs.chart.updateSeries(updatedSeries);
      }
    }
  },

  watch: {
    "$i18n.locale": {
      handler() {
        this.updateChartOnLocaleChange();
      },
      immediate: true
    }
  },

  async created() {
    await this.fetchPositionData();
  }
};
</script>