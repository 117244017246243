<template>
  <modal
    name="edit-profile-information"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-xl">{{ $t("cv.profile.components.edit.title") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="fullname"
          type="text"
          min="2"
          max="55"
          :required="true"
          ref="fullname"
          :placeholder="$t('cv.profile.components.edit.nameSurnamePlacholder')"
          :title="$t('cv.profile.components.edit.nameSurname')"
        />
        <customInput 
            v-model="position"
            type="text"
            :label="$t('cv.profile.workExperience.components.add.position')"
            :placeholder="$t('cv.profile.workExperience.components.add.position')"
        />

        <customInput
          v-model="biography"
          type="textarea"
          max="500"
          :placeholder="$t('cv.profile.components.edit.biographyPlacholder')"
          :title="$t('cv.profile.components.edit.biography')"
          :rows="10"
        />

        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.components.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { cv, utilityPosition } from "@/networking/urlmanager.js";

export default {
  name: "addProject",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      fullname: "",
      position: "",
      biography: "",
      
    };
  },
  methods: {
    async getPosition() {
      const response = await this.axios.get(c.getAll);
      return response.data.data;
    },
    show() {
      setTimeout(() => {
        this.fullname = this.$store.state.extractDetail.fullname;
        this.position = this.$store.state.extractDetail.position;
        this.biography = this.$store.state.extractDetail.biography;
      }, 50);
      this.$modal.show("edit-profile-information");
    },
    hide() {
      this.$modal.hide("edit-profile-information");
    },
    save() {
      this.load = true;
      this.$emit("loadChance", true);
      this.$store.commit("editProfileInformation", {
        fullname: this.fullname,
        position: this.position,
        biography: this.biography,
      });

      this.fullname = "";
      this.position = "";
      this.biography = "";
      this.successMessage();

      this.load = false;
      this.$emit("loadChance", true);

      this.hide();
    },
    add() {
      this.$refs.fullname.validateInput();
      if (this.fullname || this.position || this.biography) {
        this.load = true;
        this.axios
          .post(cv.editInformation, {
            cvId: this.$route.params.id,
            fullname: this.fullname,
            position: this.position,
            biography: this.biography,
          })
          .then((res) => {
            this.successMessage();
            this.getInformation();
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            console.error(err);
            this.authController(err);
            this.load = false;
          });
      } else {
        this.authController(this.$t("message.error"));
      }
    },
    getInformation() {
      this.$emit("loadChance", true);
      this.axios
        .get(cv.getInformation + "?cvId=" + this.$route.params.id)
        .then((res) => {
          this.$store.commit("editProfileInformation", {
            fullname: res.data.data[0].fullname,
            position: res.data.data[0].position,
            biography: res.data.data[0].biography,
          });
        })
        .catch((err) => {
          console.error(err);
          this.authController(err);
        })
        .finally(() => {
          this.$emit("loadChance", true);
        });
    },
  },
};
</script>
