<template>
  <button
    @click="goUrl"
    class="flex py-2 gap-3 items-center h-full hover:bg-gray-100 p-3 rounded-b border-b border-gray-200 w-full"
  >
    <i
      v-if="icon"
      class="text-2xl"
      :class="{
        'fas fa-spinner fa-2x fa-spin': icon == 1,
        'fas fa-check text-green-700': icon == 2,
        'fa-solid fa-xmark text-rose-600': icon == 3,
      }"
    ></i>
    <div class="text-left w-full">
      <h3 v-if="title" class="text-sm text-gray-700 font-semibold">
        {{ title }}
      </h3>
      <p v-if="description" class="text-xs pt-1 w-full">
        {{ description }}
      </p>
      <p v-if="date" class="text-[10px] pt-1 w-full">
        {{ date | getFullDateFormat }}
      </p>
    </div>
  </button>
</template>
<script>
import moment from "moment";

export default {
  name: "notification-card",
  props: ["icon", "title", "description", "date", "href"],
  methods: {
    goUrl() {
      if (this.href) {
        const currentPath = decodeURIComponent(this.$route.fullPath).trim();
        const targetPath = this.href.trim();
      
          this.$router.replace('/').then(() => {
            this.$router.push(this.href);
          });
      }
    },
  },
  filters: {
    getFullDateFormat(val) {
      if (val) return moment(val).format("LLL");
      else return "Tarih Yok";
    },
  },
};
</script>