<template>
  <div class="w-full flex max-sm:flex-wrap mt-10">
    <div class="w-full max-sm:w-full">
      <div class="flex justify-between max-sm:flex-col-reverse">
        <div class="flex items-center">
          <h3 class="text-lg font-bold relative">
            <span class="border-t-2 border-[#344CA0] pt-1">İş Deneyimi</span>
          </h3>
          <div class="flex items-center ml-3">
            <span class="bg-[#344CA0] text-white px-2 py-0.5 text-sm font-medium rounded-l-md">
              {{ Math.floor($store.state.extractDetail.experience / 12) }} yıl
            </span>
                    <span class="bg-[#5268c2] text-white px-2 py-0.5 text-sm font-medium rounded-r-md" v-if="$store.state.extractDetail.experience % 12 > 0">
              {{ $store.state.extractDetail.experience % 12 }} ay
            </span>
          </div>
        </div>


        <addItem ref="addModal" :isEdit="isEdit" @refresh="getAll()" />
        <editItem
          ref="editItem"
          :isEdit="isEdit"
          @refresh="getAll()"
          :Detail="selected"
          :index="index"
          @loadChance="loading = !loading"
        />

        <div
          class="flex justify-end items-center gap-5 max-sm:justify-between max-sm:mb-6"
        >
          <div class="relative group flex justify-center">
            <button
              @click="$refs.addModal.show()"
              class="w-20 py-2 text-base rounded text-black"
            >
              <i class="fas fa-plus mr-1"></i>
              {{ $t("cv.profile.workExperience.index.add") }}
            </button>
            <tooltip
              :title="$t('cv.profile.workExperience.index.toolTip')"
              tipClass=" bottom-8 max-sm:ml-2 text-center"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isContent && !loading"
        class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 relative"
      >
        <draggable
          v-model="$store.state.extractDetail.workexperience"
          @change="
            $store.commit(
              'setWorkExperience',
              $store.state.extractDetail.workexperience
            )
          "
          @start="drag = true"
          @end="drag = false"
        >
          <div
            class="w-full border-b border-b-[#344ba045] pb-4 border-dotted avoid-page-break"
            v-for="(item, index) in sortedWorkExperience"
            :key="index"
          >


<!--            :five="item.doesItContinue ? Number(item.doesItContinue) === 1 ? $t('cv.profile.workExperience.components.edit.continues') : $t('cv.profile.workExperience.components.edit.completed')  : ''"-->

            <card
              :img="require('@/assets/cv/carbon_inventory-management.svg')"
              :rowId="item.id"
              :title="item.company"
              :sub="item.position"
              :third="formatDuration(item.duration)"
              :four="item.description"
              :index="index"
              deleteCommitName="deleteEducation"
              :isDraggable="true"
              @deleteItem="!isEdit ? deleteStoreItem(index) : deleteItem(item)"
              @editItem="editItem(item, index)"
            />
          </div>
        </draggable>
      </div>

      <dataLoader
        :load="loading"
        :isContent="isContent"
        :title="$t('cv.profile.workExperience.index.dataLoader')"
      />
    </div>
  </div>
</template>

<script>
import card from "../components/card.vue";
import { workExperience } from "@/networking/urlmanager.js";
import draggable from "vuedraggable";
import tooltip from "@/components/tooltip.vue";
import addItem from "./components/add.vue";
import editItem from "./components/edit.vue";
import dataLoader from "../components/dataLoader.vue";

export default {
  name: "workExperience",
  props: ["load", "isEdit"],
  components: {
    card,
    draggable,
    tooltip,
    addItem,
    editItem,
    dataLoader,
  },
  data() {
    return {
      loading: false,
      selected: "",
      index: "",
    };
  },
  methods: {
    getAll() {
      this.loading = true;
      this.axios
        .get(workExperience.getAll + "?cvId=" + this.$route.params.id)
        .then((res) => {
          this.$store.commit("setWorkExperience", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          let experince = this.$store.state.extractDetail.workexperience;

          this.axios
            .post(workExperience.delete, {
              rowId: item.id,
              cvId: this.$route.params.id,
              experinceList: JSON.stringify(experince),
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    deleteStoreItem(index) {
      this.verifiedMessage().then((acc) => {
        if (acc.isConfirmed) {
          this.$store.commit("deleteExperience", index);
        }
      });
    },
    editItem(val, index) {
      this.$refs.editItem.show();
      this.selected = val;
      this.index = index;
    },
    formatDuration(durationString) {
      if (!durationString) return '';
      
      // Split the duration string to handle start and end dates
      const parts = durationString.split(' - ');
      
      if (parts.length !== 2) return durationString; // Return original if not in expected format
      
      const startDateStr = parts[0];
      const endDateStr = parts[1];
      
      // Format the start date
      const formattedStart = this.formatDatePart(startDateStr);
      
      // Format the end date (handle special case for '???')
      const formattedEnd = endDateStr === '???' ? '???' : this.formatDatePart(endDateStr);
      
      return `${formattedStart} - ${formattedEnd}`;
    },
    
    formatDatePart(datePart) {
      // Check if the date is in YYYY.MM.DD format
      if (!/^\d{4}\.\d{2}\.\d{2}$/.test(datePart)) {
        return datePart; // Return as is if not in expected format
      }
      
      // Parse the YYYY.MM.DD format
      const [year, month, day] = datePart.split('.');
      
      // Return in DD/MM/YYYY format
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    formattedExperience() {
      const totalMonths = this.$store.state.extractDetail.experience;

      // Check if totalMonths is a valid number
      if (!totalMonths || isNaN(totalMonths)) {
        return '';
      }

      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      // Different formatting options based on the values
      if (years === 0) {
        return `${months} ay`;
      } else if (months === 0) {
        return `${years} yıl`;
      } else {
        return `${years} yıl ${months} ay`;
      }
    },
    isContent() {
      if (Array.isArray(this.$store.state.extractDetail.workexperience))
        if (this.$store.state.extractDetail.workexperience.length > 0)
          return true;
        else return false;
      else return false;
    },
    sortedWorkExperience() {
      if (!Array.isArray(this.$store.state.extractDetail.workexperience)) {
        return [];
      }
      
      return [...this.$store.state.extractDetail.workexperience].sort((a, b) => {
        // Extract the first date from formatDate (format: "YYYY.MM.DD - YYYY.MM.DD" or "YYYY.MM.DD - ???")
        const getFirstDate = (formatDate) => {
          if (!formatDate) return null;
          const dateStr = formatDate.split(' - ')[0];
          // Try to parse the date string into a Date object
          return dateStr ? new Date(dateStr.split('.').join('-')) : null;
        };
        
        const dateA = getFirstDate(a.formatDate);
        const dateB = getFirstDate(b.formatDate);
        
        // Handle cases where dates might be null or invalid
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        
        // Sort in descending order (newest first)
        return dateB - dateA;
      });
    }
  },
};
</script>
