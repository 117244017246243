<template>
  <div class="w-full pt-12">
    <form @submit.prevent="save">
        <navbar
          :load="getLoad"
          :buttonLoad="loading"
          :title="$t('settings.general.index.generalSettings')"
          :description="$t('settings.general.index.generalSettingsText')"
        />

      <div
        v-if="!getLoad"
        class="mt-3 mb-5 w-full max-md:mt-0 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"
      >
        <div class="flex gap-24 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.general.index.logoEdit") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.general.index.logoEditText") }}
            </p>
          </div>
          <div class="w-6/12 max-md:w-full">
            <uploadFile :img="form.logo" @fileSelected="onFileSelectedLogo" />
          </div>
        </div>

        <div class="flex gap-24 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.general.index.resumeFormatFile") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.general.index.resumeFormatFileText") }}
            </p>
          </div>
          <div class="w-6/12 max-md:w-full">
            <uploadFormat
              :img="form.format"
              @fileSelected="onFileSelectedFormat"
            />
          </div>
        </div>

        <div class="flex gap-24 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.general.index.consentStatementAgreement") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.general.index.consentStatementAgreementText") }}
            </p>
            <span class="text-[10px] block text-red-600 font-semibold">
              {{
                $t(
                  "settings.general.components.uploadConsentStatement.consentStatementFileSize"
                )
              }}
            </span>
          </div>
          <div class="w-6/12 max-md:w-full">
            <uploadConsentStatement
              :img="form.consentStatementUrl"
              @fileSelected="onFileConsentStatement"
              ref="uploadConsentStatement"
            />
          </div>
        </div>

        <div
          class="flex gap-[97px] py-8 border-b border-gray-200 max-md:flex-wrap"
        >
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.general.index.cvStorageDuration") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.general.index.cvStorageDurationSetting") }}
            </p>
          </div>
          <div class="w-6/12 max-md:w-full z-0">
            <div>
              <div class="flex items-center space-x-2">
                <label
                  class="textcol font-semibold flex gap-1 items-center pb-1"
                >
                  {{ $t("settings.general.index.storagePeriod") }}
                </label>
                <div class="relative group flex justify-center">
                  <i
                    class="fa-solid fa-circle-exclamation text-sm text-[#182451] mb-1"
                  ></i>
                  <tooltip
                    :title="$t('settings.general.index.storagePeriodTooltip')"
                    tipClass="z-50 !bottom-6 !w-[130px]"
                    :bottom="true"
                  />
                </div>
              </div>

              <div class="!w-full">
                <settingInput
                  v-model="form.cvStorageMonth"
                  @change="(r) => chanceStorage(r)"
                  type="monthInput"
                  min="1"
                  max="60"
                  :required="true"
                />
              </div>
            </div>
            <div class="pt-5">
              <label class="textcol font-semibold flex gap-1 items-center pb-1">
                {{ $t("settings.general.index.reminderDuration") }}
              </label>
              <select
                v-model="form.storageTimeReminder"
                required
                class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs"
              >
                <option selected value="" disabled>
                  {{ $t("settings.general.index.cvDurationMonthSelect") }}
                </option>
                <option
                  v-for="index in storageTimeReminderMonths"
                  :key="index"
                  :value="index"
                  v-show="index < form.cvStorageMonth"
                >
                  {{ index }}
                  {{ $t("settings.general.index.monthToGo") }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <settingInput
          :title="$t('settings.general.index.companyName')"
          class="flex gap-[97px]"
          v-model="form.name"
          :required="true"
          type="text"
          min="2"
          max="255"
          :description="$t('settings.general.index.companyNameText')"
          :plac="$t('settings.general.index.companyNamePlacholder')"
        />

        <div class="flex gap-24 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.general.index.sector") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.general.index.sectorText") }}
            </p>
          </div>
          <div class="w-6/12 max-md:w-full z-0">
            <sectorSelect
              @change="sectorChange"
              :value="form.sector"
              @refresh="getSector()"
            />
          </div>
        </div>

        <settingInput
          class="flex gap-[97px]"
          :title="$t('settings.general.index.personalNumber')"
          v-model="form.personalNumber"
          :required="true"
          type="number"
          min="2"
          max="100000"
          :description="$t('settings.general.index.personalNumberText')"
          :plac="$t('settings.general.index.personalNumberPlacholder')"
        />

        <div
          class="flex gap-[97px] py-8 border-b border-gray-200 max-md:flex-wrap"
        >
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.general.index.workingHours") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.general.index.workingHoursText") }}
            </p>
          </div>
          <div class="w-6/12 max-md:w-full -z-1">
            <select
              name=""
              id=""
              required
              class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs"
              v-model="form.workingHours"
            >
              <option v-for="(item, index) in hours" :key="index" :value="item">
                {{ item }}
              </option>

              <option value="1">
                {{ $t("settings.general.index.other") }}
              </option>
            </select>

            <div v-if="form.workingHours == 1" class="flex gap-3 mt-3">
              <input
                type="time"
                v-model="form.startWorking"
                class="w-6/12 border bordercol rounded py-3 outline-none pl-3 text-xs"
                required
              />
              <input
                type="time"
                v-model="form.endWorking"
                class="w-6/12 border bordercol rounded py-3 outline-none pl-3 text-xs"
                required
              />
            </div>
          </div>
        </div>

        <settingInput
          class="flex gap-[97px]"
          :title="$t('settings.general.index.companyDescription')"
          :textareaClass="[
            { '!border-red-500 shake': validationResults.isDescriptionInvalid },
            'h-44',
          ]"
          v-model="form.description"
          :required="true"
          type="textarea"
          cols="30"
          rows="10"
          min="0"
          max="5000"
          :description="$t('settings.general.index.companyDescriptionText')"
          :plac="$t('settings.general.index.companyDescriptionPlacholder')"
        />

        <settingInput
          class="flex gap-[97px]"
          :title="$t('settings.general.index.classifiedAdTitle')"
          :textareaClass="[
            {
              '!border-red-500 shake':
                validationResults.isHiddenAdvertDescriptionInvalid,
            },
            'h-44',
          ]"
          v-model="form.hiddenAdvertDescription"
          type="textarea"
          cols="30"
          rows="10"
          min="0"
          max="5000"
          :description="$t('settings.general.index.classifiedAdDescription')"
          :plac="$t('settings.general.index.classifiedAdPlaceholder')"
        />

        <settingInput
          class="flex gap-[97px]"
          :title="$t('settings.general.index.openListingsTitle')"
          :textareaClass="[
            {
              '!border-red-500 shake':
                validationResults.isActiveAdvertDescriptionInvalid,
            },
            'h-44',
          ]"
          v-model="form.activeAdvertDescription"
          type="textarea"
          cols="30"
          min="0"
          max="5000"
          rows="10"
          :description="$t('settings.general.index.openListingsDescription')"
          :plac="$t('settings.general.index.openListingsPlaceholder')"
        />

        <!-- eslint-disable-next-line vue/valid-v-on -->
        <refreshToken
          @setDefaultService="setDefaultService"
          :defaultService="form.defaultProvide"
        />
        <AddInterviewType />
      </div>
    </form>
  </div>
</template>

<script>
import AddInterviewType from "./components/AddInterviewType.vue";
import navbar from "../components/navbar.vue";
import settingInput from "../components/settingInput.vue";
import uploadFile from "./components/uploadFile.vue";
import uploadFormat from "./components/uploadFormat.vue";
import uploadConsentStatement from "./components/uploadConsentStatement.vue";
import refreshToken from "./components/refreshToken.vue";

import sectorSelect from "@/components/dev/sectorSelect.vue";
import tooltip from "@/components/tooltip.vue";
import { settingGeneral } from "@/networking/urlmanager";
export default {
  name: "settingPage",
  components: {
    navbar,
    AddInterviewType,
    settingInput,
    uploadFile,
    uploadFormat,
    sectorSelect,
    uploadConsentStatement,
    tooltip,
    refreshToken,
  },
  data() {
    return {
      form: {
        name: "",
        sector: {},
        personalNumber: "",
        description: "",
        logo: "",
        format: "",
        consentStatementFile: "",
        consentStatementUrl: "",
        cvStorageMonth: "",
        storageTimeReminder: "",
        isWorkingHours: true,
        workingHours: "",
        startWorking: "",
        endWorking: "",
        hiddenAdvertDescription: "",
        activeAdvertDescription: "",
        defaultProvide: 0,
        deletedList: {
          logo: 1,
          format: 1,
          consentStatement: 1,
        },
      },
      storageTimeReminderMonths: 11,
      isDescriptionInvalid: false,
      isActiveAdvertDescriptionInvalid: false,
      isHiddenAdvertDescriptionInvalid: false,
      isScrolled: false,
      initialValues: [],
      getLoad: false,
      loading: false,
      hours: [
        "8:00 - 16:00",
        "8:30 - 16:30",
        "9:00 - 17:00",
        "9:00 - 18:00",
        "10:00 - 18:00",
      ],
    };
  },
  computed: {
    validationResults() {
      return {
        isDescriptionInvalid:
          this.form.description.trim() ===
          this.form.hiddenAdvertDescription.trim(),
        isActiveAdvertDescriptionInvalid:
          this.form.activeAdvertDescription.trim() ===
          this.form.hiddenAdvertDescription.trim(),
        isHiddenAdvertDescriptionInvalid:
          this.form.hiddenAdvertDescription.trim() ===
            this.form.description.trim() ||
          this.form.hiddenAdvertDescription.trim() ===
            this.form.activeAdvertDescription.trim(),
      };
    },
  },
  methods: {
    setDefaultService(val) {
      this.form.defaultProvide = val;
    },
    chanceStorage(val) {
      if (val <= 1 && val != 0) {
        this.form.storageTimeReminder = "";
      }
    },
    sectorChange(val) {
      this.form.sector = val;
    },
    onFileSelectedLogo(val) {
      this.form.deletedList.logo = val == 2 ? 2 : 1;
      this.form.logo = val == 2 ? null : val;
    },
    onFileSelectedFormat(val) {
      this.form.deletedList.format = val == 2 ? 2 : 1;
      this.form.format = val == 2 ? null : val;
    },
    onFileConsentStatement(val) {
      this.form.deletedList.consentStatement = val == 2 ? 2 : 1;
      this.form.consentStatementUrl = val == 2 ? null : val;
      this.form.consentStatementUrl = val == 2 ? null : val;
    },

    async getAll() {
      try {
        this.getLoad = true;
        const res = await this.axios.get(settingGeneral.getAll);
        const {
          name,
          personalNumber,
          workingHours,
          description,
          logoUrl,
          formatUrl,
          consentStatementUrl,
          sector,
          hiddenAdvertDescription,
          activeAdvertDescription,
          cvStorageTime,
          storageTimeReminder,
          defaultProvide,
        } = res.data.data;
        this.form = {
          ...this.form,
          name: name || "",
          personalNumber: personalNumber || "",
          hiddenAdvertDescription: hiddenAdvertDescription || "",
          activeAdvertDescription: activeAdvertDescription || "",
          description: description || "",
          cvStorageMonth: cvStorageTime || "",
          storageTimeReminder: storageTimeReminder || "",
          defaultProvide: defaultProvide || "",
          logo: logoUrl || "",
          format: formatUrl || "",
          consentStatementUrl: consentStatementUrl || "",
          sector: sector || {},
        };

        const findIndex = this.hours.findIndex((r) => r == workingHours);
        if (findIndex > -1) {
          this.form.workingHours = workingHours;
        } else if (workingHours) {
          this.form.workingHours = 1;
          if (typeof workingHours === "string") {
            const dates = workingHours.split("-");
            this.form.startWorking = dates[0].trim();
            this.form.endWorking = dates[1] ? dates[1].trim() : "";
          }
        }

        this.initialValues = _.cloneDeep(this.form);
      } catch (err) {
        console.log(err);
        this.authController(err);
      } finally {
        this.getLoad = false;
      }
    },
    save() {
      this.loading = true;
      const form = this.form;
      const validations = this.validationResults;

      const isValid =
        form.name &&
        form.sector &&
        form.sector.id &&
        form.personalNumber &&
        form.workingHours &&
        form.description &&
        form.defaultProvide &&
        !validations.isHiddenAdvertDescriptionInvalid &&
        !validations.isActiveAdvertDescriptionInvalid &&
        !validations.isDescriptionInvalid;

      if (isValid) {
        const formData = new FormData();
        formData.append("name", this.form.name);
        formData.append("sector", this.form.sector.id);
        formData.append("logo", this.form.logo);
        formData.append("cvFormatFile", this.form.format);
        formData.append("consentStatementFile", this.form.consentStatementUrl);
        formData.append("personalNumber", this.form.personalNumber);
        formData.append(
          "hiddenAdvertDescription",
          this.form.hiddenAdvertDescription
        );
        formData.append(
          "activeAdvertDescription",
          this.form.activeAdvertDescription
        );
        formData.append("cvStorageTime", this.form.cvStorageMonth);
        formData.append("storageTimeReminder", this.form.storageTimeReminder);
        formData.append(
          "workingHours",
          this.form.workingHours == 1
            ? this.form.startWorking + " - " + this.form.endWorking
            : this.form.workingHours
        );
        formData.append("description", this.form.description);
        formData.append("defaultProvide", this.form.defaultProvide);
        formData.append("deletedList", JSON.stringify(this.form.deletedList));

        this.axios
          .post(settingGeneral.edit, formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.getAll();
            this.$store.commit("setCompanyName", form.name);
          })
          .catch((err) => {
            this.authController(err);
          })
          .finally(() => {
            this.loading = false;
            this.isDataChanged = false;
          });
      } else {
        this.loading = false;
        let errorMessage = this.$t("company.components.add.alertMessage");
        if (validations.isHiddenAdvertDescriptionInvalid) {
          errorMessage = this.$t(
            "settings.general.index.isHiddenAdvertDescriptionInvalid"
          );
        } else if (validations.isActiveAdvertDescriptionInvalid) {
          errorMessage = this.$t(
            "settings.general.index.isActiveAdvertDescriptionInvalid"
          );
        } else if (validations.isDescriptionInvalid) {
          errorMessage = this.$t("settings.general.index.isDescriptionInvalid");
        }

        this.authController(errorMessage);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    let formCopy = { ...this.form };
    let initialValuesCopy = { ...this.initialValues };

    if (formCopy.sector && initialValuesCopy.sector) {
      if (
        formCopy.sector.id !== undefined &&
        initialValuesCopy.sector[0].sectorId !== undefined
      ) {
        formCopy.sector = formCopy.sector.id;
        initialValuesCopy.sector = initialValuesCopy.sector[0].sectorId;
      }
    }

    if (!this.getLoad && !_.isEqual(formCopy, initialValuesCopy)) {
      this.verifiedMessage(
        this.$t("settings.general.index.leaveMessage"),
        this.$t("settings.general.index.leaveMessageYes"),
        this.$t("settings.general.index.leaveMessageNo")
      ).then((res) => {
        if (res.isConfirmed) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  created() {
    this.getAll();
  },
};
</script>

<style>
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-4px);
  }

  40% {
    transform: translateX(4px);
  }

  60% {
    transform: translateX(-4px);
  }

  80% {
    transform: translateX(4px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
