<template>
  <div class="w-full">
    <div class="flex gap-24 py-8 border-b border-gray-200 max-md:flex-wrap">
      <div class="w-6/12 max-md:w-full">
        <h1 class="text-base text-gray-700 font-semibold">

        {{
          $t('project.projectUser.components.shortList.components.steps.personalInformation.selectPersonalInformationTitle')
        }}</h1>
      <p class="text-xs text-gray-700 mt-2">
        {{
            $t('project.projectUser.components.shortList.components.steps.personalInformation.selectPersonalInformationDescription')
          }}</p>

      <button type="button" class="text-base block mt-4 bgcol px-8 py-2 rounded text-white" @click="openModal('add')">
        {{ $t("project.projectUser.components.shortList.components.steps.personalInformation.new") }}
      </button>
    </div>
    <div class="w-6/12 h-full max-md:w-full flex flex-col gap-4">

      <div class="w-full h-full grid grid-cols-1 gap-4 ">
        <div v-for="(item, index) in personalInformations" :key="index"
          class="border-b border-gray-100 w-full flex gap-3 pb-4">
          <div class="w-10/12">
            <h2 class="text-base text-gray-800 font-semibold">
              {{ item.title }}
            </h2>
            <p class="text-sm text-gray-700">{{ item.description }}</p>
          </div>
          <div class="w-2/12 flex text-sm">
            <i class="fa-solid fa-pen-to-square text-base cursor-pointer ml-8 max-sm:ml-0" @click="openModal('edit', item)
          ">
            </i>
            <i class="fa-solid fa-trash-can text-base ml-4 cursor-pointer" @click="deleteItem(item)"></i>
          </div>
        </div>

        <div v-if="personalInformations.length == 0 && !load" class="text-center text-sm mt-10 text-gray-700">
          {{ $t("project.projectUser.components.shortList.components.steps.personalInformation.informationNotFound") }}
        </div>
        <div v-if="load" class="text-center text-sm mt-10 text-gray-700">
          <i class="fas fa-spinner fa-spin"></i>
          {{ $t("general.loading") }}
        </div>
      </div>
    </div>
</div>
    <!-- modal -->


<personalInformationModal ref="personalInformationModal" @success="fetchPersonalInformations" />

</div>
</template>

<script>
import personalInformationModal from "./personalInformationModal.vue";
import { settingGeneral } from "@/networking/urlmanager";

export default {
  name: "PersonalInformationSettings",

  data() {
    return {
      personalInformations: [],
      modalType: 'save',
      load: false,
    };
  },
  components: {
    personalInformationModal,
  },
  created() {
    this.fetchPersonalInformations();
  },

  methods: {
    async fetchPersonalInformations() {
      try {
        this.load = true;
        const response = await this.axios.get(settingGeneral.getPersonalInformationTypes);
        this.personalInformations = response.data.data;
      } catch (error) {
        console.error("Error fetching personal information types:", error);
      } finally {
        this.load = false;
      }
    },


    openModal(type = 'add', item = null) {

      this.$refs.personalInformationModal.show(type, item);
    },

    async deleteItem(item) {
      try {
        const data = await this.verifiedMessage();
        if (data.isConfirmed) {
          await this.axios.post(settingGeneral.deletePersonalInformationTypes, {
            id: item.id,
          });
          await this.fetchPersonalInformations();
        }
      } catch (err) {
        console.log(err);
        this.authController(err);
      }
    },
 
  },

};
</script>

