<template>
  <div>
    <div v-if="loading">
      <div class="flex justify-center items-center h-52">
        <div
          class="w-8 h-8 rounded-full animate-spin border-2 border-solid border-slate-800 border-t-transparent shadow-md">
        </div>
      </div>
    </div>

    <div v-else class="">
      <div class="">
        <div class="pb-4 rounded">
          <h2 class="text-gray-700 flex items-center font-medium border-b border-gray-200">
            <span class="w-3/12">
              {{
      $t(
        "project.projectUser.components.shortList.components.meetModal.candidateName"
      )
    }}</span>
            <span class="font-normal w-9/12 border-l py-5 pl-5 border-gray-200 h-full">
              {{
        candidateName ||
        $t(
          "project.projectUser.components.shortList.components.meetModal.noInfo"
        )
      }}
            </span>
          </h2>
          <h2 class="text-gray-700 flex items-center font-medium border-b border-gray-200">
            <span class="w-3/12">{{
        $t(
          "project.projectUser.components.shortList.components.meetModal.meetDate"
        )
      }}</span>
            <span class="font-normal w-9/12 border-l py-5 pl-5 border-gray-200 h-full">
              {{
        meetingDate ||
        $t(
          "project.projectUser.components.shortList.components.meetModal.noInfo"
        )
      }}
            </span>
          </h2>
          <h2 v-if="detail.stateId == 4" class="text-gray-700 flex items-center font-medium border-b border-gray-200">
            <span class="w-3/12">
              {{
      $t(
        "project.projectUser.components.shortList.components.meetModal.explanation"
      )
    }}</span>
            <span class="font-normal w-9/12 border-l py-5 pl-5 border-gray-200 h-full">
              {{
        description ||
        $t(
          "project.projectUser.components.shortList.components.meetModal.noInfo"
        )
      }}
            </span>
          </h2>
          <h2 class="text-gray-700 flex items-center font-medium">
            <span class="w-3/12">{{
        $t(
          "project.projectUser.components.shortList.components.meetModal.meetLink"
        )
      }}</span>

            <span class="font-normal w-9/12 border-l py-5 pl-5 border-gray-200 h-full">
              <a v-if="meetLink" :href="meetLink" target="_blank" class="underline text-slate-700">
                Meet Link
              </a>
              <span v-else>{{
      $t(
        "project.projectUser.components.shortList.components.meetModal.noLink"
      )
    }}
              </span>
            </span>
          </h2>
        </div>
        <div class="w-full">
          <buttonItem v-if="!meetLink && detail.stateId != 4" :load="generatingMeetLoad" @click="generateMeet" :text="generatingMeetLoad
        ? $t(
          'project.projectUser.components.shortList.components.meetModal.generatingMeet'
        )
        : $t(
          'project.projectUser.components.shortList.components.meetModal.createMeet'
        )
      " buttonClass="w-full" />
          <buttonItem v-if="isResend" :load="generatingMeetLoad" @click="generateMeet" :text="generatingMeetLoad
        ? $t(
          'project.projectUser.components.shortList.components.meetModal.generatingMeet'
        )
        : $t(
          'project.projectUser.components.shortList.components.meetModal.createMeet'
        )
      " buttonClass="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import buttonItem from "@/components/button.vue";
import {
  technicalInterviewGoogle,
  technicalInterviewMicrosoft,
} from "@/networking/urlmanager";

export default {
  name: "meetModal",
  props: ["detail",],
  components: {
    buttonItem,
  },
  data() {
    return {
      generatingMeetLoad: false,
      meetLink: "",
      candidateName: "",
      meetingDate: "",
      loading: false,
      description: "",
      defaultService: this.$store.state.integration.defaultService,
      isResend: false
    };
  },
  methods: {
    checkOutlookCredentials() {
      const vuexState = JSON.parse(localStorage.getItem("vuex"));
      const microsoftToken = vuexState.integration?.microsoft?.token;
      const microsoftRefreshToken =
        vuexState.integration?.microsoft?.refreshToken;
      const microsoftDate = vuexState.integration?.microsoft?.date;

      return {
        microsoftToken,
        microsoftDate,
        microsoftRefreshToken,
        credentialsExist:
          microsoftToken &&
          microsoftDate &&
          moment(microsoftDate).diff(moment(), "minutes") > 0,
      };
    },
    checkGoogleCredentials() {
      const vuexState = JSON.parse(localStorage.getItem("vuex"));
      const googleToken = vuexState.integration.google.token;
      const googleRefreshToken = vuexState.integration.google.refreshToken;
      const googleDate = vuexState.integration.google.date;

      return {
        googleToken,
        googleRefreshToken,
        credentialsExist:
          googleToken &&
          googleDate &&
          moment(googleDate).diff(moment(), "minutes") > 0,
      };
    },

    async generateMeet() {
      try {

        this.generatingMeetLoad = true;
        if (this.defaultService == 1) {
          const outlookData = this.checkOutlookCredentials();

          if (outlookData.credentialsExist) {

            const response = await this.axios.post(
              technicalInterviewMicrosoft.meetGenerate,
              {
                access_token: outlookData.microsoftToken,
                refresh_token: outlookData.microsoftRefreshToken,
                meetCount: this.detail.meetCount,
                cvId: this.detail.cvId,
                projectId: this.detail.projectId,
                applicantMail: this.detail.email,
                projectName: this.$route.query.projectName,
                interviewId: this.$store.state.interviewId,
              }
            );

            this.meetJson = response.data;
            this.generatingMeetLoad = false;
            this.getMeetDetail();
            this.$emit("refresh", true);
            this.$nextTick(() => {
          this.isResend = false
        });
          } else {
            this.authorize();
            this.generatingMeetLoad = false;
          }
        } else if (this.defaultService == 2) {
          const googleData = this.checkGoogleCredentials();
          if (googleData.credentialsExist) {
            const email =
              this.detail.contactEmail.match(/[\w.-]+@[\w.-]+\.\w+/)[0];
            const response = await this.axios.post(
              technicalInterviewGoogle.meetGenerate,
              {
                access_token: googleData.googleToken,
                refresh_token: googleData.googleRefreshToken,
                meetCount: this.detail.meetCount,
                cvId: this.detail.cvId,
                projectId: this.detail.projectId,
                applicantMail: email,
                projectName: this.$route.query.projectName,
                interviewId: this.$store.state.interviewId,
              }
            );

            this.meetJson = response.data;
            this.generatingMeetLoad = false;
            this.getMeetDetail();
            this.$emit("refresh", true);
            this.$nextTick(() => {
          this.isResend = false
        });
          } else {
            this.authorize();
            this.generatingMeetLoad = false;
          }
        } else {
          console.error("Geçersiz hizmet seçimi");
          this.generatingMeetLoad = false;
        }
      } catch (err) {
        this.generatingMeetLoad = false;
        this.authController(err);
        console.error(err);
      } finally {
      
      }
    },

    authorize() {
      this.authorizeMixin();
    },

    async getMeetDetail() {
      this.loading = true;
      try {
        const res = await this.axios.get(
          technicalInterviewGoogle.getMeetDetails,
          {
            params: {
              interviewId: this.$store.state.interviewId,
            },
          }
        );
        const { date, name, url } = res.data.data;
        this.meetingDate = moment(date).format("LLL");
        this.candidateName = name
        this.meetLink = url;
        this.loading = false;
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },
  },
  mounted() {
    this.getMeetDetail()

  }
};
</script>
