<template>
  <div :class="containerClass">
    <div class="flex flex-col items-center justify-center">
      <i class="fas fa-spinner fa-spin" :style="{ fontSize: size }"></i>
    </div>
    <p class="mt-3 text-gray-600">{{ text || $t('general.loading') }}</p>
  </div>
</template>

<script>
export default {
  name: "CustomLoading",
  props: {

    size: {
      type: String,
      default: "2rem"
    },
    text: {
      type: String,
      default: ""
    },
    containerClass: {
      type: String,
      default: "flex flex-col items-center justify-center"
    }
  }
};
</script>

