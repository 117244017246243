<template>
    <div id="chart" class="pb-5">
        <apexchart ref="chart" type="bar" height="480" :options="{
            ...chartOptions,
        }" :series="series"></apexchart>
    </div>
</template>


<script>
import { general } from "@/networking/urlmanager";

export default {
    name: "PositionChart",

    data() {
        return {

            series: [{
                data: [0, 0, 0]
            }],
            error: null,
            loading: false,

        };
    },

    computed: {
        chartOptions() {
            return {
                chart: {
                    type: 'bar',
                    height: 350
                },
                title: {
                    text: this.$t("general.components.filledPositionChart.filledPositionChart"),
                    align: "left",
                    style: {
                        fontSize: "14px",
                        fontWeight: "600",
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                colors: ["#5070b6"],
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [''
                    ],
                }
            };
        }
    },


    methods: {
        async fetchPositionData() {
            try {
                this.loading = true;
                const response = await this.axios.get(general.getFilledPositionAll);
                const data = response?.data?.data;
                this.series = [{
                    data: data.map(item => item.totalHired) // Adjust based on your actual data structure
                }];
                this.categories = data.map(item => item.positionName);
                this.chartOptions.xaxis = {
                    categories: this.categories
                };

            } catch (error) {
                console.error("Failed to fetch position data:", error);
            } finally {
                this.loading = false;
            }
        },

        updateChartOnLocaleChange() {
            if (!this.$refs.chart) return;

            const updatedOptions = {
                title: {
                    text: this.$t("general.components.filledPositionChart.filledPositionChart")
                },
                xaxis: {
                    categories: this.categories
                }
            }

            this.chartOptions.title.text = updatedOptions.title.text;
            this.chartOptions.xaxis.categories = updatedOptions.xaxis.categories;

        }


    },

    watch: {
        "$i18n.locale": {
            handler() {
                this.updateChartOnLocaleChange();
            },
            immediate: true
        }
    },

    async created() {
        await this.fetchPositionData();
    }
};
</script>