<template>
  <Teleport to="body">
    <transition name="fade">
      <div v-if="value" class="fixed inset-0 z-[9998] flex items-center justify-center p-4" >
        <!-- Backdrop overlay -->
        <div class="absolute inset-0 bg-slate-900/50 backdrop-blur-sm" @click.stop="closePopupOutside"></div>
        
        <!-- Popup modal -->
        <transition :name="animationType">
          <div 
            class="relative flex flex-col w-full max-h-[90vh] overflow-hidden rounded-lg shadow-xl z-[9999]"
            :class="[
              sizeClasses[size],
              colorVariants[variant].card,
              borderVariants[variant]
            ]"
            @click.stop
          >
            <!-- Header -->
            <div 
              class="flex items-center justify-between border-b p-2 px-4 !bg-cvus-primary "
              :class="[
                colorVariants[variant].header,
                borderVariants[variant],
              ]"
            >
              <slot name="title">
                <h3 
                  class="font-semibold m-0 !text-white"
                  :class="[
                    colorVariants[variant].text,
                    { 'text-base': size === 'sm', 'text-xl': size === 'md', 'text-2xl': size === 'lg' }
                  ]"
                >
                  {{ title }}
                </h3>
              </slot>
              
              <button 
                v-if="showCloseButton"
                @click="closePopup" 
                class="flex items-center justify-center rounded-full hover:bg-slate-100/10 transition-colors"
                :class="[
                  { 'h-6 w-6': size === 'sm', 'h-8 w-8': size === 'md', 'h-10 w-10': size === 'lg' }
                ]"
              >
                <Icon 
                  icon="mdi:close" 
                  :class="[
                    colorVariants[variant].text,
                    { 'text-base': size === 'sm', 'text-lg': size === 'md', 'text-xl': size === 'lg' }
                  ]"
                  class="!text-white"
                />
              </button>
            </div>
            
            <!-- Body content with scroll -->
            <transition name="body-scale" :appear="animateContent">
              <div 
                class="flex-1 overflow-y-auto p-2"
                :class="[
                  colorVariants[variant].body
                ]"
              >
                <slot></slot>
              </div>
            </transition>
            
            <!-- Footer -->
            <div 
              v-if="$slots.footer" 
              class="border-t flex items-center justify-end gap-2 p-2 px-4"
              :class="[
                colorVariants[variant].footer,
                borderVariants[variant]
              ]"
            >
              <slot name="footer"></slot>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import Teleport from 'vue2-teleport'

export default {
  name: 'CustomPopup',
  components: {
    Teleport
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md', 'lg', 'xl', 'full'].includes(value)
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'primary', 'success', 'warning', 'danger', 'info', 'dark'].includes(value)
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    animationType: {
      type: String,
      default: 'bounce',
      validator: (value) => ['popup', 'slide-up', 'slide-down', 'slide-left', 'slide-right', 'bounce'].includes(value)
    },
    animateContent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sizeClasses: {
        sm: 'max-w-md',
        md: 'max-w-lg',
        lg: 'max-w-2xl',
        xl: 'max-w-4xl',
        full: 'max-w-full h-[90vh]'
      },
      colorVariants: {
        default: {
          card: 'bg-white',
          header: 'bg-gray-50',
          body: 'bg-white',
          footer: 'bg-gray-50',
          text: 'text-gray-800'
        },
        primary: {
          card: 'bg-white',
          header: 'bg-blue-50',
          body: 'bg-white',
          footer: 'bg-blue-50',
          text: 'text-blue-700'
        },
        success: {
          card: 'bg-white',
          header: 'bg-green-50',
          body: 'bg-white',
          footer: 'bg-green-50',
          text: 'text-green-700'
        },
        warning: {
          card: 'bg-white',
          header: 'bg-yellow-50',
          body: 'bg-white',
          footer: 'bg-yellow-50',
          text: 'text-yellow-700'
        },
        danger: {
          card: 'bg-white',
          header: 'bg-red-50',
          body: 'bg-white',
          footer: 'bg-red-50',
          text: 'text-red-700'
        },
        info: {
          card: 'bg-white',
          header: 'bg-cyan-50',
          body: 'bg-white',
          footer: 'bg-cyan-50',
          text: 'text-cyan-700'
        },
        dark: {
          card: 'bg-gray-800',
          header: 'bg-gray-900',
          body: 'bg-gray-800',
          footer: 'bg-gray-900',
          text: 'text-white'
        }
      },
      borderVariants: {
        default: 'border-gray-200',
        primary: 'border-blue-200',
        success: 'border-green-200',
        warning: 'border-yellow-200',
        danger: 'border-red-200',
        info: 'border-cyan-200',
        dark: 'border-gray-700'
      }
    }
  },
  methods: {
    closePopupOutside() {
      if (this.closeOnClickOutside) {
       this.closePopup()
      }
    },
    closePopup() {
      this.$emit('input', false)
      this.$emit('close')
    },
    handleKeydown(e) {
      if (e.key === 'Escape' && this.value) {
        this.closePopupOutside()
      }
    }
  },
  mounted() {
    // Add ESC key listener to close popup
    document.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    // Clean up event listener
    document.removeEventListener('keydown', this.handleKeydown)
  },
  watch: {
    value(newVal) {
      console.log("popup value", newVal)
    }
  }
}
</script>

<style scoped>
/* Fade transition for backdrop */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Scale transition for popup */
.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.popup-enter,
.popup-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

/* Body content scale animation */
.body-scale-enter-active {
  transition: opacity 0.3s ease-out 0.15s, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.15s;
}

.body-scale-leave-active {
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
}

.body-scale-enter, 
.body-scale-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

/* Slide Up transition */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.slide-up-enter,
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* Slide Down transition */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

/* Slide Left transition */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.slide-left-enter,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* Slide Right transition */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.slide-right-enter,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

/* Bounce transition */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

/* Scrollbar Styling */
.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
</style>