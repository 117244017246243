<template>
  <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 max-sm:my-8">
  

    <addUser ref="addUser" @refresh="getAll()" />
    <editUser ref="editUser" @refresh="getAll()" :detail="selectedItem" />

    <customTable 
      :items="List" 
      :columns="tableColumns" 
      :loading="load"
      :current-page="currentPage"
      :page-count="pageCount"
      :total-count="totalCount"
      :size="'sm'"
      @page-change="handlePageChange"
      @row-click="handleRowClick"
    >
      <template #header>
        <div class="flex items-center justify-between w-full">
          <div class="flex gap-5 max-sm:flex-col">
      <div>
        <button
          class="px-8 py-2 h-11 text-sm max-sm:w-[182px] text-white rounded bg-cvus-gradient"
          @click="$refs.addUser.show()"
          data-v-step="0"
        >
          <i class="fas fa-plus mr-2"></i>
          {{ $t("user.index.add") }}
        </button>
      </div>
    </div>
          <div class="w-[200px] tableSearch relative ml-auto border-none outline-none">
            <input
              v-model="search"
              @keypress.enter="getSearch()"
              type="text"
              class="h-full w-full outline-none border-2 border-cvus-primary shadow-cvus-primary/25 shadow-md focus:shadow-cvus-primary/50 focus:!border-cvus-primary-light2 pl-3 placeholder:textcol font-light rounded-md text-black pr-16"

              :placeholder="$t('user.index.table.search')"
            />
            <button class="px-3 textcol absolute right-0">
              <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </template>
      <template #fullName="{ item }">
        <customTextWrapper 
          :text="item.firstName + ' ' + item.lastName" 
          :maxLength="18" 
          :top="true"
        />
      </template>
      <template #actions="{ item }">
        <div class="flex gap-4 justify-end">
          <button
            data-v-step="2"
            @click="() => ((selectedItem = item), $refs.editUser.show())"
            class="flex items-center h-full"
          >
            <div class="relative group flex justify-center">
              <i class="fa-solid fa-pen-to-square text-base"></i>
              <tooltip
                :title="$t('user.index.table.editTooltip')"
                tipClass="z-50 !top-6 !w-[130px]"
                :top="true"
              />
            </div>
          </button>
          <button
            data-v-step="3"
            @click="deleteItem(item)"
            class="flex items-center h-full"
          >
            <div class="relative group flex justify-center">
              <i class="fa-solid fa-trash-can text-base"></i>
              <tooltip
                :title="$t('user.index.table.deletTooltip')"
                tipClass="z-50 !top-6 !w-[130px] -right-7"
                :top="true"
                rotateClass="ml-[58px]"
              />
            </div>
          </button>
        </div>
      </template>
    </customTable>

    <tour
      :steps="steps"
      :moduleId="1"
      v-if="!$store?.state?.userData?.tourModuleId?.includes(1)"
    />

  </div>
</template>

<script>
import addUser from "./components/addUser.vue";
import editUser from "./components/editUser.vue";
import customTable from "@/components/customTable.vue";
import { users } from "@/networking/urlmanager";
import { userstour } from "@/tourmodel/tour.js";
import moment from "moment";
import "moment/locale/tr";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
import customTextWrapper from "@/components/customTextWrapper.vue";
export default {
  name: "users-page",
  components: {
    addUser,
    editUser,
    tour,
    tooltip,
    customTable,
    customTextWrapper,
  },
  data() {
    return {
      steps: userstour,
      load: false,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      rowId: 0,
      selectedItem: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        authority: "",
      },
      List: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'index',
          label: '#',
          width: '80px',
          format: (_, item, index) => this.currentPage * 15 + (index + 1)
        },
        {
          key: 'fullName',
          label: this.$t("user.index.table.name"),
          width: '200px',
          clickable: true,
          format: (_,item) => {
            return this.formatText(item?.firstName + " " + item?.lastName)
          }

        },
        {
          key: 'authority',
          label: this.$t("user.index.table.authority"),
          width: '120px',
          format: (value) => value == 1 ? this.$t("user.index.table.executive") : this.$t("user.index.table.user")
        },
        {
          key: 'email',
          label: this.$t("user.index.table.email"),
          width: '200px'
        }
      ];
    }
  },
  methods: {
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(
          users.getAll + "?page=" + this.currentPage + "&search=" + this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              users.delete,
              {
                rowId: item.id,
                fullname: item.firstName + " " + item.lastName,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getAll();
    },
    handleRowClick({ item }) {
      this.selectedItem = item;
      this.$refs.editUser.show();
    }
  },
  created() {
    this.getAll();
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return $t("noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
