var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"w-full relative flex flex-col mt-4",class:[_vm.variant == 'secondary' ? '!mt-0' : '', _vm.tableClass]},[_c('div',{staticClass:"flex w-ful items-center gap-4 flex-wrap my-2",class:_vm.variant == 'secondary' ? '!my-0' : ' bg-white/50 rounded-md p-2 border border-slate-200 shadow-md'},[_vm._t("header")],2),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"overflow-x-auto scrolltype2 flex flex-col rounded-sm shadow-lg",on:{"scroll":_vm.handleTableScroll}},[_c('table',[_c('thead',{staticClass:"sticky top-0 z-[35]"},[_c('tr',{staticClass:"flex items-center px-8 overflow-hidden bg-white/50 backdrop-blur-sm border h-auto relative border-slate-200 rounded-t-md text-slate-900 shadow-sm",class:_vm.size == 'sm' ? 'min-h-[40px] text-xs' : _vm.size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base'},[_vm._l((_vm.columns),function(column,index){return _c('th',{key:index,staticClass:"text-left p-2 tableSpacing w-full items-center flex-grow h-auto flex flex-shrink-0",class:[
                  column.align === 'center' && 'text-center justify-center', 
                  column.align === 'right' && 'text-right justify-end',
                  (column.sticky && _vm.scrollLeft > _vm.getLeftPosition(index)) && 'sticky bg-white border-r h-full border-slate-200  z-10'
                ],style:({
                  minWidth: column.minWidth || column.width || '120px', 
                  maxWidth: column.minWidth ? ' auto' : (column.width || '120px'),
                  left: (column.sticky && _vm.scrollLeft > _vm.getLeftPosition(index)) ? _vm.scrollLeft + _vm.getStickyPosition(index) - 32 + 'px' : 'auto',
                })},[(column.sortable)?[_c('button',{staticClass:"flex items-center h-full gap-1 group",on:{"click":function($event){return _vm.handleSort(column.key)}}},[_c('p',[_vm._v(_vm._s(column.label))]),(_vm.selectedSortKey === column.key)?_c('Icon',{attrs:{"icon":_vm.sortType === 'asc' ? 'mdi:arrow-up' : _vm.sortType === 'desc' ? 'mdi:arrow-down' : 'mdi:arrow-up-down'}}):_c('Icon',{staticClass:"opacity-0 group-hover:opacity-50",attrs:{"icon":'mdi:arrow-up-down'}})],1)]:(column.selectAll)?[_c('customCheckbox',{attrs:{"label":column.label},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]:[_vm._v(" "+_vm._s(column.label)+" ")]],2)}),(_vm.isActions)?_c('th',{staticClass:"flex h-full",style:({ width: _vm.actionWidth + 'px', minWidth: _vm.actionWidth + 'px' })},[_c('div',{staticClass:"h-full flex w-full"})]):_vm._e()],2)]),_c('div',{staticClass:"rounded-b-sm",style:({
            height: _vm.height || '400px',
            minHeight: '400px',
            maxHeight: _vm.height || '400px',
          })},[_vm._t("loader",function(){return [_c('table-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"load":_vm.loading,"length":_vm.items.length,"colspan":_vm.columns.length}})]}),(!_vm.loading && _vm.items.length == 0)?_c('div',{staticClass:"flex w-full h-full items-center justify-center"},[_c('span',{staticClass:"text-gray-500 text-sm"},[_vm._v(_vm._s(_vm.$t("globalComponents.tableLoader.tableLoaderTitle")))])]):_vm._e(),_c('tbody',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"flex flex-col w-full h-auto"},_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,staticClass:"flex items-stretch hover:bg-gray-50 border-b w-full border-slate-50 max-w-full relative",class:_vm.size == 'sm' ? 'min-h-[40px] text-xs' : _vm.size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base',on:{"click":function($event){$event.stopPropagation();return _vm.handleRowClickAll(item)}}},[_c('div',{staticClass:"flex w-full h-full flex-col"},[_c('div',{staticClass:"flex w-full h-full items-stretch px-8"},[(_vm.isActions)?_c('div',{staticClass:"!w-[2px] sticky !z-[30] flex h-full",class:_vm.size == 'sm' ? 'min-h-[40px] text-xs' : _vm.size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base',style:({ left: `calc(100% - ${_vm.actionWidth}px)` })},[_c('div',{ref:"actionButton",refInFor:true,staticClass:"w-fit flex justify-end items-center bg-white h-full px-4 border-x border-slate-50",class:_vm.size == 'sm' ? 'min-h-[40px] text-xs' : _vm.size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base'},[_vm._t("actions",null,{"item":item,"index":index})],2)]):_vm._e(),_vm._l((_vm.columns),function(column,colIndex){return _c('td',{key:colIndex,class:[
                      'px-2 w-full flex-grow flex-shrink-0 !flex items-center h-auto ',
                      { 'cursor-pointer hover:*:underline hover:underline ': column.clickable },
                      column.align === 'center' && 'text-center justify-center',
                      column.align === 'right' && 'text-right justify-end',
                      (column.sticky && _vm.scrollLeft > _vm.getLeftPosition(colIndex)) && 'sticky bg-white shadow-[1px_0_5px_rgba(0,0,0,0.05)] z-10'
                    ],style:({
                      minWidth: column.minWidth || column.width || '120px', 
                      maxWidth: column.minWidth ? ' auto' : (column.width || '120px'),
                      left: column.sticky && _vm.scrollLeft > _vm.getLeftPosition(colIndex) ? _vm.getStickyPosition(colIndex) + 'px' : 'auto',
                    }),on:{"click":function($event){column.clickable && _vm.handleRowClick(item, column.key)}}},[_vm._t(column.key,function(){return [_vm._v(" "+_vm._s(_vm.formatColumnValue(item[column.key], column.format, item, index))+" ")]},{"item":item,"column":column,"index":index})],2)}),(_vm.isActions)?_c('td',{staticClass:"flex h-auto",style:({ width: _vm.actionWidth + 'px', minWidth: _vm.actionWidth + 'px' })},[_c('div',{staticClass:"h-auto flex w-full"})]):_vm._e()],2),(_vm.isSecondLine)?_c('div',{staticClass:"flex w-full h-full items-center"},[_vm._t("secondLine",null,{"item":item,"index":index})],2):_vm._e()])])}),0)],2)])])])]),_vm._t("pagination",function(){return [(_vm.showPagination)?_c('pagination',{attrs:{"pageCount":_vm.pageCount,"totalRowCount":_vm.totalCount},model:{value:(_vm.currentPageSync),callback:function ($$v) {_vm.currentPageSync=$$v},expression:"currentPageSync"}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }