<template>
  <modal name="advertModal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '50%'" :scrollable="true"
    class="scrolltype2 overflow-y-auto" @closed="hide()">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h2 class="text-lg">{{ $t("project.components.advert.title") }}</h2>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="p-4 scrolltype2 overflow-y-auto">
      <!-- Body -->
      <loadingDocument :isLoading="getLoad" />

      <form @submit.prevent="save()" v-if="!getLoad" class=" py-4 px-2 flex flex-col justify-between h-[500px]">
        <div
          class="w-full h-full text-xs ">
          <VueEditor class="" v-model="explanation" id="quill-custom"></VueEditor>
        </div>
        <!-- <customInput
          v-model="explanation"
          type="textarea"
          :placeholder="'İlan Açıklaması'"
          :rows="20"
          textareaClass="w-full  border p-2 rounded resize-none text-xs"
        /> -->

        <buttonItem :load="load" containerClass="mt-4 mb-2" />
      </form>
    </div>
  </modal>
</template>
<script>
import { project } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";
import loadingDocument from "@/components/loadingDocument.vue";
// import customInput from "@/components/customInput.vue";
import { VueEditor } from "vue2-editor";

export default {
  name: "advertModal",
  props: ["rowId", "isEdit"],
  components: {
    buttonItem,
    loadingDocument,
    VueEditor,
    // customInput,
  },
  data() {
    return {
      explanation: "",
      load: false,
      getLoad: false,
    };
  },
  methods: {
    show() {
      setTimeout(async () => {
        if (this.isEdit != 1) {
          await this.getDetail();
        } else {
          await this.generate();
        }
      }, 50);
      this.$modal.show("advertModal");
    },
    hide() {
      this.$modal.hide("advertModal");
    },

    async getDetail() {
      try {
        this.getLoad = true;
        const result = await this.axios.get(
          project.advert.getDetail + "?rowId=" + this.rowId
        );

        this.explanation = result.data.data?.advert;
      } catch (error) {
        console.log(error);
        // hata mesajı verilemdi
      } finally {
        this.getLoad = false;
      }
    },
    async generate() {
      try {
        this.getLoad = true;
        const result = await this.axios.post(project.advert.generate, {
          rowId: this.rowId,
        });

        this.explanation = result.data.data;
      } catch (error) {
        console.log(error);
        // hata mesajı verilemdi
      } finally {
        this.getLoad = false;
      }
    },

    async save() {
      try {
        this.load = true;
        await this.axios
          .post(project.advert.save, {
            description: this.explanation,
            rowId: this.rowId,
          })
          .then(() => {
            this.successMessage(
              this.$t("project.components.advert.successMessage")
            );
            this.$emit("refresh", true);
            this.hide();
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
<style >
#quill-custom{
  height: 320px;
}
</style>