<template>
  <div
    class="my-10 w-full max-sm:my-14 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"
  >
    <div class="flex justify-between items-center">
      <div class="relative flex gap-4">
        <div class="gods relative ml-[15px]">
          <img src="@/assets/profile/profile.png" alt="" />
        </div>
        <div>
          <h1 class="text-2xl textcol font-bold leading-9">
            {{ this.$store.state.userData.firstName }} <br />
            <span>{{ this.$store.state.userData.lastName }}</span>
          </h1>

          <button
            class="flex items-center text-lg gap-2 absolute bottom-1 textcol"
          >
            <img src="@/assets/profile/useredit.svg" alt="" class="w-6" />
            {{ $t("profile.index.edit") }}
          </button>
        </div>
      </div>

      <div class="max-sm:hidden">
        <img src="@/assets/profile/profileRight.svg" alt="" />
      </div>
    </div>

    <div class="mx-auto">
      <div
        class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 border-t-2 border-slate-800 mt-14"
      >
        <setCard
          :img="require('@/assets/profile/passwordcheck.svg')"
          :titleOne="$t('profile.index.password')"
          :titletwo="$t('profile.index.change')"
          superdiv="mt-5"
        />
        <setCard
          :img="require('@/assets/profile/emptywallet.svg')"
          :titleOne="$t('profile.index.payment')"
          :titletwo="$t('profile.index.settings')"
          superdiv="mt-5 max-md:border-0"
        />
        <setCard
          :img="require('@/assets/profile/stickynote.svg')"
          :titleOne="$t('profile.index.registered')"
          :titletwo="$t('profile.index.note')"
          superdiv="mt-5"
        />
        <setCard
          :img="require('@/assets/profile/like1.svg')"
          :titleOne="$t('profile.index.registered')"
          :titletwo="$t('profile.index.cv')"
          superdiv="!border-0 mt-5"
        />
      </div>
    </div>
  </div>
</template>
<script>
import setCard from "./components/setCard.vue";
export default {
  name: "profile-page",
  components: {
    setCard,
  },
  data() {
    return {};
  },
};
</script>
<style>
.gods::before {
  content: " ";
  background-color: #344ca0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -15px;
  top: -15px;
  z-index: -1;
}
</style>
