<template>

    <modal
      name="cv-positions"
      :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '70%'"
      :scrollable="true"
    >
      <div class="flex justify-between card-header p-5 bgcol w-full rounded-t text-white">
        <h1 class="text-xl">{{ $t('cv.positions.modalTitle') }}</h1>
        <button @click="hide">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="max-h-screen overflow-y-auto p-1">
      
        <CustomTable
          :items="positions"
          :columns="columns"
          :loading="loading"
          @row-click="handleRowClick"
          :showPagination="false"
          :isActions="false"
          variant="secondary"
        >
        <template #header>
          <div class="flex w-full justify-end m-2" v-if="showAddButton">
            <cvSave :isEdit="isEdit" @refreshPositions="fetchPositions" />

        </div>
        </template>

          <template #id="{ item }">
                <span class="font-bold">#{{ item.id }}</span>
          </template>
          <!-- Custom column slots -->
          <template #position="{ item }">
            <div class="flex items-center gap-2">
              <span>{{ item.title }}</span>
            </div>
          </template>

          <template #company="{ item }">
            {{ item.company }}
          </template>

          <template #stateId="{ item }">
            <Badge :variant="getStatusVariant(item.stateId)">
              {{ $t(`positionStatus.${item.stateId}`) }}
            </Badge>
          </template>

       
        </CustomTable>
      </div>
    </modal>
</template>

<script>
import CustomTable from '@/components/customTable.vue'
import Badge from '@/components/badge.vue'
import { cv } from '@/networking/urlmanager.js'
import cvSave from "../../components/cvSave.vue"

export default {
  name: 'CvPositions',
  components: {
    CustomTable,
    Badge,
    cvSave
  },
  props: {
    isEdit:{
      default:null
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    cvId: {
      type: String,
    }
  },
  data() {
    return {
      positions: [],
      loading: false
    }
  },
  computed: {
    columns() {
      return [
        {
          key: 'id',
          label: this.$t('cv.positions.columns.id'),
          width: '130px',

        },
        {
          key: 'projectName',
          label: this.$t('cv.positions.columns.position'),
          width: '200px',
          clickable: true,

        },
        {
          key: 'companyName',
          label: this.$t('cv.positions.columns.company'),
          width: '200px',
        },
        {
          key: 'stateId',
          label: this.$t('cv.positions.columns.status'),
          width: '120px',

        }
      ]
    }
  },
  methods: {
    // Method to be called from parent via ref
    async showPositions() {
      await this.$modal.show('cv-positions')
      await this.fetchPositions()

    },
    hide() {
      this.$modal.hide('cv-positions')
    },
    async fetchPositions() {
      try {
        this.loading = true
        this.positions = []
        const response = await this.axios.get(`${cv.positions}?rowId=${this.cvId}`)
        this.positions = response.data.data
      } catch (error) {
        console.error('Error fetching positions:', error)
        this.authController(error)
      } finally {
        this.loading = false
      }
    },
    getStatusVariant(status) {
      const variants = {
        1: 'default',
        2: 'warning',
        3: 'success',
        4: 'info',
        5: 'primary',
        6: 'error',
        7: 'success',
        8: 'error',
        9: 'success',
        10: 'error',
        11: 'error'
      }
      return variants[status] || 'default'
    },
    handleRowClick({ item, key }) {
        if (key === 'projectName') {
            this.$router.push(`/project/user?id=${item.id}&projectName=${item.projectName}`)
        }
    }
  },

 
}
</script>
