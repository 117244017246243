<template>
    
    <div class="border-r-2 border-slate-800" :class="superdiv">
        <button class="w-full">
            <img :src="img" alt="" class="w-11 mx-auto">
            <h1 class="text-gray-800 text-center text-lg tracking-wide mt-3">{{ titleOne }} <br> <span>{{ titletwo }}</span>
            </h1>
        </button>
    </div>
</template>
<script>
export default {
    name: 'setCard',
    props: ["superdiv", "titleOne", "titletwo", "img"]

}
</script>