<template>
    <sideModal ref="hiredFilterPanel" modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full" :isHeader="true"
        :headerTitle="$t('hired.components.filterPanel.title')" :headerRight="true">
        <template v-slot:headerRight>
            <button @click="deleteFilter()" class="flex items-center">
                <span class="text-red-500 border-b border-red-500 text-sm mr-2">{{
                    $t("hired.components.filterPanel.reset")
                }}</span>
            </button>
        </template>

        <template v-slot:content>
            <div class="px-3 py-4">
                <form @submit.prevent="handleFilters" class="!h-full">
                    <!-- Position Filter -->
                    <div class="mb-4">

                    <slot name="body"></slot>
                    </div>
                    <div class="mb-4">

                        <!-- <customInput :placeholder="$t('hired.components.filterPanel.position')" type="select" v-model="filters.jobTitle.value"
                            :label="$t('hired.components.filterPanel.position')" 
                            :selectList="projectList"
                            /> -->
                        <customSelect :placeholder="$t('hired.components.filterPanel.position')"
                            :label="$t('hired.components.filterPanel.position')" :options="projectList"
                            v-model="filters.jobTitle.value" clearable :loading="positionLoading" />
                    </div>

                    <!-- Date Range Filter -->
                    <div class="mb-4">
                        <label class="w-full text-sm font-medium mb-1 space-between flex">
                            <span class="block w-full max-sm:px-1">
                                {{ $t("hired.components.filterPanel.hiredDate") }}
                            </span>
                        </label>
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <label class="text-xs text-gray-600">{{ $t('hired.components.filterPanel.startDate')
                                    }}</label>

                                <customInput :placeholder="$t('hired.components.filterPanel.startDate')" type="date"
                                clearable v-model="filters.hiredDate.min" />
                            </div>
                            <div>
                                <label class="text-xs text-gray-600">{{ $t('hired.components.filterPanel.endDate')
                                    }}</label>
                                <customInput :placeholder="$t('hired.components.filterPanel.endDate')" type="date"
                                    clearable v-model="filters.hiredDate.max" />
                            </div>
                        </div>
                    </div>

                    <!-- Filter Button -->
                    <buttonItem :load="load" name="Filter" buttonClass="w-full font-bold hover:bg-opacity-90 mt-3"
                        :disabled="!hasActiveFilters" />
                </form>
            </div>
        </template>
    </sideModal>
</template>

<script>

import buttonItem from "@/components/button.vue";
import customInput from "@/components/customInput.vue";
import customSelect from "@/components/customSelect.vue";
import moment from "moment";
import { hired } from "@/networking/urlmanager";

export default {
    name: "hired-filter-panel",
    components: {
        buttonItem,
        customInput,
        customSelect
    },
    data() {
        return {
            load: false,
            filters: this.initializeFilters(),
            projectList: [],
            positionLoading: false,
        };
    },
    computed: {
        hasActiveFilters() {
            const hasPosition = this.filters.jobTitle?.value ? true : false;
            const hasDateRange = this.filters.hiredDate.min || this.filters.hiredDate.max;

            return hasPosition || hasDateRange || this.search;
        },
    },
    methods: {
        initializeFilters() {
            const query = this.$route.query;

            return {
                jobTitle: {
                    name: "jobTitle",
                    value: query.positions ? JSON.parse(query.positions) : "",
                },
                hiredDate: {
                    name: "hiredDate",
                    min: query.startDate ? this.formatDateForInput(query.startDate) : "",
                    max: query.endDate ? this.formatDateForInput(query.endDate) : "",
                },
            };
        },
        formatDateForInput(date) {
            if (!date) return "";
            // Convert any date format to YYYY-MM-DD for input
            return moment(date).format('YYYY-MM-DD');
        },
        formatDateForAPI(date) {
            if (!date) return "";
            // Convert to ISO format for API
            return moment(date).format('YYYY-MM-DD');
        },
        handleFilters() {
            this.applyFilters();
        },
        applyFilters() {
            if (!this.hasActiveFilters) return;

            this.load = true;

            const filterList = [];

            // Add position filter if exists
            if (this.filters.jobTitle.value) {
                filterList.push({
                    name: "jobTitle",
                    value: this.filters.jobTitle.value
                });
            }

            // Add date range filter if exists and validate dates
            if (this.filters.hiredDate.min || this.filters.hiredDate.max) {
                let minDate = this.formatDateForAPI(this.filters.hiredDate.min);
                let maxDate = this.formatDateForAPI(this.filters.hiredDate.max);

                // If both dates are set and min is greater than max, swap them
                if (minDate && maxDate && new Date(minDate) > new Date(maxDate)) {
                    [minDate, maxDate] = [maxDate, minDate];
                    this.filters.hiredDate.min = this.formatDateForInput(minDate);
                    this.filters.hiredDate.max = this.formatDateForInput(maxDate);
                }

                filterList.push({
                    name: "hiredDate",
                    min: minDate,
                    max: maxDate
                });
            }

            // Emit the filter data to parent
            this.$emit("filterApplied", { filterList });

            this.$refs.hiredFilterPanel.isOpen = false;
            this.load = false;
        },
        async toggleSidebar() {
            if (!this.$refs.hiredFilterPanel.isOpen) {
                this.$refs.hiredFilterPanel.show();
            } else {
                this.$refs.hiredFilterPanel.close();
            }
        },
        deleteFilter() {
            this.filters = this.initializeFilters();
            this.$emit("filterReset");
            this.$refs.hiredFilterPanel.close();
        },
        async getProjects() {
            this.positionLoading = true;
            await this.axios.get(hired.getProjects).then(res => {
                this.projectList = res.data?.data?.map(item => ({
                    id: item.projectId,
                    name: `#${item.projectId} - ${item.projectName}`
                })) || []
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.positionLoading = false;
            })
        },
    },
    props: {
        search: {
            type: String,
            default: ""
        }
    },
    created() {
        // Watch for route changes to update filters
        this.$watch(
            () => this.$route.query,
            (newQuery) => {
                if (!this.$refs.hiredFilterPanel.isOpen) {
                    this.filters = this.initializeFilters();
                }
            }
        );
        this.getProjects();
    }
};
</script>
