<template>
  <div class="">
    <div class="w-full relative flex flex-col mt-4" 
      :class="[variant == 'secondary' ? '!mt-0' : '', tableClass]"
    >
      <div class="flex w-ful items-center gap-4 flex-wrap my-2 " :class="variant == 'secondary' ? '!my-0' : ' bg-white/50 rounded-md p-2 border border-slate-200 shadow-md'">
        <slot name="header">
        </slot>


      </div>

      <div class="w-full   ">
        <div class=" overflow-x-auto scrolltype2 flex flex-col rounded-sm shadow-lg  "
        @scroll="handleTableScroll"
        >
          <table>
            <!-- Header -->

            <thead class="sticky top-0 z-[35]">
              <tr
                class="flex  items-center  px-8 overflow-hidden bg-white/50 backdrop-blur-sm border h-auto relative  border-slate-200 rounded-t-md text-slate-900 shadow-sm"
                :class="size == 'sm' ? 'min-h-[40px] text-xs' : size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base'">
                <th v-for="(column, index) in columns" :key="index"
                  class="text-left p-2 tableSpacing w-full items-center flex-grow h-auto flex flex-shrink-0"
                  :class="[
                    column.align === 'center' && 'text-center justify-center', 
                    column.align === 'right' && 'text-right justify-end',
                    (column.sticky && scrollLeft > getLeftPosition(index)) && 'sticky bg-white border-r h-full border-slate-200  z-10'
                  ]"
                  :style="{
                    minWidth: column.minWidth || column.width || '120px', 
                    maxWidth: column.minWidth ? ' auto' : (column.width || '120px'),
                    left: (column.sticky && scrollLeft > getLeftPosition(index)) ? scrollLeft + getStickyPosition(index) - 32 + 'px' : 'auto',
                  }">
                  <template v-if="column.sortable">

                    <button class="flex items-center h-full gap-1 group" @click="handleSort(column.key)">
                      <p>{{ column.label }}</p>
                      <Icon :icon="sortType === 'asc' ? 'mdi:arrow-up' : sortType === 'desc' ? 'mdi:arrow-down' : 'mdi:arrow-up-down'" v-if="selectedSortKey === column.key" />
                      <Icon :icon="'mdi:arrow-up-down'" class="opacity-0 group-hover:opacity-50" v-else />
                    
                    </button>
                  </template>
                  <template v-else-if="column.selectAll">
                    <customCheckbox v-model="selectAll" :label="column.label" />
                  </template>
                  <template v-else>
                    {{ column.label }}
                  </template>
                </th>
                <!-- Action column header -->
                <th v-if="isActions" class="flex h-full"
                  :style="{ width: actionWidth + 'px', minWidth: actionWidth + 'px' }">
                  <div class="h-full flex w-full">

                  </div>
                </th>
              </tr>
            </thead>

            <!-- Scrollable body -->
            <div :style="{
              height: height || '400px',
              minHeight: '400px',
              maxHeight: height || '400px',
            }" class="rounded-b-sm">
              <slot name="loader" >
                <table-loader  v-show="loading" :load="loading" :length="items.length" :colspan="columns.length" />
              </slot>
              <div v-if="!loading && items.length == 0" class="flex w-full h-full items-center justify-center">
                <span class="text-gray-500 text-sm">{{ $t("globalComponents.tableLoader.tableLoaderTitle") }}</span>
              </div>

              <tbody class="flex flex-col w-full h-auto" v-show="!loading">

                <tr v-for="(item, index) in items" :key="index" @click.stop="handleRowClickAll(item)"
                  class="flex items-stretch hover:bg-gray-50 border-b w-full border-slate-50 max-w-full relative"
                  :class="size == 'sm' ? 'min-h-[40px] text-xs' : size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base'">
                  <div class="flex w-full h-full flex-col">
                    <div class="flex w-full h-full items-stretch px-8">
                      <div v-if="isActions" class="!w-[2px] sticky !z-[30] flex h-full  "
                        :style="{ left: `calc(100% - ${actionWidth}px)` }"
                        :class="size == 'sm' ? 'min-h-[40px] text-xs' : size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base'">
                        <div ref="actionButton"
                          class="w-fit flex justify-end items-center bg-white h-full px-4 border-x border-slate-50  "
                          :class="size == 'sm' ? 'min-h-[40px] text-xs' : size === 'md' ? 'min-h-[48px] text-sm' : 'min-h-[56px] text-base'"
                          >
                          <slot name="actions" :item="item" :index="index"></slot>
                        </div>

                      </div>
                      <td v-for="(column, colIndex) in columns" :key="colIndex" 
                      :class="[
                        'px-2 w-full flex-grow flex-shrink-0 !flex items-center h-auto ',
                        { 'cursor-pointer hover:*:underline hover:underline ': column.clickable },
                        column.align === 'center' && 'text-center justify-center',
                        column.align === 'right' && 'text-right justify-end',
                        (column.sticky && scrollLeft > getLeftPosition(colIndex)) && 'sticky bg-white shadow-[1px_0_5px_rgba(0,0,0,0.05)] z-10'
                      ]" 
                      :style="{
                        minWidth: column.minWidth || column.width || '120px', 
                        maxWidth: column.minWidth ? ' auto' : (column.width || '120px'),
                        left: column.sticky && scrollLeft > getLeftPosition(colIndex) ? getStickyPosition(colIndex) + 'px' : 'auto',
                      }" 
                      @click="column.clickable && handleRowClick(item, column.key)">
                        <slot :name="column.key" :item="item" :column="column" :index="index">
                          {{ formatColumnValue(item[column.key], column.format, item, index) }}
                        </slot>
                      </td>

                      <td v-if="isActions" class="flex h-auto"
                        :style="{ width: actionWidth + 'px', minWidth: actionWidth + 'px' }">
                        <div class="h-auto flex w-full">

                        </div>
                      </td>
                    </div>
                    <div v-if="isSecondLine" class="flex w-full h-full items-center">
                      <slot name="secondLine" :item="item" :index="index"></slot>
                    </div>
                  </div>
                </tr>

              </tbody>
            </div>
          </table>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <slot name="pagination">
      <pagination v-if="showPagination" v-model="currentPageSync" :pageCount="pageCount" :totalRowCount="totalCount" />
    </slot>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import TableLoader from "@/components/table-loader.vue";
import moment from "moment";
import customCheckbox from "@/components/customCheckbox.vue";
export default {
  name: "CustomTable",


  components: {
    Pagination,
    TableLoader,
    customCheckbox
  },
  props: {
    // Table data
    items: {
      type: Array,
      required: true
    },
    // Column definitions
    columns: {
      type: Array,
      required: true
      /* Example column structure:
      {
        key: 'id',
        label: 'ID',
        width: 'w-[10%]',
        sortable: true,
        clickable: true,
        format: 'date' // or custom formatter function,
        align: 'center' // or 'right' default is left,
        sticky: true // makes the column fixed during horizontal scroll
      }
      */
    },
    // Pagination props
    currentPage: {
      type: [Number, String],
      default: 0
    },

    pageCount: {
      type: [Number, String],
      default: 1
    },

    totalCount: {
      type: [Number, String],
      default: 0
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    // Loading state
    loading: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: "md"
    },
    height: {
      type: String,
      default: ""
    },
    isActions: {
      type: Boolean,
      default: true
    },
    isSecondLine: {
      type: Boolean,
      default: false
    },
    sortType: {
      type: String,
      default: 'none'
    },
    selectedSortKey: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    },
    tableClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showFilter: false,
      currentPageSync: this.currentPage,
      actionWidth: 130,
      resizeObserver: null,
      selectAll: false,
      stickyColumnWidths: [],
      scrollLeft: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateActionWidth()
      this.setupResizeObserver()
      this.calculateStickyColumnWidths()
    })
  },
  computed: {
    // Get all sticky columns for easy calculation
    stickyColumns() {
      return this.columns.filter(col => col.sticky);
    }
  },
  methods: {
    // Calculate position for sticky columns
    getStickyPosition(columnIndex) {
      if (!this.columns[columnIndex].sticky) return 0;
      
      // Table has 32px (8px * 4) of padding on the left
      let position = 0; // px-8 = 8px * 4 = 32px
      
      // Add widths of all previous sticky columns
      for (let i = 0; i < columnIndex; i++) {
        if (this.columns[i].sticky) {
          let width = this.columns[i].width || this.columns[i].minWidth || '120px';
          if(typeof width === 'string' && width.includes('%')){
            width = parseInt(width.replace('%', ''))
          }else if(typeof width === 'string' && width.includes('px')){
            width = parseInt(width.replace('px', ''))
          }

          position += width;
        }
      }
      
      return position;
    },
    getLeftPosition(columnIndex){
      let position = 0;
      for(let i = 0; i < columnIndex; i++){
          position += this.getActualColumnWidth(i);
          if (this.columns[i].sticky) {
            const width = this.getActualColumnWidth(i);
            position -= width;
          }
      }
      
      return position;
    },
    
    // New method to get actual column width
    getActualColumnWidth(columnIndex) {
      // Try to get the actual rendered width from DOM
      const headerCells = document.querySelectorAll('thead th');
      if (headerCells && headerCells[columnIndex]) {
        return headerCells[columnIndex].offsetWidth;
      }
      
      // Fallback if DOM element is not available
      const width = this.columns[columnIndex].width || this.columns[columnIndex].minWidth || '120px';
      if (typeof width === 'string' && width.includes('%')) {
        // For percentage width, we need to estimate - this is a fallback
        // and might not be perfectly accurate until elements are rendered
        return 120; // Default estimate
      }else if(typeof width === 'string' && width.includes('px')){
        return parseInt(width.replace('px', ''))
      }
      return parseInt(width) || 120;
    },
    // Calculate and store widths of sticky columns
    calculateStickyColumnWidths() {
      this.stickyColumnWidths = this.columns
        .filter(col => col.sticky)
        .map(col => parseInt(col.width || col.minWidth || 120));
    },
    formatColumnValue(value, format, item, index) {
      if (!format) return value;

      if (typeof format === 'function') {
        return format(value, item, index);
      }

      switch (format) {
        case 'date':
          return this.formatDate(value, item, index);
        // Add other format types as needed
        default:
          return value;
      }
    },
    formatDate(value) {
      return value ? moment(value).format("LL") : this.$t("noDate");
    },
    handleSort(key) {
      let order = 'none';
      if(key !== this.selectedSortKey){
        order = 'asc';
      }else{
         order = this.sortType === 'none' ? 'asc' : this.sortType === 'asc' ? 'desc' : 'none';
      }
     
      this.$emit('sort', { key: key, order: order });
    },
    handleFilter(filterId) {
      this.showFilter = false;
      this.$emit('filter', filterId);
    },
    handleRowClick(item, key) {
        this.$emit('row-click', { item, key });
    },
    handleRowClickAll(item){
      this.$emit('row-click-all', item);
    },
    handleTableScroll(event) {
      this.scrollLeft = event.target.scrollLeft;
    },
    activateStickyStylesWhenScrollPastTheElement(element){
      if(this.scrollLeft > element.offsetLeft){
        element.classList.add('sticky');
      }else{
        element.classList.remove('sticky');
      }
    },
  
    calculateActionWidth() {
      if (this.$refs.actionButton && this.$refs.actionButton.length > 0) {
        // Get all action buttons

        const actionElements = this.$refs.actionButton
        let maxWidth = 0

        // Find the widest action button
        actionElements.forEach(el => {
          const computedStyle = window.getComputedStyle(el)
          const paddingLeft = parseFloat(computedStyle.paddingLeft)
          const paddingRight = parseFloat(computedStyle.paddingRight)
          const totalWidth = el.offsetWidth + paddingLeft + paddingRight 

          maxWidth = Math.max(maxWidth, totalWidth) 
        })

        // Add some buffer for safety
        this.actionWidth = maxWidth - 32
      }
    },

    setupResizeObserver() {
      if (this.$refs.actionButton && this.$refs.actionButton.length > 0) {
        // Cleanup any existing observer
        if (this.resizeObserver) {
          this.resizeObserver.disconnect()
        }

        // Create new observer
        this.resizeObserver = new ResizeObserver(() => {
          this.calculateActionWidth()
        })

        // Observe all action buttons
        this.$refs.actionButton.forEach(el => {
          this.resizeObserver.observe(el)
        })
      }
    }
  },
  beforeDestroy() {
    // Cleanup
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  watch: {
    currentPageSync(newVal) {
      this.$emit('update:currentPage', newVal);
      this.$emit('page-change', newVal);
    },
    currentPage(newVal) {
      this.currentPageSync = newVal;
    },
    items: {
      handler(val) {
        this.$nextTick(() => {
          this.calculateActionWidth();
          if (!val.length) {
            this.selectAll = false;
          }
        })
      },
      deep: true
    },
    loading(newVal) {
      this.$nextTick(() => {
        this.calculateActionWidth();
      })
    },
    selectAll(newVal) {
      this.$emit('select-all', newVal)
    },
    columns: {
      handler() {
        this.$nextTick(() => {
          this.calculateStickyColumnWidths();
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped>
/* Ensures sticky columns properly overlay scrollable content */
th.sticky, td.sticky {
  position: sticky;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  height: auto;
}

/* Ensure the rows and cells expand properly */
tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

td {
  display: flex;
  align-items: center;
}

/* Center alignment for text in cells */
td.text-center {
  justify-content: center;
}

/* Right alignment for text in cells */
td.text-right {
  justify-content: flex-end;
}
</style>