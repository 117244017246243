<template>
  <div>
    <customPopup v-model="showPopupLocal" :title="
      popupType == 'reference' ? $t('project.projectUser.components.referenceAndPayment.reference.popup.title') :
      popupType == 'payment' ? $t('project.projectUser.components.referenceAndPayment.payment.popup.title') :
      popupType == 'investigation' ? $t('project.projectUser.components.referenceAndPayment.investigation.popup.title') :
      ''
    " size="lg">
      <div class="flex flex-col gap-4 p-4" v-if="!loadingPopupDetails">
        <div v-if="popupType == 'reference'" class="flex flex-col gap-4">
          <customInput
            v-if="!hasExistingReferences"
            type="number"
            :label="$t('project.projectUser.components.referenceAndPayment.reference.popup.referenceCount')"
            :placeholder="$t('project.projectUser.components.referenceAndPayment.reference.popup.referenceCountPlaceholder')"
            v-model="referenceCount"
          />

          <div v-if="hasExistingReferences" class="flex flex-col gap-4">
            <div v-for="(referenceGroup, index) in groupedReferences" :key="index">
              <customCard size="sm" variant="primary" :isAccordion="true" :defaultOpen="true" 
                :title="`${$t('project.projectUser.components.referenceAndPayment.reference.popup.reference')} ${index + 1}`">
                <div class="flex flex-col gap-4">
                  <div v-for="item in referenceGroup" :key="item.typeId" class="flex flex-col">
                    <span class="text-sm font-bold">{{ item.typeName }}</span>
                    <span class="text-sm text-gray-500">{{ item.value || '-' }}</span>
                  </div>
                </div>
              </customCard>
            </div>
          </div>

          <customCard v-else size="sm" variant="primary" :isAccordion="true" :defaultOpen="true" :title="$t('project.projectUser.components.referenceAndPayment.reference.popup.references')">
            <div v-for="reference in referenceTypes" class="flex flex-col gap-2" :key="reference.id">
              <div class="flex flex-col">
                <span class="text-sm font-bold">{{ reference.value }}</span>
                <span class="text-sm text-gray-500">{{ reference.input || '-' }}</span>
              </div>
            </div>
          </customCard>
        </div>
        
        <div v-if="popupType == 'payment'" class="flex flex-col gap-4">
          <customInput
            type="textarea"
            :label="$t('project.projectUser.components.referenceAndPayment.payment.popup.salaryDescription')"
            :placeholder="$t('project.projectUser.components.referenceAndPayment.payment.popup.salaryDescriptionPlaceholder')"
            v-model="salaryDescription"
            rows="5"
            :disabled="hasSalary"
          />
        </div>
        
        <div v-if="popupType == 'investigation'" class="flex flex-col gap-4">
          <customInput
            type="textarea"
            :label="$t('project.projectUser.components.referenceAndPayment.investigation.popup.investigationDescription')"
            :placeholder="$t('project.projectUser.components.referenceAndPayment.investigation.popup.investigationDescriptionPlaceholder')"
            v-model="investigationDescription"
            rows="5"
          />
        </div>
      </div>
      <div v-else>
        <customLoading />
      </div>
      <template #footer>
        <div class="flex justify-end gap-2" v-if="popupType == 'reference'">
          <customButton containerBackground="bg-red-500" @click="handleSubmit('reject')"
            v-if="hasExistingReferences"
            :load="loadingSubmit"
            :disabled="loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.rejectReference") }}
          </customButton>
          <customButton @click="handleSubmit('accept')"
            v-if="hasExistingReferences"
            :load="loadingSubmit"
            :disabled="loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.acceptReference") }}
          </customButton>
        
          <customButton @click="handleSubmit('send')"
            v-if="!hasExistingReferences" :disabled="!referenceCount || loadingSubmit"
            :load="loadingSubmit"
            :loadText="$t('project.projectUser.components.referenceAndPayment.buttons.sendReferenceMail') "
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.sendReferenceMail") }}
          </customButton>
        </div>
        
        <div class="flex justify-end gap-2" v-if="popupType == 'payment'">
          <customButton containerBackground="bg-red-500" @click="handleSubmit('reject')"
            v-if="hasSalary"
            :load="loadingSubmit"
            :disabled="loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.rejectPayment") }}
          </customButton>
          <customButton @click="handleSubmit('accept')"
            v-if="hasSalary"
            :load="loadingSubmit"
            :disabled="loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.acceptPayment") }}
          </customButton>
        
          <customButton @click="handleSubmit('set')"
            v-if="!hasSalary" :disabled="!salaryDescription || loadingSubmit"
            :load="loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.sendPaymentInfo") }}
          </customButton>
        </div>
        
        <div class="flex justify-end gap-2" v-if="popupType == 'investigation'">
          <customButton containerBackground="bg-red-500" @click="handleSubmit('reject')"
            :load="loadingSubmit"
            :disabled="!investigationDescription || loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.rejectInvestigation") }}
          </customButton>
          <customButton @click="handleSubmit('accept')"
            :load="loadingSubmit"
            :disabled="!investigationDescription || loadingSubmit"
          >
            {{ $t("project.projectUser.components.referenceAndPayment.buttons.acceptInvestigation") }}
          </customButton>
          
     
        </div>
      </template>
    </customPopup>
    
  </div>
</template>

<script>
import customPopup from "@/components/customPopup.vue";
import customButton from "@/components/button.vue";
import customInput from "@/components/customInput.vue";
import customLoading from "@/components/customLoading.vue";
import customCard from "@/components/customCard.vue";
import { referenceAndPayment } from "@/networking/urlmanager";

export default {
  name: "ReferenceAndPaymentModals",
  components: {
    customPopup,
    customButton,
    customInput,
    customLoading,
    customCard,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    popupType: {
      type: String,
      default: null
    },
    selectedItem: {
      type: Object,
      default: null
    },
    projectId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      loadingPopupDetails: false,
      loadingSubmit: false,
      referenceTypesCandidate: [],
      referenceTypes: [],
      referenceCount: null,
      hasExistingReferences: false,
      salaryDescription: "",
      hasSalary: false,
      investigationDescription: "",
      hasInvestigation: false
    }
  },
  computed: {
    showPopupLocal: {
      get() {
        return this.showPopup;
      },
      set(value) {
        this.$emit('update:showPopup', value);
        if (!value) {
          this.$emit('reset');
        }
      }
    },
    groupedReferences() {
      const grouped = {};
      
      if (this.referenceTypesCandidate && this.referenceTypesCandidate.length > 0) {
        this.referenceTypesCandidate.forEach(group => {
          if (group.values && group.values.length > 0) {
            const referenceId = group.values[0].referencesId;
            if (!grouped[referenceId]) {
              grouped[referenceId] = [];
            }
            grouped[referenceId] = [...grouped[referenceId], ...group.values];
          }
        });
      }
      
      return Object.values(grouped);
    }
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (this.popupType === 'reference') {
            this.fetchCandidateReferences();
          } else if (this.popupType === 'payment') {
            this.fetchSalaryInfo();
          } else if (this.popupType === 'investigation') {
            this.fetchInvestigationInfo();
          }
        }
      }
    }
  },
  methods: {
    async fetchCandidateReferences() {
      if (!this.selectedItem) return;
      
      this.loadingPopupDetails = true;
      try {
        const response = await this.axios.get(referenceAndPayment.getReferenceTypesProject, {
          params: {
            projectId: this.projectId,
            cvId: this.selectedItem.cvId
          }
        });
        
        this.referenceTypesCandidate = response.data.data || [];
        
        // Check if we have reference data
        this.hasExistingReferences = this.referenceTypesCandidate && 
          this.referenceTypesCandidate.length > 0 &&
          this.referenceTypesCandidate.some(group => group.values && group.values.length > 0);
        
        // If we have reference data, fetch reference types for names
        if (!this.hasExistingReferences) {
          await this.fetchReferences();
        }
      } catch (error) {
        console.error(error);
        this.authController(error);
      } finally {
        this.loadingPopupDetails = false;
      }
    },
    async fetchReferences() {
      try {
        const response = await this.axios.get(referenceAndPayment.referenceTypes);
        this.referenceTypes = response.data.data || [];
      } catch (error) {
        console.error(error);
        this.authController(error);
      }
    },
    async fetchSalaryInfo() {
      if (!this.selectedItem) return;
      
      this.loadingPopupDetails = true;
      try {
        const response = await this.axios.get(referenceAndPayment.salaryGetAll, {
          params: {
            projectId: this.projectId,
            cvId: this.selectedItem.cvId
          }
        });
        
        const salaryData = response.data.data;
        if (salaryData && salaryData.description) {
          this.salaryDescription = salaryData.description;
          this.hasSalary = true;
        } else {
          this.salaryDescription = "";
          this.hasSalary = false;
        }
      } catch (error) {
        console.error(error);
        this.authController(error);
      } finally {
        this.loadingPopupDetails = false;
      }
    },
    async fetchInvestigationInfo() {
      if (!this.selectedItem) return;
      
      this.loadingPopupDetails = true;
      try {
        const response = await this.axios.get(referenceAndPayment.investigationGetAll, {
          params: {
            projectId: this.projectId,
            cvId: this.selectedItem.cvId
          }
        });
        
        const investigationData = response.data.data;
        if (investigationData && investigationData.description) {
          this.investigationDescription = investigationData.description;
          this.hasInvestigation = true;
        } else {
          this.investigationDescription = "";
          this.hasInvestigation = false;
        }
      } catch (error) {
        console.error(error);
        this.authController(error);
      } finally {
        this.loadingPopupDetails = false;
      }
    },
    handleSubmit(type) {
      if (this.popupType == "reference") {
        this.submitReference(type);
      } else if (this.popupType == "payment") {
        this.submitPayment(type);
      } else if (this.popupType == "investigation") {
        this.submitInvestigation(type);
      }
    },
    submitReference(type) {
      if (type == "send") {
        this.loadingSubmit = true;
        const email = this.parseEmail(this.selectedItem.email);
        this.axios.post(referenceAndPayment.referenceMail, {
          projectId: this.projectId,
          cvId: this.selectedItem.cvId,
          email: email,
          referenceCount: this.referenceCount
        }).then(response => {
          this.successMessage();
          this.resetAll();
          this.$emit('refresh');
        }).catch(error => {
          console.error(error);
          this.authController(error);
        }).finally(() => {
          this.loadingSubmit = false;
        });
      } else {
        this.loadingSubmit = true;
        this.axios.post(referenceAndPayment.referenceChangeState, {
          projectId: this.projectId,
          cvId: this.selectedItem.cvId,
          state: type == "accept" ? 1 : 0
        }).then(response => {
          this.successMessage();
          this.$emit('refresh');
          this.resetAll();
        }).catch(error => {
          console.error(error);
          this.authController(error);
        }).finally(() => {
          this.loadingSubmit = false;
        });
      }
    },
    submitPayment(type) {
      this.loadingSubmit = true;
      
      if (type === 'set') {
        this.axios.post(referenceAndPayment.salarySet, {
          projectId: this.projectId,
          cvId: this.selectedItem.cvId,
          description: this.salaryDescription
        }).then(response => {
          this.successMessage();
          this.$emit('refresh');
          this.resetAll();
        }).catch(error => {
          console.error(error);
          this.authController(error);
        }).finally(() => {
          this.loadingSubmit = false;
        });
      } else {
        this.axios.post(referenceAndPayment.salaryChangeState, {
          projectId: this.projectId,
          cvId: this.selectedItem.cvId,
          state: type === 'accept' ? 1 : 0
        }).then(response => {
          this.successMessage();
          this.$emit('refresh');
          this.resetAll();
        }).catch(error => {
          console.error(error);
          this.authController(error);
        }).finally(() => {
          this.loadingSubmit = false;
        });
      }
    },
    submitInvestigation(type) {
      this.loadingSubmit = true;
      
      this.axios.post(referenceAndPayment.investigationSet, {
        projectId: this.projectId,
        cvId: this.selectedItem.cvId,
        description: this.investigationDescription,
        state: type === 'accept' ? 1 : type === 'reject' ? 0 : null
      }).then(response => {
        this.successMessage();
        this.$emit('refresh');
        this.resetAll();
      }).catch(error => {
        console.error(error);
        this.authController(error);
      }).finally(() => {
        this.loadingSubmit = false;
      });
    },
    resetAll() {
      this.showPopupLocal = false;
      this.referenceCount = null;
      this.referenceTypes = [];
      this.referenceTypesCandidate = [];
      this.hasExistingReferences = false;
      this.salaryDescription = "";
      this.hasSalary = false;
      this.investigationDescription = "";
      this.hasInvestigation = false;
      this.loadingPopupDetails = false;
      this.loadingSubmit = false;
    },
  
  }
}
</script>
