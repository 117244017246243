var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex min-h-screen w-full relative",attrs:{"id":"app"}},[_c('log',{ref:"log",attrs:{"moduleId":3},on:{"isLog":(r) => (_vm.isLog = r)}}),_c('checkNetwork',{attrs:{"successText":"İnternet Bağlantınız Tekrar Sağlandı","errorText":"İnternet bağlanıtınız Koptu"}}),(_vm.$store.state.userData.isAuth)?_c('sidebar',{class:[
    _vm.$route.name == 'notFound' && 'hidden',
    _vm.$store.state.isMeetingBar == false ? _vm.$route.name == 'meeting' && 'hidden transition-all ease-in-out' : 'transition-all ease-in-out ',
    _vm.$store.state.isMeetingBar == false ? _vm.$route.name == 'meetingLive' && 'hidden transition-all ease-in-out' : ' transition-all ease-in-out',
    _vm.$store.state.isMeetingBar == false ? _vm.$route.name == 'assistan' && 'hidden transition-all ease-in-out' : ' transition-all ease-in-out',
  ],attrs:{"isSidebar":_vm.isSidebar}}):_vm._e(),_c('div',{staticClass:"w-full max-h-screen overflow-y-auto overflow-x-hidden",attrs:{"id":"app-container"}},[(_vm.$store.state.userData.isAuth)?_c('navbar',{class:[
        _vm.$route.name == 'notFound' && 'hidden',
        _vm.$route.name == 'meeting' && 'hidden',
        _vm.$route.name == 'meetingLive' && 'hidden',
        _vm.$route.name == 'assistan' && 'hidden',
      ],attrs:{"toggleSidebar":_vm.toggleSidebar,"logToggle":_vm.logToggle,"isSidebar":_vm.isSidebar,"isLog":_vm.isLog}}):_vm._e(),_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }