<template>
    <div id="chart" class="pb-5">
        <apexchart  ref="chart" type="radialBar" height="480" :options="{
            ...chartOptions,
        }" :series="series"></apexchart>
    </div>
</template>


<script>
import { general } from "@/networking/urlmanager";

export default {
    name: "PositionChart",

    data() {
        return {
            series: [0, 0, 0, 0, 0],
            categories: [],
            error: null,
            loading: false,
            chartOptions: {
                chart: {
                    type: 'radialBar',
                    height: 350
                },
                title: {
                    text: this.$t("general.components.applicationStatusChart.applicationStatusChart"),
                    align: "left",
                    style: {
                        fontSize: "14px",
                        fontWeight: "600",
                    },
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '30%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            }
                        },
                        barLabels: {
                            enabled: true,
                            useSeriesColors: true,
                            offsetX: -8,
                            fontSize: '16px',
                            formatter: function(seriesName, opts) {
                                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                            },
                        },
                    },
                },
                colors: ["#50b696", "#5070b6", "#b65070", "#f4a261", "#b0bec5",],
                labels: ['', '', '', '', ''],
                responsive: [{
                    breakpoint: 480,

                    options: {
                        legend: {
                            show: false
                        }
                    }
                }],
            }
        };
    },

    methods: {
        async fetchPositionData() {
            try {
                this.loading = true;
                const response = await this.axios.get(general.getApplicationStatus);
                const data = response?.data?.data?.[0];
             
                if (!data) {
                    console.error('No data received');
                    return;
                }

                const seriesData = [];
                const categories = [];
                
                for (const key in data) {
                    seriesData.push(data[key]);
                    categories.push(key);
                }

                // Update chart data
                this.series = seriesData;
                this.categories = categories;
                this.chartOptions.labels = categories.map(category => 
                    this.$t(`general.components.applicationStatusChart.${category}`)
                );

            } catch (error) {
                console.error("Failed to fetch position data:", error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        },

        updateChartOnLocaleChange() {
            if (!this.$refs.chart) return;

            // Update labels and title
            this.chartOptions.labels = this.categories.map(category => 
                this.$t(`general.components.applicationStatusChart.${category}`)
            );
            this.chartOptions.title.text = this.$t("general.components.applicationStatusChart.applicationStatusChart");

            // Update chart
            this.$nextTick(() => {
                this.$refs.chart.updateOptions(this.chartOptions);
            });
        }
    },

    watch: {
        "$i18n.locale": {
            handler() {
                this.updateChartOnLocaleChange();
            },
            immediate: true
        }
    },

    async created() {
        await this.fetchPositionData();
    }
};
</script>