<template>
  <div v-if="isOpen">
    <modal :name="customName ? customName : 'assessmentHistoryModalName'" @close="handleClose" :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '70%'" :scrollable="true" class="scroltype">
      <div class="flex justify-between card-header p-5 bgcol w-full md:rounded-t text-white z-50">
        <h1>{{ $t("project.components.assessmentHistory.title") }}</h1>
        <button @click="handleClose()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4 h-full overflow-y-auto max-h-[calc(100vh-300px)]">
    

        <!-- Button to Create New Assessment -->
    

        <!-- Assessment History Table -->
       
          <customTable
            :columns="tableColumns"
            :items="historyData"
            :loading="fetchLoading"
            size="md"
            :showPagination="false"
            tableClass="!mt-0"
            :isActions="false"
          >
          <template #header>
            <div class="flex w-full justify-end items-center">
          <customButton @click="openAssessmentModal" 
          :text="$t('project.components.assessmentHistory.createNew')"
          >
          </customButton>
        </div>
          </template>

          <template #id="{item}">
            <div class="flex items-center justify-center font-bold">
              #{{ item.id }}
            </div>
          </template>

          <template #assessmentName="{item}">
            <div class="flex flex-col text-xs">
                <div class="font-bold">
                    {{ item.providerName }}
                </div>

                <div class="text-gray-500">
                  {{ item.assessmentTitle }}  {{ item.stepTitle ? `- ${item.stepTitle}` : '' }}
                </div>
            </div>
          </template>
        <template #stateId="{item}">
            <Badge :variant="item.stateId == 0 ? 'warning' : item.stateId == 1 ? 'info' : item.stateId == 2 ? 'success' : item.stateId == 3 ? 'error' : 'default'" >
        {{ item.stateId}}        
        </Badge>
        </template>
          </customTable>

       
      </div>
  
    </modal>
    <!-- Assessment Modal Reference -->
    <assessmentModal ref="assessmentModal" :candidateInfo="candidateInfo"
      :projectId="projectId" mode="sendAssessment" customName="historyAssessmentModal"
      @refreshHistory="fetchAssessmentHistory"
      />
  </div>
</template>

<script>
import customTable from "@/components/customTable.vue";
import assessmentModal from "./assessmentModal.vue";
import { assessments } from "@/networking/urlmanager";
import Badge from "@/components/badge.vue";
import customButton from "@/components/button.vue";
export default {
  name: "AssessmentHistoryModal",
  components: {
    customTable,
    assessmentModal,
    Badge,
    customButton
  },
  props: {
    projectId: {
      type: [String, Number],
      default: null
    },
    candidateInfo: {
      type: Object,
      default: null
    },
    customName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
      fetchLoading: false,
      historyData: [],
      tableColumns: [
        {
            key: "id",
            label: this.$t("project.components.assessmentHistory.columns.id"),
            width: "90px"
        },
        {
            key: "assessmentName",
            label: this.$t("project.components.assessmentHistory.columns.assessmentName"),
            width: "150px"
        },
        {
          key: "stateId",
          label: this.$t("project.components.assessmentHistory.columns.status"),
          width: "120px"
        },
        {
          key: "score",
          label: this.$t("project.components.assessmentHistory.columns.score"),
          width: "100px"
        },
        {
          key: "startDate",
          label: this.$t("project.components.assessmentHistory.columns.startDate"),
          width: "150px"
        },
        {
          key: "endDate",
          label: this.$t("project.components.assessmentHistory.columns.endDate"),
          width: "150px"
        },
        {
            key:"completedAt",
            label: this.$t("project.components.assessmentHistory.columns.completedAt"),
            width: "150px"
        },
    
        {
          key: "sentAt",
          label: this.$t("project.components.assessmentHistory.columns.sentDate"),
          width: "150px"
        },
      ]
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    }
  },
  methods: {
    show() {
      this.isOpen = true;
      this.$nextTick(() => {
        const name = this.customName ? this.customName : 'assessmentHistoryModalName';
        this.$modal.show(name);
        // Fetch assessment history data when modal is shown
        this.fetchAssessmentHistory();
      });
    },
    handleClose() {
      const name = this.customName ? this.customName : 'assessmentHistoryModalName';
      this.$modal.hide(name);
      this.$nextTick(() => {
        this.isOpen = false;
      });
    },
    fetchAssessmentHistory() {
      if (!this.candidateInfo || !this.projectId) return;
      
      this.fetchLoading = true;
      this.historyData = [];
      
      this.axios.get(assessments.getHistory, {
       params:{
        projectId: this.projectId,
        cvId: this.candidateInfo.cvId
       }
      })
      .then(response => {
        this.historyData = this.processHistoryData(response.data.data || []);
      })
      .catch(error => {
        console.error('Error fetching assessment history:', error);
        this.authController(error);
      })
      .finally(() => {
        this.fetchLoading = false;
      });
    },
    processHistoryData(data) {
      // Process and format the data from the API
      return data.map(item => {
        // Check if stateId is 1 and endDate is past
        let stateId = item.stateId;
        if (stateId == 1 && item.endDate && new Date(item.endDate) < new Date()) {
          stateId = 3; // Update stateId to 3 if endDate is in the past
        }
        
        return {
          id: item.id,
          sentAt: this.formatDate(item.sentAt),
          startDate: this.formatDate(item.startDate),
          endDate: this.formatDate(item.endDate),
          completedAt: this.formatDate(item.completedAt),
          stateId: this.getStatusLabel(stateId),
          providerName: item.providerName,
          assessmentTitle: item.assessmentTitle,
          stepTitle: item.stepTitle,
          statusCode: stateId, // Use the potentially updated stateId
          score: item.score || '-',
          rawData: item // Keep the raw data for reference
        };
      });
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      const date = new Date(dateString);
      return date.toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    },
    getStatusLabel(status) {
      // Map status codes to readable labels
      const statusMap = {
        0: this.$t('project.components.assessmentHistory.status.pending'),
        1: this.$t('project.components.assessmentHistory.status.inProgress'),
        2: this.$t('project.components.assessmentHistory.status.completed'),
        3: this.$t('project.components.assessmentHistory.status.expired')
      };
      return statusMap[status] || this.$t('project.components.assessmentHistory.status.unknown');
    },
    openAssessmentModal() {
      // Open the assessment modal for sending a new assessment
      this.$refs.assessmentModal.show();
    },
    viewAssessmentResults(item) {
      // Implement viewing assessment results
      console.log('Viewing results for assessment:', item);
      // This would typically open another modal or navigate to a results page
    },
   
  }
};
</script>

<style scoped>
.bgcol {
  background-color: var(--cvus-primary);
}
</style>
