<template>
  <div class="bg-white shadow-md rounded-lg overflow-hidden">
    <div v-if="list.length > 0" class="min-h-[300px] max-h-[500px] overflow-auto">
      <customTable :items="processedList" :columns="columns" :loading="false" :showPagination="false"
        :isSecondLine="true"  variant="secondary" size="sm">
        <template #title="{ item }">
          <div class="flex items-center  !min-h-[50px] flex !h-[50px]">
            <span class="font-medium text-gray-800">{{ item.title }}</span>

          </div>
        </template>

        <template #typeName="{ item }">
          <span class="text-gray-600">{{ item.typeName }}</span>
        </template>

        <template #date="{ item }">
          <span class="text-gray-600">{{ formatDate(item.createdAt) }}</span>
        </template>

        <template #isOnline="{ item }">
          <span class="px-2 py-1 rounded-full text-xs font-medium"
            :class="item.isOnline ? 'bg-cvus-primary text-slate-200' : 'bg-green-100 text-green-800'">
            {{ item.isOnline
            ? $t("project.projectUser.components.shortList.components.steps.interviewList.online")
            : $t("project.projectUser.components.shortList.components.steps.interviewList.faceToFace")
            }}
          </span>
        </template>

        <template #status="{ item }">
          <span class="px-2 py-1 rounded-full text-xs font-medium"
            :class="item.history && item.history.length > 0 ? statuName(item.history[item.history.length - 1].status).class : ''">
            {{ item.history && item.history.length > 0 ? statuName(item.history[item.history.length - 1].status).name :
            '-' }}
          </span>
        </template>
      
        <template #acts="{ item }">
          <div class="flex items-center  !min-h-[50px] gap-2 !h-[50px]">


            <asyncButton v-if="item.history && item.history.length > 0 &&
              (item.history[item.history.length - 1].status == 3 ||
                item.history[item.history.length - 1].status == 4 ||
                item.history[item.history.length - 1].status == 5 ||
                item.history[item.history.length - 1].status == 6)"
              :buttonBodyClass="'flex flex-row-reverse justify-center items-center gap-2'"
              :buttonClass="'rounded-md text-xs text-white px-3 py-2 bgcol'" :text="getButtonText(item)"
              :awesome="'fa-solid fa-arrow-right text-gray-100 hover:text-gray-700 cursor-pointer'"
              @click="handleButtonClick(item)" />
          </div>
        </template>
        <template #actions="{ item }">
          <div class="flex items-center  !min-h-[50px] gap-2 !h-[50px]">
            <button @click="toggleHistory(item)" class=" text-gray-500 hover:text-gray-700">
             <Icon icon="mdi:history" class=" text-xl text-gray-500 hover:text-gray-700"/>
            </button>
          </div>
        </template>
        <template #secondLine="{ item }">
          <transition name="slide-fade-top">
            <div v-if="item.secondline" class="flex flex-col w-full py-2 px-4 bg-gray-50 border-t border-gray-100">
              <div class="text-sm font-medium text-gray-700 mb-1">{{
                $t("project.projectUser.components.shortList.components.steps.interviewList.historyTitle") }}</div>
              <div class="grid grid-cols-1 gap-2">
                <div v-for="(entry, index) in getHistoryList(item.history)" :key="index" class="flex items-start gap-2">
                  <span class="inline-block w-2 h-2 rounded-full bg-blue-500 mt-1.5"></span>
                  <div class="flex flex-col">
                    <div class="text-xs text-gray-600">
                      {{ formatDate(entry.date) }} - {{ entry.status ? statuName(entry.status).name : '-' }}
                    </div>
                    <div class="text-xs text-gray-500" v-if="entry && entry.dates && entry.dates.length > 0">
                      {{ formatHistory([entry]) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>
      </customTable>
    </div>
  </div>
</template>

<script>
import asyncButton from "@/components/button.vue";
import customTable from "@/components/customTable.vue";

export default {
  name: "InterviewList",
  components: {
    asyncButton,
    customTable,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      processedItems: [], // Store processed items here instead of in computed
      columns: [
        {
          key: 'title',
          label: this.$t("project.projectUser.components.shortList.components.steps.interviewList.header.interviewTitle"),
          width: '200px',
        },
        {
          key: 'typeName',
          label: this.$t("project.projectUser.components.shortList.components.steps.interviewList.header.type"),
          width: '150px',
        },
        {
          key: 'date',
          label: this.$t("project.projectUser.components.shortList.components.steps.interviewList.header.date"),
          width: '180px',
        },
        {
          key: 'isOnline',
          label: this.$t("project.projectUser.components.shortList.components.steps.interviewList.header.interviewPlace"),
          width: '150px',
        },
        {
          key: 'status',
          label: this.$t("project.projectUser.components.shortList.components.steps.interviewList.header.status"),
          width: '220px',
        },
   
        {
          key: 'acts',
          label: '',
          width: '200px',
        },
      ],
    };
  },
  computed: {
    processedList() {
      return this.processedItems;
    },
  },
  watch: {
    list: {
      immediate: true,
      handler(newList) {
        if (newList && newList.length > 0) {
          this.processItems();
        }
      }
    }
  },
  methods: {
    processItems() {
      this.processedItems = this.list
        .map((item) => {
          const type = this.types.find((t) => t.value === item.type);
          return {
            ...item,
            typeName: type ? type.name : "Unknown",
            secondline: false,
          };
        })
        .reverse();
    },
    toggleHistory(item) {
      // Find the item in our processedItems array
      const index = this.processedItems.findIndex(i => i.id === item.id);
      if (index !== -1) {
        // Create a new object with secondline toggled to ensure reactivity
        const updatedItem = { ...this.processedItems[index], secondline: !this.processedItems[index].secondline };
        // Use Vue's $set to ensure the change is reactive
        this.$set(this.processedItems, index, updatedItem);
      }
    },
    getButtonText(item) {
      if (!item.history || item.history.length === 0) {
        return "";
      }
      
      const status = item.history[item.history.length - 1].status;

      switch (status) {
        case 3:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.sendAppointment"
          );
        case 4:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.sendNewDates"
          );
        case 5:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.resendEmail"
          );
        case 6:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.sendAppointmentAgain"
          );
        default:
          return "";
      }
    },
    handleButtonClick(item) {
      if (!item.history || item.history.length === 0) {
        return;
      }
      
      const status = item.history[item.history.length - 1].status;

      switch (status) {
        case 3:
          // this.$emit("resendEmail", item);
          this.$emit("successful", item);
          break;
        case 4:
          // this.$emit("resendEmail", item);
          this.$emit("unsuccessful", item);
          break;
        case 5:
          this.$emit("resendEmail", item);
          break;
        case 6:
          this.$emit("successful", item, true);
          break;
        // default:
        //   this.$emit("resendEmail", item);
        //   break;
      }
    },
    formatHistory(history) {
      if (!Array.isArray(history) || history.length === 0) return "";

      return history
        .map((entry) => {
          if (!entry || !entry.dates || !Array.isArray(entry.dates) || entry.dates.length === 0) {
            return "";
          }
          
          const label = entry.isCompany
            ? this.$t(
              "project.projectUser.components.shortList.components.steps.interviewList.company"
            )
            : this.$t(
              "project.projectUser.components.shortList.components.steps.interviewList.candidate"
            );

          return entry.dates
            .map((dateEntry) => {
              if (!dateEntry) return "";
              
              const date = dateEntry.date
                ? this.formatDate(dateEntry.date)
                : "";
              return `${label}: ${date}`;
            })
            .filter(item => item) // Remove empty strings
            .join(", ");
        })
        .filter(item => item) // Remove empty strings
        .join(", ");
    },
    formatDate(dateString) {
      if (!dateString) return '-';
      
      try {
        const date = new Date(dateString);
        // Check if date is valid
        if (isNaN(date.getTime())) {
          return '-';
        }
        const options = { day: "2-digit", month: "long", year: "numeric" };
        return new Intl.DateTimeFormat("tr-TR", options).format(date);
      } catch (error) {
        console.warn('Invalid date format:', dateString);
        return '-';
      }
    },
    statuName(val) {
      switch (val) {
        case 1:
          return {
            name: this.$t("project.tableInterview.stateName.waiting"),
            class: "",
          };
        case 2:
          return {
            name: this.$t("project.tableInterview.stateName.interviewSent"),
            class: "bg-green-100 text-green-500",
          };
        case 3:
          return {
            name: this.$t("project.tableInterview.stateName.dateConfirmed"),
            class: "bg-green-100 text-green-500",
          };
        case 4:
          return {
            name: this.$t("project.tableInterview.stateName.dateRejected"),
            class: "bg-red-100 text-red-500",
          };
        // case 5:
        //   return {
        //     name: this.$t("project.tableInterview.stateName.meetCreated"),
        //     class: "bg-green-100 text-green-500",
        //   };
        // case 6:
        //   return {
        //     name: this.$t("project.tableInterview.stateName.hired"),
        //     class: "bg-green-100 text-green-500",
        //   };
        case 7:
          return {
            name: this.$t("project.tableInterview.stateName.inadequate"),
            class: "bg-red-100 text-red-500",
          };
        case 5:
          return {
            name: this.$t(
              "project.tableInterview.stateName.interviewCouldNotBeSent"
            ),
            class: "bg-yellow-100 text-yellow-600",
          };
        case 6:
          return {
            name: this.$t("project.tableInterview.stateName.interviewCouldNotBeSent"),
            class: "bg-yellow-100 text-yellow-600",
          };
        default:
          return { name: "", class: "" };
      }
    },
    getHistoryList(history) {
      if (!Array.isArray(history) || history.length === 0) return [];
      // Create a copy of the array before reversing it
      return [...history].reverse();
    }
    
  }
};
</script>

<style scoped>
    

.group:hover .tooltip {
  @apply opacity-100;
}

.slide-fade-top-enter-active, .slide-fade-top-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-top-enter, .slide-fade-top-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
