<template>
  <div class="mb-5"
    :class="{'opacity-50 cursor-not-allowed': disabled}"
  >
    <!-- Upload area - empty state -->
    <div
      v-if="
        ($store.state.userData.isExtract == 1 ||
          $store.state.userData.isExtract == 3) &&
        load == false
      "
      class="w-full rounded-xl border-2 border-dashed transition-all duration-300 hover:bg-slate-50 h-[125px]"
      :class="isDragging ? 'border-blue-500 bg-blue-50' : 'border-cvus-primary'"
    >
      <label
        for="fileInput"
        class="px-4 py-2 cursor-pointer flex flex-col items-center justify-center gap-1"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden"
          @change="onChange"
          ref="file"
          accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.jpeg"
        />

        <!-- Upload icon -->
        <div class="w-10 h-10 flex items-center justify-center rounded-full bg-blue-50 text-cvus-primary">
          <Icon icon="mdi:cloud-upload" class="h-6 w-6" />
        </div>

        <!-- Text instructions -->
        <div class="text-center">
          <div class="text-cvus-primary font-medium" v-if="isDragging">
            {{ $t("cv.components.uploadPanel.dropFiles") }}
          </div>
          <div v-if="!isDragging" class="flex flex-col">
            <span  class="text-cvus-primary-light2 font-semibold text-md max-[1310px]:text-xs">{{ $t("cv.components.uploadPanel.title") }}</span>
            <span class="text-[10px] text-gray-500 max-sm:text-[8px]">{{ $t("cv.components.uploadPanel.description") }}</span>
          </div>
        </div>
      </label>
    </div>

    <!-- Upload in progress state -->
    <div
      v-else-if="$store.state.userData.isExtract == 2 || load == true"
      class="w-full rounded-xl border-2 border-cvus-primary bg-slate-50 shadow-sm h-[125px]"
    >
      <div class="p-6 flex flex-col items-center justify-center gap-3">
        <!-- Loading state with progress -->
        <div class="flex items-center justify-center gap-3 w-full">
          <div class="relative w-12 h-12 flex items-center justify-center">
            <!-- Show different icon for parsing vs uploading -->
            <template v-if="percentage >= 100">
              <Icon icon="line-md:cloud-alt-print-filled-loop" class="w-10 h-10 text-cvus-primary" />
            </template>
            <template v-else>
              <Icon icon="eos-icons:loading" class="w-12 h-12 text-gray-300" />
              <div class="absolute inset-0 flex items-center justify-center">
                <span class="text-xs font-medium text-cvus-primary">{{ percentage ? Math.floor(percentage) : 0 }}%</span>
              </div>
            </template>
          </div>
          
          <div class="flex-1">
            <p class="font-medium text-cvus-primary text-xs max-[1310px]:text-[8px]">
              <template v-if="percentage >= 100">
                {{ $t("cv.components.uploadPanel.parsingDescription") }}<span>{{ loadingDots }}</span>
              </template>
              <template v-else>
                {{ LoadFilter() }}
              </template>
            </p>
            <p class="text-xs text-gray-500">
              {{ $tc('cv.components.uploadPanel.fileCount', files.length) }}
            </p>
          </div>
        </div>
        
        <!-- Progress bar -->
        <div class="w-full mt-2">
          <div class="w-full bg-gray-200 rounded-full h-2">
            <div 
              class="bg-cvus-primary h-2 rounded-full transition-all duration-300" 
              :style="{ width: `${percentage}%` }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cv } from "@/networking/urlmanager.js";
import axios from "axios";
import tourVue from "@/components/tour.vue";

export default {
  name: "uploadPanel",
  components: {
  },
  props: ["projects", "disabled"],
  data() {
    return {
      isDragging: false,
      files: [],
      load: false,
      invalidFiles: [],
      percentage: "",
      verify: false,
      dotsCount: 0,
      dotsInterval: null,
    };
  },
  computed: {
    loadingDots() {
      return '.'.repeat(this.dotsCount);
    }
  },
  methods: {
    async uploadFiles() {
      if (this.disabled) return;
      this.load = true;
      try {
        this.percentage = "";
        const res = await this.axios.post(cv.addExtractor);
        this.save(res.data.data);
      } catch (err) {
        this.load = false;
        this.authController(err);
      }
    },
    async save(rowId) {
      if (this.disabled) return;
      this.load = true;
      const files = this.files;
      const batchSize = 10;
      const token = this.$store.state.userData.token;
      let invalidFiles = [];
      this.$store.commit("setInvalidFiles", invalidFiles);

      try {
        const totalFiles = files.length;

        for (let start = 0; start < totalFiles; start += batchSize) {
          const end = Math.min(start + batchSize, totalFiles);
          const batch = files.slice(start, end);

          const formData = new FormData();
          batch.forEach((file) => {
            formData.append("file", file);
            this.$store.commit("setCvFile", file);
          });
          formData.append("extractId", rowId);
          formData.append("projects", JSON.stringify(this.projects));

          const res = await axios.post(cv.extractor, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: (progressEvent) => {
              console.log(progressEvent, "progressEvent")
              // Calculate progress for current batch
              const batchProgress = (progressEvent.loaded / progressEvent.total) * 100;
              
              // Calculate overall progress considering batch position
              const batchContribution = (batch.length / totalFiles) * 100;
              const previousBatchesContribution = (start / totalFiles) * 100;
              
              // Update the overall percentage
              this.percentage = previousBatchesContribution + (batchProgress * batchContribution / 100);
            }
          });

          if (res?.data?.data?.invalidFiles?.length > 0) {
            res.data.data.invalidFiles.map((r) => invalidFiles.push(r));
          }
          if (res?.data?.data?.addFiles?.length > 0) {
            this.$store.commit("setExtractState", 3);
          }
        }
        if (invalidFiles?.length > 0) {
          this.$store.commit("setInvalidFiles", invalidFiles);
          this.successUpload();
        } else {
          this.successUpload();
        }
      } catch (err) {
        this.load = false;
        this.authController(err);
      }
    },
    successUpload() {
      setTimeout(() => {
        this.load = false;
        this.successMessage();
        this.$emit("open-sidebar", true);
      }, 100);
    },
    onChange() {
      const selectedFiles = this.$refs.file.files;
      const allFilesAreValid = Array.from(selectedFiles).every((file) => {
        //const fileExtension = file.name.split(".").pop().toLowerCase();
        return true;
        //return ["pdf", "docx", "doc"].includes(fileExtension);
      });

      if (allFilesAreValid) {
        this.files = [...selectedFiles];
        this.uploadFiles();
      } else {
        this.warningMesage(this.$t("cv.components.uploadPanel.warningMessage"));
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    LoadFilter() {
      return this.load
        ? this.$t("cv.components.uploadPanel.loading") +
            (this.percentage ? Math.floor(this.percentage) : 0) +
            "%"
        : this.$t("cv.components.uploadPanel.parsing");
    },
    startDotsAnimation() {
      this.dotsCount = 0;
      this.dotsInterval = setInterval(() => {
        this.dotsCount = (this.dotsCount % 3) + 1;
      }, 500);
    },
    stopDotsAnimation() {
      if (this.dotsInterval) {
        clearInterval(this.dotsInterval);
        this.dotsInterval = null;
      }
    },
  },
};
</script>
<style scoped>
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}
</style>
