<template>
  <div>
    <customSelect
      v-model="selected"
      :options="filterList"
      :loading="load"
      :disabled="disabled"
      :searchable="true"
      @search="handleSearch"
      @scroll-bottom="loadMoreData"
      :return-object="true"
      :clearable="true"
     
    >
    </customSelect>
  </div>
</template>

<script>
import customSelect from "@/components/customSelect.vue";
import { utilityPosition } from "@/networking/urlmanager";

export default {
  props: ["value", "isEdit", "disabled"],
  components: {
    customSelect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: {},
      List: [],
      filterList: [],
      load: false,
      isActive: true,
      currentPage: 0,
      searchTerm: "",
      hasMoreData: true,
    };
  },
  methods: {
    addTag(newTag) {
      let tag = {
        name: newTag,
        id: newTag,
        status: 2,
      };
      this.selected = {};
      this.verifiedMessage(
        "'" +
          newTag +
          this.$t("project.components.positionList.warningMessage"),
        this.$t("project.components.positionList.warningMessageReply")
      ).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.axios.post(utilityPosition.add, {
              name: newTag,
            });

            tag.id = response.data.data;

            this.List.unshift(tag);
            this.filterList = [...this.List];
            this.selected = tag;
          } catch (err) {
            console.log(err);
            this.authController(err);
          }
        }
      });
    },
    
    handleSearch(searchTerm) {
      this.searchTerm = searchTerm;
      this.currentPage = 0;
      this.filterList = [];
      this.hasMoreData = true;
      this.getAll();
    },
    
    loadMoreData() {
      if (!this.load && this.hasMoreData) {
        this.currentPage++;
        this.getAll(true);
      }
    },

    async getAll(append = false) {
      if (!append) {
        this.filterList = [];
      }
      
      try {
        this.load = true;
        const response = await this.axios.get(utilityPosition.getAll, {
          params: {
            page: this.currentPage,
            search: this.searchTerm || undefined
          }
        });
        
        const data = response.data.data.detail;
        
        // If we received fewer items than expected or no items, we've reached the end
        if (!Array.isArray(data) || data.length === 0) {
          this.hasMoreData = false;
        } else {
          this.List = append ? [...this.List, ...data] : data;
          this.filterList = append ? [...this.filterList, ...data] : data;
        }
        
        this.load = false;
      } catch (error) {
        this.load = false;
        console.log(error);
      }
    },
  },
  async created() {
    await this.getAll();
    this.selected = this.value;
  },
  watch: {
    selected(val) {
      console.log(val);
      this.$emit("change", val);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
