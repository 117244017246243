<template>
  <div class="relative flex w-full flex-col gap-1">
    <!-- Label -->
    <label v-if="title || label" class="textcol font-semibold flex gap-1 items-center" :class="[
      { 'text-xs': size === 'sm' },
      { 'text-sm': size === 'md' },
      { 'text-base': size === 'lg' }
    ]">
      {{ title || label }}
      <span v-if="required" class="text-[10px]">
        <i class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600"></i>
      </span>
    </label>

    <!-- Select Button -->
    <div @click="toggleDropdown" class="w-full border bordercol h-full  rounded flex justify-between items-center" :class="[
      { 'py-1 px-2 text-xs mt-1': size === 'sm' },
      { 'py-2 px-3 text-sm mt-1': size === 'md' },
      { 'py-3 px-4 text-base mt-2': size === 'lg' },
      {
        '!border-red-500': error,
        'cursor-pointer': !loading && !disabled,
        'cursor-not-allowed opacity-75': loading || disabled
      }
    ]" ref="selectButton">
      <span class="truncate flex w-full h-full min-h-[20px] " :class="{ 'text-gray-400': (!selectedOption && !multiple) || (!selectedOptions.length && multiple) || disabled,
         
       }">
        <template v-if="multiple">
          <template v-if="selectedOptions.length">
            <div class="flex flex-wrap gap-1">
              <div v-for="(option, index) in selectedOptions" :key="`selected-${option.id}-${index}`" class="bg-gray-100 px-2 py-1 flex  rounded-md break-words whitespace-normal">
                {{ option.name }}
              </div>
            </div>
          </template>
          <template v-else>{{ (placeholder || $t('general.components.select.placeholder'))  }}</template>
        </template>
        <template v-else>
         <span v-if="selectedOption && selectedOption?.name">{{ selectedOption.name }}</span>
         <span v-else class="text-gray-400">{{ (placeholder || $t('general.components.select.placeholder'))  }}</span>
        </template>
      </span>
      <div class="flex items-center gap-2">
        <div v-if="loading" class="loading-spinner" :class="[
          { 'w-2 h-2': size === 'sm' },
          { 'w-3 h-3': size === 'md' },
          { 'w-4 h-4': size === 'lg' }
        ]"></div>
        <i v-else-if="clearable && !disabled && ((multiple && selectedOptions.length) || (!multiple && selectedOption))" 
           class="fas fa-times text-gray-400 cursor-pointer" 
           @click.stop="reset"></i>
        <i v-if="!loading" class="fas fa-chevron-down transition-transform flex-shrink-0" :class="[
          { 'text-xs': size === 'sm' },
          { 'text-sm': size === 'md' },
          { 'text-base': size === 'lg' },
          { 'rotate-180': isOpen }
        ]"></i>
      </div>
    </div>

    <!-- Overlay for capturing clicks outside when dropdown is open -->
    <Teleport to="body" v-if="isOpen && closeOnClickOutside">
      <div class="fixed inset-0 z-[9998]" @click.self="closeDropdown"></div>
    </Teleport>

    <!-- Dropdown with Teleport - make sure this has a higher z-index than the overlay -->
    <Teleport to="body">
      <transition name="dropdown">
        <div 
          v-if="isOpen" 
          class="fixed z-[9999] bg-white border bordercol2 rounded shadow-lg overflow-y-auto dropdown-options"
          :class="[
            { 'max-h-[150px]': size === 'sm' },
            { 'max-h-[200px]': size === 'md' },
            { 'max-h-[250px]': size === 'lg' }
          ]"
          :style="dropdownStyle"
          @scroll="handleDropdownScroll"
          ref="dropdownElement"
        >
          <!-- Search Input -->
          <div class="sticky top-0 bg-white border-b bordercol2 z-10">
            <div v-if="searchable" class="flex items-center">
              <div class="flex-shrink-0 ml-2">
                <Icon icon="mdi:magnify" :class="[
                  { 'text-xs': size === 'sm' },
                  { 'text-sm': size === 'md' },
                  { 'text-base': size === 'lg' }
                ]" class="text-gray-400" />
              </div>
              <input
                type="text"
                v-model="searchTerm"
                :placeholder="searchPlaceholder || $t('general.components.select.searchPlaceholder')"
                class="w-full border-none focus:outline-none"
                :class="[
                  { 'px-2 py-1 text-xs': size === 'sm' },
                  { 'px-3 py-2 text-sm': size === 'md' },
                  { 'px-4 py-3 text-base': size === 'lg' }
                ]"
                @input="handleSearch"
              />
            </div>
          </div>
          
          <!-- No Results Message - Add slot with fallback -->
          <div v-if="filteredOptions.length === 0 && !loading" class="px-3 py-2 text-gray-500 text-center" :class="[
            { 'text-xs': size === 'sm' },
            { 'text-sm': size === 'md' },
            { 'text-base': size === 'lg' }
          ]">
            <slot name="no-results">
              {{ $t('general.components.select.noElements') }}
            </slot>
          </div>
          <template v-else>
            <div 
              v-for="(option, index) in filteredOptions" 
              :key="`option-${option.id}-${index}`" 
              @click="!option.disabled && selectOption(option)"
              class="hover:bg-gray-50 border-b last:border-b-0 bordercol2 flex items-center justify-between" 
              :class="[
                { 'px-2 py-1 text-xs min-h-[30px]': size === 'sm' },
                { 'px-3 py-2 text-sm min-h-[36px]': size === 'md' },
                { 'px-4 py-3 text-base min-h-[44px]': size === 'lg' },
                { 'bg-gray-50': multiple ? isOptionSelected(option) : selectedOption?.id === option.id },
                { 'cursor-pointer': !option.disabled },
                { 'cursor-not-allowed opacity-60': option.disabled }
              ]"
            >
              <span>{{ option.name }}</span>
              <i v-if="multiple && isOptionSelected(option)" class="fas fa-check text-green-500"></i>
            </div>
            
            <!-- Bottom loading indicator -->
            <div v-if="loading" class="flex justify-center items-center py-2 border-t bordercol2">
              <div class="loading-spinner" :class="[
                { 'w-2 h-2': size === 'sm' },
                { 'w-3 h-3': size === 'md' },
                { 'w-4 h-4': size === 'lg' }
              ]"></div>
              <span class="ml-2 text-gray-500" :class="[
                { 'text-xs': size === 'sm' },
                { 'text-sm': size === 'md' },
                { 'text-base': size === 'lg' }
              ]">{{ $t('general.components.select.loadingMore') }}</span>
            </div>
          </template>
        </div>
      </transition>
    </Teleport>

    <!-- Error Message -->
    <span v-if="error" class="block mt-1 text-red-500" :class="[
      { 'text-[10px]': size === 'sm' },
      { 'text-xs': size === 'md' },
      { 'text-sm': size === 'lg' }
    ]">
      {{ errorMessage || $t('general.components.select.errorText') }}
    </span>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport'
import { debounce } from 'lodash'

export default {
  name: 'CustomSelect',
  components: {
    Teleport,
  },
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: value => ['sm', 'md', 'lg'].includes(value)
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: ""
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
      selectedOptions: [],
      error: false,
      dropdownStyle: {},
      searchTerm: '',
      hasCheckedScroll: false,
      loadingMore: false,
      debouncedSearch: null
    }
  },
  computed: {
    filteredOptions() {
      if (!this.searchTerm) return this.options;
      
      const search = this.searchTerm.toLowerCase();
      return this.options.filter(option => 
        option.name.toLowerCase().includes(search)
      );
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (this.multiple) {
          if (Array.isArray(newValue) && newValue.length) {
            if (this.returnObject) {
              // If returnObject is true, the value is already an array of objects
              this.selectedOptions = newValue;
            } else {
              // Otherwise, find the objects by ID
              this.selectedOptions = this.options.filter(option => 
                newValue.includes(option.id) || newValue.includes(option.value)
              );
            }
          } else {
            this.selectedOptions = [];
          }
        } else {
          if (newValue) {
            if (this.returnObject && typeof newValue === 'object') {
              // If returnObject is true and value is an object, use it directly
              this.selectedOption = newValue;
            } else {
              // Otherwise, find the object by ID
              this.selectedOption = this.options.find(option =>
                option.id === newValue || option.value === newValue
              );
            }
          } else {
            this.selectedOption = null;
          }
        }
      }
    },
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.updateDropdownPosition()
          this.searchTerm = ''
          this.hasCheckedScroll = false
          // Check if scrollable after dropdown is fully rendered
          this.$nextTick(() => {
            this.checkIfScrollable()
          })
        })
      }
    },
    selectedOptions: {
      handler() {
        if (this.isOpen) {
          this.$nextTick(() => {
            this.updateDropdownPosition()
          })
        }
      },
      deep: true
    },
    selectedOption() {
      if (this.isOpen) {
        this.$nextTick(() => {
          this.updateDropdownPosition()
        })
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    // Create debounced search function
    this.debouncedSearch = debounce((term) => {
      this.$emit('search', term)
    }, 300)
  },
  methods: {
    toggleDropdown() {
      if(!this.loading && !this.disabled){
        this.isOpen = !this.isOpen
        if (this.isOpen) {
          this.$emit('onOpen')
        } else {
          this.$emit('onClose')
        }
      }
    },
    
    closeDropdown() {
      this.isOpen = false
      this.$emit('onClose')
    },
    
    updateDropdownPosition() {
      const buttonEl = this.$refs.selectButton
      if (!buttonEl) return

      const rect = buttonEl.getBoundingClientRect()

      let dropdownHeight = 200
      if (this.size === 'sm') dropdownHeight = 150
      if (this.size === 'lg') dropdownHeight = 250

      const windowHeight = window.innerHeight
      const spaceBelow = windowHeight - rect.bottom
      const spaceAbove = rect.top

      this.dropdownStyle = {
        position: 'fixed',
        top: `${rect.bottom + 4}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        maxHeight: `${dropdownHeight}px`,
        visibility: 'hidden'
      }

      this.$nextTick(() => {
        const dropdownEl = document.querySelector('.dropdown-options')
        if (!dropdownEl) return

        const actualHeight = Math.min(dropdownEl.scrollHeight, dropdownHeight)
        const openUpwards = spaceBelow < actualHeight && spaceAbove > spaceBelow

        this.dropdownStyle = {
          position: 'fixed',
          top: openUpwards ? `${rect.top - actualHeight - 4}px` : `${rect.bottom + 4}px`,
          left: `${rect.left}px`,
          width: `${rect.width}px`,
          maxHeight: `${dropdownHeight}px`,
          visibility: 'visible'
        }

        if (openUpwards) {
          this.dropdownStyle.transformOrigin = 'bottom'
        } else {
          this.dropdownStyle.transformOrigin = 'top'
        }
      })
    },
    handleScroll() {
      if (this.isOpen) {
        this.updateDropdownPosition()
      }
    },
    handleResize() {
      if (this.isOpen) {
        this.updateDropdownPosition()
      }
    },
    handleSearch() {
      // Use the debounced function instead of emitting directly
      this.debouncedSearch(this.searchTerm)
    },
    
    handleDropdownScroll(event) {
      const el = event.target
      // Check if scrolled to bottom (with a small threshold)
      if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
        this.$emit('scroll-bottom')
       
      }
    },
    
    checkIfScrollable() {
      if (this.hasCheckedScroll) return
      
      const dropdownEl = this.$refs.dropdownElement
      if (!dropdownEl) return
      
      // If the element is not scrollable (scrollHeight equals clientHeight)
      if (dropdownEl.scrollHeight <= dropdownEl.clientHeight) {
        this.$emit('scroll-bottom')
        
       
      }
      
      this.hasCheckedScroll = true
    },
    
    isOptionSelected(option) {
      return this.selectedOptions.some(selected => selected.id === option.id);
    },
    
    selectOption(option) {
      if (this.multiple) {
        const index = this.selectedOptions.findIndex(item => item.id === option.id);
        if (index === -1) {
          // Add option
          this.selectedOptions.push(option);
        } else {
          // Remove option
          this.selectedOptions.splice(index, 1);
        }
        
        this.error = false;
        if (this.returnObject) {
          this.$emit('input', this.selectedOptions);
        } else {
          const ids = this.selectedOptions.map(opt => opt.id || opt.value);
          this.$emit('input', ids);
        }
        this.$emit('change', this.selectedOptions);
      } else {
        this.selectedOption = option;
        this.isOpen = false;
        this.error = false;
        if (this.returnObject) {
          this.$emit('input', option);
        } else {
          this.$emit('input', option.id || option.value);
        }
        this.$emit('change', option);
      }
    },
    
    reset() {
      if (this.multiple) {
        this.selectedOptions = [];
        this.$emit('input', this.returnObject ? [] : []);
      } else {
        this.selectedOption = null;
        this.$emit('input', null);
      }
      this.error = false;
    },
   
  }
}
</script>

<style scoped>
.bordercol2 {
  border-color: #e2e8f0;
}

.loading-spinner {
  border: 2px solid #e2e8f0;
  border-top-color: #64748b;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Animation for dropdown */
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}

/* Scrollbar Styling */
.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
</style>