<template>
  <div class="max-sm:overflow-x-auto max-sm:my-4">
    <div id="chart" class="max-md:w-[600px]">
      <apexchart
        ref="chart"
        type="line"
        height="500"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { general } from "@/networking/urlmanager";
export default {
  name: "chart-costum",
  props: ["img"],
  data() {
    return {
      series: [],
      chartOptions: {},
      monthLabels: [],
    };
  },
  methods: {
    getresumeAll() {
      this.axios.get(general.getresumeAll)
        .then((res) => {
          const list = res.data.data;

          const categories = [];
          const activeCounts = [];
          const isFavoriteCounts = [];
          const blackListedCounts = [];

          for (let i = 1; i <= 12; i++) {
            const monthData = list.find((item) => item.month === i);

            const monthLabel = this.monthLabels[i - 1];

            categories.push(monthLabel);
            activeCounts.push(monthData ? monthData.activeCount : 0);
            isFavoriteCounts.push(monthData ? monthData.isFavoriteCount : 0);
            blackListedCounts.push(monthData ? monthData.blackListedCount : 0);
          }

          this.chartOptions.xaxis.categories = categories;
          this.series = [
            {
              name: this.$t("general.components.cvChart.seriesList.active"),
              data: activeCounts,
            },
            {
              name: this.$t("general.components.cvChart.seriesList.favorite"),
              data: isFavoriteCounts,
            },
            {
              name: this.$t("general.components.cvChart.seriesList.unsuitable"),
              data: blackListedCounts,
            },
          ];
        })
        .catch((error) => {
          console.log("Veri alınamadı:", error);
        });
    },
    getChartOptions() {
      return {
        chart: {
          height: 400,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#fff",
            top: 5,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#5070b6", "#50b696", "#b0bec5"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: this.$t("general.components.cvChart.resumeChart"),
          align: "left",
          style: {
            fontSize: "12px",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.monthLabels,
          title: {
            text: this.$t("general.components.cvChart.months"),
          },
          labels: {
            rotate: -45,
            trim: false,
            maxHeight: 120,
            style: {
              fontSize: "12px",
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      };
    },
    updateMonthLabels() {
      this.monthLabels = [
        this.$t("general.components.cvChart.monthsList.january"),
        this.$t("general.components.cvChart.monthsList.february"),
        this.$t("general.components.cvChart.monthsList.march"),
        this.$t("general.components.cvChart.monthsList.april"),
        this.$t("general.components.cvChart.monthsList.may"),
        this.$t("general.components.cvChart.monthsList.june"),
        this.$t("general.components.cvChart.monthsList.july"),
        this.$t("general.components.cvChart.monthsList.august"),
        this.$t("general.components.cvChart.monthsList.september"),
        this.$t("general.components.cvChart.monthsList.october"),
        this.$t("general.components.cvChart.monthsList.november"),
        this.$t("general.components.cvChart.monthsList.december"),
      ];
    },
  },
  watch: {
    "$i18n.locale": {
      handler() {
        this.updateMonthLabels();

        const xAxistitle = {
          text: this.$t("general.components.cvChart.months"),
        };

        const title = {
          text: this.$t("general.components.cvChart.resumeChart"),
        };

        if (this.$refs.chart) {
          this.$refs.chart.updateOptions({
            title: title,
            xaxis: {
              categories: this.monthLabels,
              title: xAxistitle,
            },
          });
        }

        if (this.series && this.series.length) {
          this.series = [
            {
              name: this.$t("general.components.cvChart.seriesList.active"),
              data: this.series[0].data,
            },
            {
              name: this.$t("general.components.cvChart.seriesList.favorite"),
              data: this.series[1].data,
            },
            {
              name: this.$t("general.components.cvChart.seriesList.unsuitable"),
              data: this.series[2].data,
            },
          ];
        }
      },
      immediate: true,
    },
  },
  created() {
    this.updateMonthLabels();
    this.chartOptions = this.getChartOptions();
    this.getresumeAll();
  },
};
</script>
