<template>
  <span
    class="rounded-full whitespace-nowrap"
    :class="[variantClasses, sizeClasses]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'Badge-component',
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['success', 'warning', 'error', 'default', 'info', 'primary'].includes(value)
      }
    },
    size: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['small', 'default', 'large'].includes(value)
      }
    }
  },
  computed: {
    variantClasses() {
      const variants = {
        success: 'bg-green-100 text-green-800',
        warning: 'bg-yellow-100 text-yellow-800',
        error: 'bg-red-100 text-red-800',
        default: 'bg-gray-100 text-gray-800',
        info: 'bg-cvus-primary-light text-white',
        primary: 'bg-cvus-primary text-white'
      }
      return variants[this.variant]
    },
    sizeClasses() {
      const sizes = {
        small: 'text-xs px-1.5 py-0.5',
        default: 'text-xs px-2 py-1',
        large: 'text-sm px-2.5 py-1.5'
      }
      return sizes[this.size]
    }
  }
}
</script> 