<template>
  <div class="w-full">
    <label class="textcol font-semibold flex gap-1 items-center"
      >{{ $t("project.components.educationSelect.title") }}
      <p class="text-[10px]">
        <i
          class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600"
        ></i>
      </p>
    </label>
    <multiselect
      ref="multiselect"
      v-model="selected"
      :options="List"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="true"
      :clear-after-select="true"
      :preserve-search="false"
      :label="'name'"
      track-by="id"
      :placeholder="
        $t('project.components.educationSelect.multiselect.notselected')
      "
      :selectLabel="$t('project.components.educationSelect.multiselect.choose')"
      :selectedLabel="
        $t('project.components.educationSelect.multiselect.chosen')
      "
      :deselectLabel="
        $t('project.components.educationSelect.multiselect.deselect')
      "
      openDirection="bottom"
      :preselect-first="false"
      :taggable="true"
      :internal-search="false"
      @search-change="asyncFind"
      :disabled="disabled"
      :class="highlight ? '*:!border-red-500' : ''"
    >
      >
      <template v-slot:selection="{ values }">
        {{
          values.length
            ? values.length +
              " " +
              $t("project.components.educationSelect.educationSelected")
            : ""
        }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Fuse from "fuse.js";

export default {
  name: "select-education-type",
  props: ["value", "disabled", "highlight"],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selected: [],
      List: this.$t("project.components.educationSelect.list"),
      internalSearch: null,
      fuse: null,
    };
  },
  methods: {
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item);
      this.filterList = newFilterList;
    },
  },
  created() {
    this.selected = this.value;

    this.fuse = new Fuse(this.List, {
      keys: ["name"],
      includeScore: false,
      threshold: 0.6,
    });
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selected = val;
    },
    "$i18n.locale"() {
      this.List = this.$t("project.components.educationSelect.list");

      this.fuse = new Fuse(this.List, {
        keys: ["name"],
        includeScore: false,
        threshold: 0.3,
      });
    },
  },
};
</script>
