<template>
  <Teleport to="body">
    <div v-if="show && !isMobile" class=" flex-col items-center  opacity-0 transition-all duration-200 ease"
      :style="tooltipStyle" ref="tooltip">
      <div class="w-3 h-3 -mb-2 rotate-45 bg-black relative  translate-x-[-50%]" v-if="top"></div>
      <div class="relative text-center p-2 text-xs text-white w-auto max-w-[260px] break-words bg-black shadow-lg rounded">
        {{ title }}
      </div>
      <div class="w-3 h-3 -mt-2 rotate-45 bg-black relative  translate-x-[-50%]" v-if="!top"></div>
    </div>
  </Teleport>
</template>


<script>
import Teleport from 'vue2-teleport';

export default {
  name: "tool-tip",

  components: {
    Teleport,
  },
  props: ["title", "top","offset"],

  data() {
    return {
      show: false,
      tooltipStyle: {},
      triggerElement: null,
      parentElement: null
    }
  },
  mounted() {
    const parent = this.$el.parentElement;
    this.parentElement = parent;
    if (parent) {
      parent.addEventListener('mouseenter', this.showTooltip);
      parent.addEventListener('mouseleave', this.hideTooltip);
    }
  },
  beforeDestroy() {
    if (this.parentElement) {
      this.parentElement.removeEventListener('mouseenter', this.showTooltip);
      this.parentElement.removeEventListener('mouseleave', this.hideTooltip);
    }
  },
  methods: {
    showTooltip(e) {
      if (this.isMobile) return;
      // First show the tooltip

      // Then wait for the next tick to get height
      const parentRect = this.parentElement.getBoundingClientRect();
      const offset = this.offset ? this.offset : this.top ? 15 : -30;
      const heightOfTooltip = this.$refs.tooltip?.clientHeight || 0;
      //if top is false translate y to bottom
        this.tooltipStyle = {
          position: 'fixed',
          left: `${parentRect.left + (parentRect.width / 2)}px`,
          top:  `${parentRect.top + (parentRect.height + offset)}px`,
          transform: this.top ? 'translateX(-45%)' : 'translate(-45%, -100%)',
          zIndex: 99999,
          display: 'flex',
          opacity: 1
        }

        this.show = true;



      },
        hideTooltip() {
        this.show = false;
      }

  },
    computed: {
      heightOfTooltip() {
        return this.$refs.tooltip?.clientHeight || 0;
      }
    }
  }
</script>


<style scoped>
.tooltip-enter-active,
.tooltip-leave-active {
  transition: opacity 0.2s ease;
}

.tooltip-enter-from,
.tooltip-leave-to {
  opacity: 0;
}
</style>
