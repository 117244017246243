<template>
    <modal
      name="note-profile"
      :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '40%'"
      :scrollable="true"
    >
      <div
        class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
      >
        <h1 class="text-xl">{{ $t("cv.profile.components.note.note") }}</h1>
        <button @click="hide()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <form
        @submit.prevent="save()"
        class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
        novalidate
      >
        <div class="space-y-4 pb-3">
          
  
          <customInput
            v-model="note"
            type="textarea"
            max="1000"
            :placeholder="$t('cv.profile.components.note.notePlacholder')"
            :title="$t('cv.profile.components.note.title')"
            :rows="10"
            :disabled="load"
          />
  
  
          <div
            class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
          >
            <div class="w-full">
              <buttonItem
                :load="load"
                :name="$t('cv.profile.components.note.save')"
                buttonClass="w-full"
              />
            </div>
          </div>
        </div>
      </form>
    </modal>
  </template>
  
  <script>
  import customInput from "@/components/customInput.vue";
  import buttonItem from "@/components/button.vue";

  
  import { cv, utilityPosition } from "@/networking/urlmanager.js";
  
  export default {
    name: "addProject",
    props: ["isEdit"],
    components: {
      customInput,
      buttonItem,
    },
    data() {
      return {
        isModalOver: false,
        load: false,
        note: "",
      };
    },
    methods: {
      show() {
        this.note= ''
        this.getInformation();
        this.$modal.show("note-profile");
      },
      hide() {
        this.$modal.hide("note-profile");
      },
      save() {
          this.load = true;
          this.axios
            .post(cv.note, {
              rowId: this.$route.params.id,
              note: this.note || "",
            })
            .then((res) => {
              this.successMessage();
              this.load = false;
              this.$emit('refresh')
              this.hide();
            })
            .catch((err) => {
              console.error(err);
              this.authController(err);
              this.load = false;
            });
       
        this.hide();
      }, 
      getInformation() {
  this.load = true
  this.axios
    .get(cv.getInformation + "?cvId=" + this.$route.params.id)
    .then((res) => {
      // Set the note value from the response
      this.note = res.data.data[0].cvNote || '';
      
  
    })
    .catch((err) => {
      console.error(err);
      this.authController(err);
    })
    .finally(() => {
      this.load = false
    });
},
    },
  };
  </script>
  