<template>
  <div v-if="isOpen">
    <modal :name="customName ? customName : 'assessmentModalName'" @close="handleClose" :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '60%'" :scrollable="true" class="scroltype">
      <div class="flex justify-between card-header p-5 bgcol w-full md:rounded-t text-white z-50">
        <h1 class="">{{ $t(mode === 'sendAssessment' ? "project.components.assessment.sendAssessmentTitle" :
          "project.components.assessment.title") }}</h1>
        <button @click="handleClose()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4 h-full overflow-y-auto max-h-[calc(100vh-300px)]">
        <!-- Description - only shown in settings mode -->
        <p v-if="mode === 'settings'" class="text-sm text-gray-700 bg-gray-100 p-4 rounded-md">
          {{ $t("project.components.assessment.description") }}
        </p>

        <!-- Candidate Information - only shown in sendAssessment mode -->
        <div v-if="mode === 'sendAssessment' && candidateInfo" class="bg-blue-50 p-4 rounded-md mb-2">
          <h3 class="font-medium text-lg mb-2">{{ $t("project.components.assessment.candidateInfo") }}</h3>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div class="flex flex-col">
              <span class="text-sm text-gray-600">{{ $t("project.components.assessment.name") }}:</span>
              <span class="font-medium">{{ candidateInfo.fullname }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-sm text-gray-600">{{ $t("project.components.assessment.email") }}:</span>
              <span class="font-medium">{{ parseEmail(candidateInfo.contactEmail) }}</span>
            </div>
          </div>
          
          <!-- Date Range Fields for sendAssessment mode -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div class="flex flex-col">
              <customInput 
                type="date" 
                v-model="startDate" 
                :label="$t('project.components.assessment.startDate')" 
                :placeholder="$t('project.components.assessment.selectDate')"
                :required="true"
                clearable
                :oldDate="true"
              />
            </div>
            <div class="flex flex-col">
              <customInput 
                type="date" 
                v-model="endDate" 
                :label="$t('project.components.assessment.endDate')" 
                :placeholder="$t('project.components.assessment.selectDate')"
                :required="true"
                clearable
                :oldDate="true"
              />
            </div>
          </div>
        </div>

        <!-- Content Area: Loading, Categories, or No Data Message -->
        <template v-if="fetchLoading">
          <!-- Loading UI for fetch operations -->
          <div class="flex flex-col items-center justify-center py-8 h-64">
            <div class="animate-spin rounded-full h-12 w-12 border-4 border-cvus-primary border-t-transparent mb-4"></div>
            <p class="text-gray-600 font-medium">{{ $t("general.loading")}}</p>
           
          </div>
        </template>
        <template v-else>
          <!-- Assessment Categories -->
          <template v-if="assessmentCategories.length > 0">
            <customCard v-for="(category, categoryIndex) in assessmentCategories" :key="categoryIndex"
            v-show="mode === 'sendAssessment' ? category.showInitial : true"
              :title="`${category.title} (${getSelectedCount(category)})`" size="sm" isAccordion variant="primary"
              class="flex" :defaultOpen="true">
              <div class="min-h-[100px] grid grid-cols-2 overflow-y-auto ">
                <!-- Subcategories -->
                <div v-for="(subcategory, subcategoryIndex) in category.subcategories" :key="subcategoryIndex" class="ml-2" v-show="mode === 'sendAssessment' ? subcategory.showInitial : true">
                  <div class="font-medium text-gray-700 mb-2">{{ subcategory.name }}</div>

                  <!-- Steps -->
                  <div class="space-y-3" >
                    <div v-for="(step, stepIndex) in subcategory.steps" :key="stepIndex"
                      class="border-l-2 border-gray-200 pl-3">
                      <div class="flex items-start mb-2">
                        <customCheckbox class="font-bold" :id="`step-${categoryIndex}-${subcategoryIndex}-${stepIndex}`"
                          v-model="step.selected" :label="step.name" />
                      </div>

                      <!-- Assessments (non-selectable, just display) -->
                      <div v-if="step.assessments && step.assessments.length" class="ml-6 text-sm text-gray-600">
                        <ul class="list-disc ml-4">
                          <li v-for="(assessment, aIndex) in step.assessments" :key="aIndex" v-show="mode === 'sendAssessment' ? step.showInitial : true" >
                            {{ assessment.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </customCard>
          </template>
          <!-- No assessments message -->
          <div v-else class="bg-gray-100 p-6 rounded-md text-center">
            <p class="text-gray-600">{{ $t("project.components.assessment.noAssessments") || "No assessments available" }}</p>
          </div>
        </template>
      </div>
      <div class="flex justify-end gap-3 p-4 border-t border-gray-300">
        <button @click="handleClose" class="px-4 py-2 border border-gray-300 rounded text-sm min-w-[100px]">
          {{ $t('general.cancel') }}
        </button>
        <asyncButton :text="mode === 'sendAssessment' ? $t('general.send') : $t('general.save')" :load="saveLoading"
          @click="handleSubmit" buttonClass="text-sm text-white" containerBackground="bg-cvus-primary-light"
          containerClass="min-w-[100px]" :disabled="fetchLoading" />
      </div>
    </modal>
  </div>
</template>

<script>
import asyncButton from "@/components/button.vue";
import customCard from "@/components/customCard.vue";
import customCheckbox from "@/components/customCheckbox.vue";
import customInput from "@/components/customInput.vue";
import { assessments } from "@/networking/urlmanager";
export default {
  name: "AssessmentModal",
  components: {
    asyncButton,
    customCard,
    customCheckbox,
    customInput
  },
  props: {
    projectId: {
      type: [String, Number],
      default: null
    },
    mode: {
      type: String,
      default: 'settings', // 'settings' or 'sendAssessment'
      validator: value => ['settings', 'sendAssessment'].includes(value)
    },
    candidateInfo: {
      type: Object,
      default: null
    },
    customName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      fetchLoading: false,
      saveLoading: false,
      isOpen: false,
      assessmentCategories: [],
      startDate: null,
      endDate: null
    };
  },
  mounted() {

  },

  methods: {
    show() {
      this.isOpen = true;
      this.$nextTick(() => {
        const name = this.customName ? this.customName : 'assessmentModalName';
        this.$modal.show(name);
        // Load assessment data when component is mounted
        this.fetchAssessmentData();
      });
    },
    handleClose() {
      if (this.saveLoading) return; // Prevent closing during save operation
      const name = this.customName ? this.customName : 'assessmentModalName';
      this.$modal.hide(name);
      this.$nextTick(() => {
        this.isOpen = false;
      });
    },

    // Fetch assessment data from API
    fetchAssessmentData() {
      this.fetchLoading = true;
      
      this.axios.get(assessments.getAll)
        .then(response => {
          // Process the API response
          const processedData = this.processAssessmentData(response.data.data || []);
          this.assessmentCategories = processedData;
          this.fetchLoading = false;

          if (this.projectId) {
            
            // Also fetch previously selected steps if projectId is available
            this.fetchSelectedSteps();
          }
        })
        .catch(error => {
          console.error('Error fetching assessment data:', error);
          this.assessmentCategories = [];
          this.fetchLoading = false;

        })
        .finally(() => {
        });
    },

    // Process the assessment data and add selected property to each step
    processAssessmentData(data) {
      return data.map(category => {
        const processedCategory = { ...category };

        processedCategory.subcategories = category.subcategories.map(subcategory => {
          const processedSubcategory = { ...subcategory };

          processedSubcategory.steps = subcategory.steps.map(step => {
            return {
              ...step,
              selected: false // Add selected property to each step instead of assessments
            };
          });

          return processedSubcategory;
        });

        return processedCategory;
      });
    },

    // Get count of selected steps for a category
    getSelectedCount(category) {
      let count = 0;
      category.subcategories.forEach(subcategory => {
        subcategory.steps.forEach(step => {
          if (step.selected) count++;
        });
      });
      return count;
    },

    // Fetch previously selected steps
    fetchSelectedSteps() {
      // Using fetch loading state, not updating if already loading
      if (this.fetchLoading) return;
      this.fetchLoading = true;
      this.axios.get(assessments.getSelected, {
        params: {
          projectId: this.projectId
        }
      })
      .then(response => {
        const selectedData = response.data.data || [];
        this.processSelectedSteps(selectedData);
      })
      .catch(error => {
        console.error('Error fetching selected assessments:', error);
      })
      .finally(() => {
        this.fetchLoading = false;
      });
    },

    // Process selected steps from getSelected API
    processSelectedSteps(selectedData) {
      if (!selectedData || !selectedData.length) return;
      
      // Iterate through categories in the selected data
      selectedData.forEach(selectedCategory => {
        // Find the matching category in our assessment categories
        const category = this.assessmentCategories.find(c => 
          c.title === selectedCategory.title && c.providerId === selectedCategory.providerId
        );
      
        console.log(category)
        if (category) {
          // Iterate through selected subcategories
          selectedCategory.subcategories.forEach(selectedSubcategory => {
            // Find matching subcategory in our data
            const subcategory = category.subcategories.find(s => 
              s.flowId === selectedSubcategory.flowId
            );
            console.log(subcategory,'subcategory')
            if (subcategory) {
              // Store projectAssessmentId in the subcategory
              subcategory.projectAssessmentId = selectedSubcategory.projectAssessmentId;
              subcategory.assessmentId = selectedSubcategory.id;
              
              // Iterate through selected steps
              selectedSubcategory.steps.forEach(selectedStep => {
                // Find and mark the step as selected
                const step = subcategory.steps.find(s => s.id === selectedStep.stepId);
                console.log(step,'step')
                if (step) {
                  step.selected = true;
                  // Store the step's DB ID
                  step.assessmentStepId = selectedStep.id;
                  step.showInitial = true
                }
              });
              if(this.anyChildSubcategorySelected(subcategory)){
                subcategory.showInitial = true
              }
            }
          });
        }
        if(this.anyChildSelected(category)){
          category.showInitial = true
        }
      });

    },

    // Handle submission based on mode
    handleSubmit() {
      if (this.fetchLoading || this.saveLoading) return; // Prevent multiple submissions
      
      if (this.mode === 'settings') {
        this.saveAssessments();
      } else if (this.mode === 'sendAssessment') {
        this.sendExams();
      }
    },

    saveAssessments() {
      this.saveLoading = true;

      // Get selected steps
      const selectedSteps = this.getSelectedStepsData();
      
      // Format data according to the required structure
      const formattedData = {
        projectId: this.projectId,
        assessments: this.formatAssessmentsForSaving(selectedSteps)
      };

      this.axios.post(assessments.setSelected, formattedData)
        .then(response => {
          this.successMessage();
            this.fetchSelectedSteps();
        })
        .catch(error => {
          console.error('Error saving assessments:', error);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    // New method to format the data according to the required structure
    formatAssessmentsForSaving(selectedSteps) {
      // Group steps by provider and flow
      const assessmentsByFlow = {};
      
      selectedSteps.forEach(step => {
        // Find the category and subcategory in our data
        const category = this.assessmentCategories.find(c => c.title === step.category);
        if (!category) return;
        
        const providerId = category.providerId;
        const subcategory = category.subcategories.find(s => s.flowId === step.flowId);
        if (!subcategory) return;
        
        // Create a unique key for this flow
        const flowKey = `${providerId}-${step.flowId}`;
        
        // Initialize the assessment object if it doesn't exist
        if (!assessmentsByFlow[flowKey]) {
          assessmentsByFlow[flowKey] = {
            providerId: providerId,
            externalId: step.flowId,
            title: subcategory.name,
            isFlow: providerId === 1, // Only set isFlow to true if providerId is 1
            steps: []
          };
        }
        
        // Add the step
        assessmentsByFlow[flowKey].steps.push({
          externalStepId: step.stepId,
          title: step.stepName,
        });
      });
      
      // Convert the object to an array
      return Object.values(assessmentsByFlow);
    },

    sendExams() {
      this.saveLoading = true;

      // Validate dates for sendAssessment mode
      if (this.mode === 'sendAssessment') {
        // Check if dates are provided
        if (!this.startDate || !this.endDate) {
          this.warningMesage(this.$t('project.components.assessment.dateRequired'));
          this.saveLoading = false;
          return;
        }
        
        // Validate date logic
        const dateValidation = this.validateDates();
        if (!dateValidation.valid) {
          this.warningMesage(dateValidation.message);
          this.saveLoading = false;
          return;
        }
      }

      // Get selected steps/exams
      const selectedSteps = this.getSelectedStepsData();
      
      // Format assessments array in the required format
      const formattedAssessments = selectedSteps.map(step => {
        const category = this.assessmentCategories.find(c => c.title === step.category);
        const subcategory = category?.subcategories.find(s => s.flowId === step.flowId);
        
        return {
          flowId: step.flowId,
          stepId: step.stepId,
          assessmentStepId: step.assessmentStepId,
          assessmentId: subcategory?.assessmentId || null,
          projectAssessmentId: subcategory?.projectAssessmentId || null
        };
      });

      // Create request payload
      const examPayload = {
        assessments: formattedAssessments,
        cvId: this.candidateInfo.cvId,
        fullName: this.candidateInfo.fullname,
        email: this.parseEmail(this.candidateInfo.contactEmail) != '-' ? this.parseEmail(this.candidateInfo.contactEmail) : null, 
        startDate: this.startDate ? new Date(this.startDate).toISOString() : null,
        endDate: this.endDate ? new Date(this.endDate).toISOString() : null,
        projectId: this.projectId
      };

      this.axios.post(assessments.sendAssessment, examPayload)
        .then(response => {
          this.successMessage(response.data.message);
          this.saveLoading = false;

          this.handleClose();
          this.startDate = null;
          this.endDate = null;
          this.$emit('refreshHistory');
          
        })
        .catch(error => {
          console.error('Error sending exams:', error);
          this.authController(error)
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    // Method to validate dates
    validateDates() {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to beginning of the day
      
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      
     
      
      // Check if end date is after start date
      if (endDate <= startDate) {
        return {
          valid: false,
          message: this.$t('project.components.assessment.endDateAfterStart')
        };
      }
      
      return { valid: true };
    },

    // Helper method to get selected steps data
    getSelectedStepsData() {
      const selectedSteps = [];
      this.assessmentCategories.forEach(category => {
        category.subcategories.forEach(subcategory => {
          subcategory.steps.forEach(step => {
            if (step.selected) {
              selectedSteps.push({
                category: category.title,
                flowId: subcategory.flowId,
                stepId: step.id,
                stepName: step.name,
                assessments: step.assessments,
                assessmentStepId: step.assessmentStepId
              });
            }
          });
        });
      });
      return selectedSteps;
    },

    anyChildSelected(category) {
      return category.subcategories.some(subcategory => subcategory.steps.some(step => step.selected));
    },
    anyChildSubcategorySelected(subcategory) {
      return subcategory.steps.some(step => step.selected);
    },
   

  }
};
</script>

<style scoped>
.bgcol {
  background-color: var(--cvus-primary);
}
</style>