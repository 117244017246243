<template>
  <div class="checkbox-wrapper" @click="toggle">
    <div 
      class="checkbox" 
      :class="{ 
        'checked': value,
        'disabled': disabled 
      }"
    >
    <Icon 
        v-if="value"
        icon="ph:check-bold" 
        class="check-icon"
      />
    </div>
    <label v-if="label" class="label" :class="{ 'disabled': disabled }">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>

<style scoped>
.checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #e2e8f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background: white;
  min-width: 20px;
  min-height: 20px;
}

.checkbox:hover:not(.disabled) {
  @apply bg-cvus-primary/25 border-cvus-primary/25;
}

.checkbox.checked {
  @apply bg-cvus-primary border-cvus-primary shadow-md shadow-cvus-primary-light/50;
}

.checkbox.checked:hover:not(.disabled) {
  @apply bg-cvus-primary/80 border-cvus-primary/80;
}

.checkbox.disabled {
  background: #f1f5f9;
  border-color: #e2e8f0;
  cursor: not-allowed;
}

.check-icon {
  color: white;
  font-size: 12px;
  animation: scale-in 0.2s ease;
  position: absolute;
}

.label {
  font-size: 14px;
  color: #1e293b;
}

.label.disabled {
  color: #94a3b8;
  cursor: not-allowed;
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style> 