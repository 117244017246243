<template>
  <div
    class="flex justify-center items-center bg-gray-100"
    style="height: calc(100vh - 96px)"
  >
    <div
      class="bg-white shadow-lg border border-gray-200 rounded-lg p-4 sm:p-8 m-2 sm:m-4 w-full sm:w-2/4 h-full sm:h-3/4 mx-auto flex flex-col items-center justify-center"
    >
      <div v-if="load" class="text-center">
        <i
          class="fas fa-spinner fa-spin text-slate-500 text-[75px] sm:text-[80px] animate-spin"
        ></i>
        <p class="mt-4 text-lg sm:text-xl text-gray-700">
          {{ $t("oauth.outlook.loading") }}
        </p>
      </div>
      <div v-else-if="isTokenApproved" class="text-center">
        <i
          class="fas fa-check-circle text-green-500 text-[75px] sm:text-[80px]"
        ></i>
        <p class="mt-4 text-lg sm:text-xl text-gray-700">
          {{ $t("oauth.outlook.permissions") }}
        </p>
      </div>
      <div v-else class="text-center">
        <i
          class="fas fa-exclamation-circle text-red-500 text-[75px] sm:text-[80px]"
        ></i>
        <p class="mt-4 text-lg sm:text-xl text-gray-700">
          {{ $t("oauth.outlook.error") }}
        </p>
      </div>
      <p class="mt-4 w-full sm:w-3/4 text-gray-500 text-center">
        {{ message }}
      </p>
      <button
        @click="goBack"
        class="mt-4 bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded"
      >
        {{ $t("oauth.outlook.goBack") }}
      </button>
    </div>
  </div>
</template>

<script>
import { technicalInterviewMicrosoft } from "@/networking/urlmanager";
export default {
  name: "technical-interview-microsoft-modal",
  data() {
    return {
      isTokenApproved: false,
      load: true,
      message: this.$t("oauth.outlook.loading"),
    };
  },
  methods: {
    getAccessToken() {
      try {
        this.load = true;
        this.message = this.$t("oauth.outlook.accessingAccount");
        this.axios
          .post(technicalInterviewMicrosoft.authorize, {
            code: this.$route.query.code,
          })
          .then((res) => {
            this.$store.commit("setIsMicrosoftRefresh", false);
            this.$store.commit("setIntegrationMicrosoft", res.data.data);
            this.isTokenApproved = true;
            this.load = false;
            this.message = this.$t("oauth.outlook.successMessage");
            setTimeout(() => {
              window.close();
            }, 5000);
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.message = this.$t("oauth.outlook.errorMessage");
            this.load = false;
          });
      } catch (error) {
        console.log("hata ", error);
      }
    },
    goBack() {
      window.close();
    },
  },
  created() {
    this.getAccessToken();
  },
};
</script>
