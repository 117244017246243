<template>
  <div class="bg-[#071238]" style="height: calc(100vh - 120px)">
    <div class="flex h-full p-6 gap-3">
      <div class="w-6/12 grid grid-cols-2 gap-3 grid-rows-3">
        <div
          v-for="item in live"
          :key="item"
          :class="item.id == 2 && 'border-2 border-slate-300'"
          class="w-full bg-cvus-primary h-full bg-cover object-cover rounded bg-[url('@/assets/meeting/liveMeeting.png')] relative"
        >
          <div class="flex absolute bottom-0 justify-between w-full">
            <div
              class="px-3 py-0.5 bg-black bg-opacity-50 text-white rounded-t text-xs flex gap-1"
            >
              <img src="@/assets/meeting/verify.svg" alt="" />
              <h3>Natura</h3>
            </div>
            <div
              class="px-3 py-0.5 bg-black bg-opacity-50 rounded flex items-center"
            >
              <img
                src="@/assets/meeting/noSound.svg"
                alt=""
                class="bg-cover w-[12px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-6/12 bg-slate-500 h-full rounded bg-cover bg-[url('@/assets/meeting/myUser.png')] border-2 border-slate-300"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "appearanceOne",
  data() {
    return {
      live: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
      ],
    };
  },
};
</script>
