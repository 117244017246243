<template>
  <div class="group relative max-sm:static" ref="notification">
    <div class="flex items-center">
      <button @click.stop="toggleDropdown">
        <icon icon="mdi:bell" class="text-slate-300 text-xl" />
      </button>
    </div>
    <div v-show="isNotification" tabindex="0"

      class="absolute bg-[#fdfdfe] pb-1 w-72 max-sm:w-full max-sm:left-0 max-sm:rounded-none -right-32 textcol mt-0 rounded shadow-md invisible group-focus-within:opacity-100 group-focus-within:visible transition-all group-focus-within:mt-9"
      ref="dropdown">
      <div class="sticky top-0 text-center py-2 text-sm border-b bordercol">
        {{ $t("globalComponents.notification.notifications") }}
      </div>

      <div v-if="!load" class="overflow-y-auto max-h-56 w-full scroltype">
        <card v-for="(item, index) in List" :key="index" :icon="item.icon" :title="item.title"
          :description="item.description" :date="item.addDate" :href="item.href" />
      </div>
      <div v-if="List.length == 0" class="text-left text-xs px-4 py-2 my-2 border-b border-gray-100">
        <p v-if="!load">
          {{ $t("globalComponents.notification.noNotification") }}
        </p>
        <p v-else>
          <i class="fas fa-spinner fa-spin"></i>
          {{ $t("globalComponents.notification.loading") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import card from "./components/card.vue";
import { utility } from "@/networking/urlmanager";
import io from "socket.io-client";
export default {
  name: "notification-components",
  components: {
    card,
  },
  data() {
    return {
      isNotification: false,
      List: [],
      load: false,
      socket: "",
      notification: null,
      dropdown: null,
    };
  },
  methods: {
    async getAll() {
      try {
        this.load = true;
        const res = await this.axios.get(utility.getNotificationLimit);
        this.List = res.data.data;
        this.load = false;
      } catch (err) {
        this.load = false;
        this.authController(err);
      }
    },

    toggleDropdown() {
      this.isNotification = !this.isNotification;
    },

    handleClickOutside(e) {
      if (this.dropdown && !this.dropdown.contains(e.target)) {
        this.isNotification = false;
      }
    },
  },
  mounted() {
    // this.socket = io("http://localhost:80/notification", {
    //   auth: {
    //     token: this.$store.state.userData.token,
    //   },
    // });

    // this.socket.on("connect", () => {
    //   this.socket.emit("joinNotificationRoom");
    //   this.socket.on("notification", (notification) => {
    //     console.log("notificationsss", notification);
    //   });
    // });
    // this.socket.on("disconnect", () => {
    //   console.log("Sunucuyla bağlantı kesildi.");
    // });
  },
  created() {
    this.getAll();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
