<template>
  <div>
    <!-- :taggable="true" -->
    <multiselect
      v-model="selectedOption"
      :options="options"
      multiple
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      @input="handleInput"
      :placeholder="$t('project.components.add.advisors')"
      track-by="userId"
      label="fullName"
      :loading="isLoading"
      :disabled="isLoading || disabled"
      class="custom-multiselect"
      :class="highlight ? '*:!border-red-500' : ''"
    >
      <template v-slot:selection="{ values }">
        <span class="multiselect__single" v-if="values.length">
          {{ values.length }} {{ $t("project.components.add.advisors") }}
        </span>
      </template>
      <template v-slot:option="{ option }">
        <span>{{ option.fullName }}</span>
      </template>
      <template v-slot:noResult>
        {{ $t("project.components.add.advisors") }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { project } from "@/networking/urlmanager";

export default {
  name: "UserSelector",
  components: {
    Multiselect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: [],
      options: [],
      users: [],
      isLoading: false,
    };
  },
  created() {
    this.getAllUsers();
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        if (Array.isArray(newVal)) {
          this.selectedOption = [...newVal];
          if (this.isEdit) {
            this.setInitialSelection();
          }
        }
      },
    },
  },
  methods: {
    handleInput(value) {
      this.selectedOption = value;
      this.$emit("update:modelValue", value);
    },
    async getAllUsers() {
      this.isLoading = true;
      try {
        const response = await this.axios.get(project.getCompanyUsers);
        if (response?.data?.data) {
          this.users = response.data.data;
          this.options = this.users.map((user) => ({
            userId: user.userId,
            fullName: user.fullName,
          }));

          // Auto-select current user
          const currentUserId = this.$store.state.userData.userId;
          const currentUser = this.options.find(
            (user) => user.userId === currentUserId
          );
          if (currentUser) {
            this.selectedOption = [currentUser];
            this.$emit("update:modelValue", this.selectedOption);
          }

          if (this.isEdit) {
            this.setInitialSelection();
          }
        }
      } catch (error) {
        console.error("Failed to fetch users:", error);
        this.$emit("error", error);
      } finally {
        this.isLoading = false;
      }
    },
    setInitialSelection() {
      if (!Array.isArray(this.modelValue) || !Array.isArray(this.users)) {
        return [];
      }
      this.selectedOption = this.modelValue.filter((selectedUser) =>
        this.users.some((user) => user.userId === selectedUser.userId)
      );
      return this.selectedOption;
    },
  },
};
</script>

<style scoped>
.custom-multiselect {
  min-height: 40px;
}
.custom-multiselect .multiselect__tags {
  min-height: 40px;
  padding: 8px 40px 0 8px;
}
.custom-multiselect .multiselect__placeholder {
  padding-top: 0;
}
.custom-multiselect.multiselect--disabled {
  opacity: 0.6;
}
.custom-multiselect .multiselect__single {
  margin-bottom: 3px;
}
.custom-multiselect .multiselect__option {
  padding: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
</style>
